// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import * as navigationActions from '../store/actions/navigation';
import { userSelector } from '../store/selectors/auth';
import { themeTypeSelector } from '../store/selectors/theme';
import { isFirstLoginSelector } from '../store/selectors/settings';
import ThemeContext from '../contexts/ThemeContext';
import LocalizationContext from '../contexts/LocalizationContext';
import ScreenNames from '../constants/screen-names';

const styles = {
  container: {
    //height: 'calc(100% - 120px)',
    //height: '100%',
    paddingBottom: '30px'
  },
  headingOLD: {
    marginTop: 10,
    marginBottom: -10
  },
  heading: {
    marginTop: 30,
    marginBottom: -20,
    marginLeft: 40
  },
  backButtonBottom: {
    marginRight: 40, 
    marginTop: -50,
    position: 'relative', 
    zIndex: 111
  },
  backButtonTop: {
    marginTop: 35,
    marginLeft: -110,
    marginRight: 40,
    marginBottom: -50,
    position: 'relative',
    zIndex: 111,
    display: 'none'    
  }
};

type HelpProps = {
  actions: Object,
  user: Object,
  isFirstLogin: boolean,
  themeType: String,
};

const Help = (props: HelpProps) => {
  const { isFirstLogin, themeType, actions: { changeScreen } } = props;
  const hideHelp = () => {
    const nextScreen = isFirstLogin ? ScreenNames.SETTINGS : ScreenNames.DASHBOARD;
    changeScreen(nextScreen);
  };
  return (
    <LocalizationContext.Consumer>
      {(localization) => (
        <ThemeContext.Consumer>
          {(theme) => (
            <div style={{ ...styles.container, background: theme.HELP_BACKGROUND, color: theme.TEXT }}>
              <Container style={{maxWidth: "100%"}}>
                <Button
                  className="float-right"
                  style={{ ...styles.backButtonTop, background: theme.THEME_SWTICH_BULLET, color: theme.LABEL }}
                  variant="primary"
                  onClick={hideHelp}
                >
                  { localization.BACK }
                </Button>
                <Row>
                  <Col>
                    <h2 style={styles.heading}>{localization.HELP_TITLE}</h2>
                    <p dangerouslySetInnerHTML={{ __html: localization.getHelpContent(themeType, theme) }}></p>
                    
                  </Col>
                </Row>
                <Button
                  className="float-right"
                  style={{ ...styles.backButtonBottom, background: theme.THEME_SWTICH_BULLET, color: theme.LABEL }}
                  variant="primary"
                  onClick={hideHelp}
                >
                  { localization.BACK }
                </Button>
              </Container>
            </div>
          )}
        </ThemeContext.Consumer>
      )}
    </LocalizationContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  themeType: themeTypeSelector(state),
  isFirstLogin: isFirstLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...navigationActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Help);
