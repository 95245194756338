import ReduxActions from '../../constants/redux-actions';
import ScreenNames from '../../constants/screen-names';

const defaultState = {
  currentScreenName: ScreenNames.LOGIN,
};

const navigation = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.CHANGE_SCREEN:
      return Object.assign({}, state, {
        currentScreenName: action.screenName,
      });
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default navigation;
