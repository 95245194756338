import ReduxActions from "../../constants/redux-actions";
import { layouts } from "../../configs/layouts";

const defaultState = {
  layout: layouts.singleGroup.find(({ id }) => id === "1.1.4"),
  layouts
};

const layout = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.UPDATE_LAYOUT:
      return Object.assign({}, state, {
        layout: action.layout
      });
    case ReduxActions.LOAD_LAYOUT:
      let layoutLoaded = layouts.singleGroup.find(
        ({ id }) => id === action.layoutId
      );
      if (!layoutLoaded) {
        layoutLoaded = layouts.multiGroup.find(
          ({ id }) => id === action.layoutId
        );
      }
      return Object.assign({}, state, {
        layout: layoutLoaded
      });
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default layout;
