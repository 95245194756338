import { LogLevel, HubConnectionBuilder } from '@aspnet/signalr'
import { delay } from '../utils/index'
import { renewAccessToken } from '../utils/axios-utility'

// import StorageKeys from '../constants/srorage-keys'
// import { getItem } from '../utils/storage-utility'

const signalRConnectionActions = {
  SEND: 'Perform',
  SEND_MESSAGE: 'SendMessage',
  RECEIVE_MESSAGE: 'ReceiveMessage',
  RECEIVE: 'Receive'
}

const internalHubActions = {
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe'
}
const hubActionConstants = {
  UPD_STATS: 'questats_update',
  NEW_CONFIGURATION: 'questats_configuration_new',
  ALL_STATS: 'questats_all',
  ALL_ALERTSTATS: 'quealertstats_all',
  UPD_ALERTSTATS: 'quealertstats_update'
}

let _AccessToken = ''
function createConnection (url, accessToken) {
  _AccessToken = accessToken
  return new HubConnectionBuilder()
    .configureLogging(LogLevel.Error)
    .withUrl(url, { accessTokenFactory: () => _AccessToken })
    .build()
}

let isConnecting = false

function handleError (err) {
  console.error('WS Error', err)
}

let onConnectionHandler = null

function startConnection (connection) {
  if (isConnecting) return
  isConnecting = true
  deliberateStop = false

  connection
    .start()
    .then(() => {
      // console.info("WS connection established")
      if (onConnectionHandler) {
        onConnectionHandler()
      }
      isConnecting = false
    })
    .catch(err => {
      isConnecting = false
      handleError(err)
      if (err.statusCode === 401 /* Unauthorized */) {
        renewAccessToken()
          .then((newToken) => {
            _AccessToken = newToken
          })
          .catch((error) => console.error('Failed renewing access_token for WS.', error))
          .then(() => reconnect(connection))
      }else {
        reconnect(connection)
      }
    })
}

function reconnect (connection) {
  connection
    .stop()
    .then(() => delay(5000))
    .then(() => startConnection(connection))
}

function sendMessage (connection) {
  return (action, message) => {
    connection
      .invoke(signalRConnectionActions.SEND_MESSAGE, action, message)
      .catch(err => {
        handleError(err)
        reconnect(connection)
      })
  }
}

const handlers = []
const temporaryHandlers = []
let deliberateStop = false

function send (connection) {
  return (action, data, callback) => {
    if (callback) {
      temporaryHandlers[action] = callback
    }
    connection
      .invoke(
        signalRConnectionActions.SEND,
        JSON.stringify({ action: action, data: data })
    )
      .catch(err => {
        handleError(err)
        reconnect(connection)
      })
  }
}

function createWsClient (url, accessToken, onConnection) {
  const connection = createConnection(url, accessToken)

  connection.on(signalRConnectionActions.RECEIVE_MESSAGE, (action, message) => {
    // console.log(`Message recived: ${message}`)
    // const [type, ...body] = message
    if (handlers[action]) {
      handlers[action](message)
    }
  })

  connection.on(signalRConnectionActions.RECEIVE, json => {
    // console.log(`Message recived: ${message}`)
    const notification = JSON.parse(json)

    if (handlers[notification.action]) {
      handlers[notification.action](notification.data)
    }
    if (temporaryHandlers[notification.action]) {
      temporaryHandlers[notification.action](notification.data)
      temporaryHandlers[notification.action] = undefined
    }
  })

  connection.onclose(reason => {
    // console.info("WS connection close")
    if (reason) {
      console.info(reason)
    }
    if (deliberateStop) {
      return
    }
    reconnect(connection)
  })

  if (onConnection) {
    onConnectionHandler = onConnection
  }
  startConnection(connection)

  return {
    sendHubMessage: sendMessage(connection),
    sendHubData: send(connection),
    subscribeToHub: (action, data, onNotificationCallback) => {
      send(connection)(internalHubActions.SUBSCRIBE, data)
      handlers[action] = onNotificationCallback
    },
    unsubscribeFromHub: action => {
      send(connection)(internalHubActions.UNSUBSCRIBE, '')
      handlers[action] = undefined
    },
    closeHub: () => {
      deliberateStop = true
      handlers.length = 0
      connection.stop()
    },
  hubActionConstants}
}

export default createWsClient
