import getCommon from './common'

export default (stateThemeType, stateTheme) => {
  const { theme, common } = getCommon(stateThemeType, stateTheme)

  const txts = {
    descrpt: 'Wallboard biedt overzicht en controle over de huidige status van reactiegroepen. Verschillende lay-outs stellen je in staat om statistieken in verschillende weergaven weer te geven. <br />' +
      'De pagina bestaat uit een rastersysteem waarin één of meer groepen worden weergegeven zoals geselecteerd.',
    cellHead: 'Cellen:',
    cellDescrpt: 'elke cel kan verschillende statistieken weergeven waardoor je per groep alleen de waarden kunt weergeven die belangrijk zijn.',
    metricsHead: 'STATISTIEKEN',
    metricsSubHead: 'Selecteer een meetwaarde:',
    metricsDescrpt: 'klik op de cel van de meetwaarde en kies de meetwaarde in de keuzelijst die verschijnt.',
    metricsChartSubHead: 'Stel een grafiek in als metriek:',
    metricsChartDescrpt: 'Staafdiagram, cirkeldiagram en tabel zijn drie verschillende grafische opties om gemiste, beantwoorde of alle gesprekken voor de geselecteerde groep te bekijken.',
    metricsOptnsHead: 'Statistieken opties:',
    metricsOptnsQueHead: '&bull; In de wachtrij:',
    metricsOptnsQueDescrpt: 'hoeveel gesprekken wachten om te worden beantwoord.',
    metricsOptnsLgdInHead: '&bull; Ingelogde agenten:',
    metricsOptnsLgdInDescrpt: 'hoeveel agenten zijn aangemeld bij de groep.',
    metricsOptnsAvailHead: '&bull; Beschikbare agenten:',
    metricsOptnsAvailDescrpt: 'hoeveel agenten zijn beschikbaar om gesprekken te ontvangen.',
    metricsOptnsTotHead: '&bull; Totaal:',
    metricsOptnsTotDescrpt: 'totaal aantal gesprekken voor de laatste 12 uur.',
    metricsOptnsAnswHead: '&bull; Beantwoord:',
    metricsOptnsAnswDescrpt: 'totaal beantwoorde gesprekken laatste 12 uur.',
    metricsOptnsMissHead: '&bull; Gemist:',
    metricsOptnsMissDescrpt: 'totaal gemiste gesprekken gedurende de laatste 12 uur.',
    metricsOptnsLtstHead: '&bull; Laatste:',
    metricsOptnsLtstDescrpt: 'wachtrij voor het laatste gesprek naar de groep.',
    metricsOptnsAvQueTimHead: '&bull; Gemiddelde wachttijd:',
    metricsOptnsAvQueTimDescrpt: 'gemiddelde wachttijd voor de laatste 12 uur.',
    metricsOptnsAvCallDurHead: '&bull; Gemiddelde gespreksduur:',
    metricsOptnsAvCallDurDescrpt: 'gemiddelde duur van beantwoorde gesprekken gedurende de laatste 12 uur.',
    metricsOptnsFwdedHead: '&bull; Doorverbonden:',
    metricsOptnsFwdedDescrpt: 'totaal aantal gesprekken doorverbonden naar een andere bestemming in de laatste 12 uur.',
    metricsOptnsChartHead: '&bull; Staafdiagram (gesprekken), <br />&nbsp;&nbsp;&nbsp;Cirkeldiagram (gesprekken), <br />&nbsp;&nbsp;&nbsp;Tabel (gesprekken):',
    metricsOptnsChartDescrpt: 'Toont beantwoorde, gemiste en totaal aantal gesprekken van de laatste 12 uur.', // in een staafdiagram. in een cirkeldiagram. in een tabel.
    toolbarHead: 'WERKBALK',
    toolbarDescrpt: 'Rechtsboven op de pagina bevinden zich verschillende knoppen om de instellingen van Wallboard te wijzigen.',
    layoutHead: 'Lay-out:',
    layoutDescrpt: 'kies de lay-out om de statistieken van één of meerdere groepen op dezelfde pagina weer te geven.',
    saveHead: 'Opslaan:',
    saveDescrpt: 'configuratie opslaan. De lay-out en statistieken worden weergegeven in alle andere browsers die met dezelfde gebruiker zijn ingelogd.',
    lightnessHead: 'Donkere / lichte modus:',
    lightnessDescrpt: 'schakel tussen lichte of donkere modus, deze instelling is lokaal voor elke browser die Wallboard weergeeft en wordt niet opgeslagen in de configuratie.',
    lightness2Head: 'Donkere / lichte modus:',
    lightness2Descrpt: 'hetzelfde als hierboven.',
    settingsHeader: 'Instellingen menu',
    buttonsOnOffDescrpt: 'Knoppen op de werkbalk tonen of verbergen.',
    languageDroplist: 'NL',
    languageDescrpt: 'Keuzelijst voor het selecteren van de taal.',
    answerGroupDroplist: 'WB 1',
    answerGroupHead: 'Configuratie selecteren:',
    answerGroupDescrpt: 'deze keuzelijst wordt alleen weergegeven als er meer dan één configuratie van Wallboard beschikbaar is.',
    answerGroupDescrpt2: 'Een configuratie wordt centraal opgeslagen en bestaat uit geselecteerde lay-out, groep(en) en statistieken. Alle Wallboards die zijn ingesteld om dezelfde configuratie weer te geven (is altijd hetzelfde als er slechts één configuratie beschikbaar is), geeft dezelfde lay-out, groep(en) en statistieken weer.',
    helpDescrpt: 'Help (deze pagina)',
    logoutDescrpt: 'Uitloggen, huidige gebruiker wordt uitgelogd.'
  }

  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,

    settingsItems: `<br><div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right +
      common.left + common.buttonsIcon + common.middle100Minus141px + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle100Minus141px + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle100Minus141px + `${txts.logoutDescrpt}` + common.right
  }

  return `<span style="color: ${theme.obj.LABEL}"><div style="float:left;padding: 0 0 10px 0px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right +
    '</div></span>'
}
