import ReduxActions from '../../constants/redux-actions';
import api from '../../api';

const renameInstance = (instanceId, instanceName, accountId, callback) => (dispatch, getState) => {
  dispatch({type: ReduxActions.INSTANCE_RENAME_STARTED});
  api.renameInstance(instanceId, instanceName, accountId, callback)
    .then(() => {
      dispatch({
        type: ReduxActions.INSTANCE_RENAME_FINISHED, 
        instanceId: instanceId, 
        instanceName: instanceName,
      });
      const lss = getState().layoutSettings;
      if(lss == null || lss.layoutsSettings == null) { 
        return; 
      }
      dispatch({
        type: ReduxActions.LOAD_LAYOUT_SETTINGS, 
        layoutsSettingsLoaded: lss.layoutsSettings.map(ls =>  
          +ls.instanceId === +instanceId ? {...ls, shortName: instanceName} : ls
        )});
    })
    .catch((error) => { 
      dispatch({type: ReduxActions.INSTANCE_RENAME_ERROR, error: error});
      console.error(error);
    });
};

const resetToDefault = () => (dispatch) => {
  dispatch({type: ReduxActions.INSTANCE_RENAME_RESET});
};

export {
  renameInstance,
  resetToDefault,
};
