import ReduxActions from '../../constants/redux-actions';
import { saveCulture } from "../../api/configs";
import { storeSettings } from './settings';
import { parseLangTag } from '../../utils';

const updateCurrentLanguage = (langTag) => ({ type: ReduxActions.CHANGE_LANGUAGE, langTag });

const changeLanguage = (langTagStr, callback) => (dispatch) => {
  const langTag = parseLangTag(langTagStr);

  dispatch(storeSettings({ currentLanguage: langTag }));
  dispatch(updateCurrentLanguage(langTag));

  saveCulture(langTag)
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch(error => {
      console.error(error);
      if (callback) {
        callback(error);
      }
    });
};

export {
  changeLanguage,
  updateCurrentLanguage,
};
