import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const layoutRecord = (state) => state.layout;

const layoutSelector = createSelector(
  layoutRecord,
  (record = EMPTY_OBJECT) => record.layout,
);

const layoutsSelector = createSelector(
  layoutRecord,
  (record = EMPTY_OBJECT) => record.layouts,
);

export {
  layoutSelector,
  layoutsSelector,
};
