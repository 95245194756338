import ReduxActions from '../../constants/redux-actions';
import StorageKeys from '../../constants/storage-keys';
import { setItem, getItem } from '../../utils/storage-utility';
import { updateCurrentLanguage } from './localization';
import { changeInstance } from './layoutSettings';
import { changeTheme } from './theme';

const storeSettings = (settings) => () => {
  const oldSettings = getItem(StorageKeys.USER_SETTINGS);
  setItem(StorageKeys.USER_SETTINGS, { ...oldSettings, ...settings });
};

const updateSettings = (settings) => (dispatch) => {
  dispatch({
    type: ReduxActions.UPDATE_SETTINGS,
    ...settings,
  });
  dispatch(storeSettings(settings));
};

const loadSettings = () => (dispatch) => {
  const {
    isHeaderToolsModeActive,
    isFirstLogin,
    selectedInstance,
    isClockVisible,
    isAlertVisible,
    currentLanguage,
    themeType,
  } = getItem(StorageKeys.USER_SETTINGS) || {};

  if (isHeaderToolsModeActive !== undefined || isFirstLogin !== undefined) {
    dispatch(updateSettings({ isHeaderToolsModeActive: isHeaderToolsModeActive, isFirstLogin: isFirstLogin }));
  }

  if (isClockVisible !== undefined || isFirstLogin !== undefined) {
    dispatch(updateSettings({ isClockVisible: isClockVisible, isFirstLogin: isFirstLogin }));
  }

  if (isAlertVisible !== undefined || isFirstLogin !== undefined) {
    dispatch(updateSettings({ isAlertVisible: isAlertVisible, isFirstLogin: isFirstLogin }));
  }

  if (selectedInstance) {
    dispatch(changeInstance(selectedInstance));
  }
  if (currentLanguage) {
    dispatch(updateCurrentLanguage(currentLanguage));
  }
  if (themeType) {
    dispatch(changeTheme(themeType));
  }
};

export {
  storeSettings,
  updateSettings,
  loadSettings,
};
