// @flow
import React, { Component } from "react";
import AnimatedNumber from "../AnimatedNumber";
import LinkSelect from "../LinkSelect";
import LocalizationContext from "../../contexts/LocalizationContext";
import ThemeContext from "../../contexts/ThemeContext";
import { ANIMATION_DURATION } from "../../constants";

const styles = {
  widget: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  }
};

type NumberWidgetProps = {
  id: number,
  groupNo: number,
  groupId: number,
  value: number,
  kpi?: string,
  groups: Array,
  onGroupChange: Function,
  fontFactor: number
};

class NumberWidget extends Component<NumberWidgetProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFirstRender: true,
      timeoutId: 0
    };
  }

  componentDidMount() {
    this.setState({
      isFirstRender: true,
      timeoutId: 0
    });
    const timeoutId = setTimeout(() => {
      this.setState({
        isFirstRender: false
      });
    }, (ANIMATION_DURATION * 1000));
    this.setState({
      timeoutId: timeoutId
    });
  }

  componentWillUnmount() {
      clearTimeout(this.state.timeoutId);
  }

  render() {
    const {
      groupId,
      value,
      kpi,
      groups,
      onGroupChange,
      fontFactor
    } = this.props;
    const { isFirstRender } = this.state;
    const numberFontSize = (() => {
      return 42 * fontFactor + "px";
    })();
    const labelFontSize = (() => {
      return 12 * fontFactor + "px";
    })();

    const options = groups.map(({ id, name }) => ({
      value: `${id}`,
      //value: `${this.props.id}_${groupId}`,
      label: name
    }));
    const selectedValue = options.find(
      option => option.value === `${groupId}`
    ) || options[0];
    return (
      <LocalizationContext.Consumer>
        {localization => (
          <ThemeContext.Consumer>
            {theme => (
              <div style={styles.widget}>
                <LinkSelect
                  className="link-select"
                  fontFactor={fontFactor}
                  options={options}
                  value={selectedValue}
                  onChange={event =>
                    onGroupChange(
                      event.value,
                      this.props.groupNo,
                      this.props.id,
                      this.props.kpi,
                      this.props.type
                    )
                  }
                  selectStyle={{
                    color: theme.TITLE
                  }}
                />
                <div title={localization.TIP_CHANGE_MTRC} style={styles.container}>
                  <AnimatedNumber
                    isFirstRender={isFirstRender}
                    value={value}
                    style={{
                      color: theme.NUMBER,
                      fontSize: numberFontSize,
                    }}
                    animationDuration={ANIMATION_DURATION}
                  />
                  <div
                    style={{
                      color: theme.LABEL,
                      fontSize: labelFontSize
                    }}
                  >
                    {localization[kpi]}
                  </div>
                </div>
                <div />
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

NumberWidget.defaultProps = {
  kpi: ""
};

export default NumberWidget;
