// @flow
import React, { Component } from "react";
import ChartTable from "./ChartTable";
import LinkSelect from "../LinkSelect";
import LocalizationContext from "../../contexts/LocalizationContext";
import ThemeContext from "../../contexts/ThemeContext";
import { ANIMATION_DURATION } from "../../constants";

const styles = {
  widget: {
    display: "flex",
    flex: 1,
    //alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  chart: {
    //display: "flex",
    //flex: 1,
    //flexDirection: "row",
    //transform: "rotate(-90deg)",
    
    //height: '20%',
    //marginTop: '-20%'
    //alignItems: "center",
    //justifyContent: "right",
    //marginRight: '-50px',
    //marginTop: "5%"
    //marginBottom: "-10%",
  },
  chartBox: {
    ////paddingLeft: "-10%",
    ////paddingTop: "-20%",
    ////paddingBottom: "-20%",

    //display: "flex",
    //flexDirection: "column",

    //height: "80%",
    //maxWidth: "80%",
    alignItems: "center",
    justifyContent: "center",
    //paddingRight: '-5%',
    //marginRight: "-8%",
    
    width: "28%",
    display: "flex",
    //flex: 1,
    flexDirection: "column",
    transform: "rotate(-90deg)",
    //height: "10%",
    //maxHeight: "10%"
  }
};

const getChartPercentage = (successful, unsuccessful) => {
  const total = successful + unsuccessful;
  const successfulPercent = successful / total;
  const unsuccessfulPercent = 1 - successfulPercent;
  return { successfulPercent, unsuccessfulPercent };
};

const getCoordinatesForPercent = percent => [
  Math.cos(2 * Math.PI * percent),
  Math.sin(2 * Math.PI * percent)
];

const slicesToSegments = slices =>
  slices.reduce((acc, { percent, ...rest }) => {
    const { end } = acc.length ? acc[acc.length - 1] : { end: 0 };
    acc.push({
      start: end,
      end: end + percent,
      ...rest
    });
    return acc;
  }, []);

const createArcPath = (start, end) => {
  const [startX, startY] = getCoordinatesForPercent(start);
  const [endX, endY] = getCoordinatesForPercent(end);

  // if the slice is more than 180, take the large arc
  const largeArcFlag = end - start > 0.5 ? 1 : 0;

  return [
    `M ${startX} ${startY}`, // Move
    `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
    "L 0 0" // Line
  ].join(" ");
};

const getSvgPieChartPath = slices =>
  slicesToSegments(slices).map(({ start, end, color }) => ({
    path: createArcPath(start, end),
    color
  }));

type Slice = {
  percent: number,
  color: string
};

type ChartProps = {
  slices: Slice,
  opacity: number
};

const Chart = (props: ChartProps) => {
  const { slices, opacity, height } = props;
  const pathArray = getSvgPieChartPath(slices);
  return (
    <div style={{...styles.chartBox, ...(height? { height: `${height}px`}: {})}}>
      <svg
        viewBox="-1 -1 2 2"
        style={styles.chart}
        opacity={opacity}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {pathArray.map(({ path, color }) => (
          <path key={color} d={path} fill={color} />
        ))}
      </svg>
    </div>
  );
};

type PieChartWidgetProps = {
  successful: number,
  unsuccessful: number,
  successfulLabel?: string,
  unsuccessfulLabel?: string,
  totalLabel?: string,
  id: number,
  groupNo: number,
  groupId: number,
  fontFactor: number
};

class PieChartWidget extends Component<PieChartWidgetProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFirstRender: true,
      opacity: 0,
      intervalId: 0,
      timeoutId: 0,
      trHeight: 0
    };
  }

  componentDidMount() {
    const trHeight = this.trElement.clientHeight;
    this.setState({ trHeight });
    const timeoutId = setTimeout(() => {
      this.setState({
        isFirstRender: false
      });
    }, (ANIMATION_DURATION * 1000) + 10);
    this.setState({
      timeoutId: timeoutId
    });
    this.animateOpacity();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
    clearInterval(this.state.intervalId);
  }

  animateOpacity(numberOfUpdates = 100) {
    const intervalTime = (ANIMATION_DURATION * 1000) / numberOfUpdates;
    const intervalId = setInterval(() => {
      const { opacity } = this.state;
      if (this.state.opacity >= 1) {
        clearInterval(this.state.intervalId);
      } else {
        this.setState({ opacity: opacity + 1 / numberOfUpdates });
      }
    }, intervalTime);
    this.setState({ intervalId: intervalId });
  }

  render() {
    const {
      successful,
      unsuccessful,
      successfulLabel,
      unsuccessfulLabel,
      totalLabel,
      groups,
      onGroupChange,
      groupId,
      fontFactor
    } = this.props;
    const { isFirstRender, opacity, trHeight } = this.state;

    const { successfulPercent, unsuccessfulPercent } = getChartPercentage(
      successful,
      unsuccessful
    );

    const options = groups.map(({ id, name }) => ({
      value: `${id}`,
      //value: `${this.props.id}_${groupId}`,
      label: name
    }));
    const selectedValue = options.find(
      option => option.value === `${groupId}`
    ) || options[0];

    return (
      <LocalizationContext.Consumer>
        {localization => {
          const labels = {
            successfulLabel: localization[successfulLabel],
            unsuccessfulLabel: localization[unsuccessfulLabel],
            totalLabel: localization[totalLabel]
          };
          return (
            <ThemeContext.Consumer>
              {theme => {
                const slices = [
                  { percent: successfulPercent || 1, color: theme.SUC_BAR },
                  { percent: unsuccessfulPercent || 0, color: theme.UNSUC_BAR }
                ];
                return (
                  <div style={styles.widget}>
                    <table style={{ width: '100%', height: '100%'}}>
                      <tbody>
                        <tr style={{ height: '40px'}}><td style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                          <LinkSelect 
                            className="link-select"
                            fontFactor={fontFactor}
                            options={options}
                            value={selectedValue}
                            onChange={event =>
                              onGroupChange(
                                event.value,
                                this.props.groupNo,
                                this.props.id,
                                this.props.kpi,
                                this.props.type
                              )
                            }
                            selectStyle={{
                              color: theme.TITLE
                            }}
                          />
                        </td></tr> 
                        <tr ref={(trElement) => this.trElement = trElement}>
                          <td title={localization.TIP_CHANGE_MTRC} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                          <Chart slices={slices} opacity={opacity} height={trHeight}/>

                          <ChartTable
                            successful={successful}
                            unsuccessful={unsuccessful}
                            labels={labels}
                            fontFactor={fontFactor}
                            isFirstRender={isFirstRender}
                            theme={theme}
                          />
                        </td></tr>
                      </tbody>
                    </table>
                  </div>
                );
              }}
            </ThemeContext.Consumer>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

export default PieChartWidget;
