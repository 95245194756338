const WidgetTypes = {
  NUMBER: 'NUMBER',
  BAR_CHART: 'BAR_CHART',
  PIE_CHART: 'PIE_CHART',
  CHART_GRID: 'CHART_GRID',
  AGENT_SIMPLE_LIST: 'AGENT_SIMPLE_LIST',
  TOP_LIST_IN: 'TOP_LIST_IN',
  TOP_LIST_OUT: 'TOP_LIST_OUT',
  AGENTS_PIE_CHART: 'AGENTS_PIE_CHART',
};

export default WidgetTypes;
