import languages from '../localizations'
const ANIMATION_DURATION = 2; // seconds animation duration
const DEFAULT_LANGUAGE = 'en';
const AVAILABLE_LANGUAGES = languages.getAvailableLanguages(); 
const APP_NAME = 'Walboard Core';

export {
  ANIMATION_DURATION,
  DEFAULT_LANGUAGE,
  AVAILABLE_LANGUAGES,
  APP_NAME,
};
