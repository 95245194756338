// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import {
  currentLanguageSelector,
} from "../../store/selectors/localization";

import * as navigationActions from '../../store/actions/navigation';
import { userSelector } from '../../store/selectors/auth';
import { themeTypeSelector } from '../../store/selectors/theme';
import ThemeContext from '../../contexts/ThemeContext';
import StorageKeys from '../../constants/storage-keys'
import { getItem } from '../../utils/storage-utility'
import { ADM_ALERTS_BASE } from "../../configs";

const styles = {
  container: {
    display: 'flex',
    flexDirection: "row",
    height: '100%',
    padding: '0px',
    borderRadius:'10px',
    background: '#2a2a2d',
  },
  frame: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
  heading: {
    marginTop: 30,
    marginBottom: -20,
    marginLeft: 40
  },
  backButtonBottom: {
    marginRight: 40, 
    zIndex: 111,
    position: 'fixed',
    bottom: 90,
    right: 0
  },
  backButtonTop: {
    marginTop: 35,
    marginLeft: -110,
    marginRight: 40,
    marginBottom: -50,
    position: 'relative',
    zIndex: 111,
  }
};

type AdminAlertsProps = {
  actions: Object,
  user: Object,
  themeType: String,
  currentLang: string,
};

const AdminAlerts = (props: AdminAlertsProps) => {
  //const { user, themeType, currentLang, actions: { changeScreen } } = props;
  const { currentLang } = props;
  const { accessToken, refreshToken2 } = getItem(StorageKeys.AUTH_TOKENS) || {};
  const adminAlertsUrl = `${ADM_ALERTS_BASE}?lc=${currentLang}&t=${encodeURI(accessToken)}&r=${encodeURI(refreshToken2)}&refr=${Math.random()}&f=wb-core`
  //console.log(adminAlertsUrl)
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div style={{ ...styles.container, color: theme.TEXT }}>
          <Container style={{maxWidth: "100%", padding: 0}}>
              <iframe title="AdminAlertsFrame" src={adminAlertsUrl} style={ styles.frame }>
              </iframe>
          </Container>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  currentLang: currentLanguageSelector(state),
  user: userSelector(state),
  themeType: themeTypeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...navigationActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAlerts);
