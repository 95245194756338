import WidgetTypes from "../constants/widget-types";

const defaultWidgets = [
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 2, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 3, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 4, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 5, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 6, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 7, type: WidgetTypes.NUMBER, groupNo: 1 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 2, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 3, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 4, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 5, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 6, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 7, type: WidgetTypes.NUMBER, groupNo: 2 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 2, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 3, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 4, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 5, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 6, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 7, type: WidgetTypes.NUMBER, groupNo: 3 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 4 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 5 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 6 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 7 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 8 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 9 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 10 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 11 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 11 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 12 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 13 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 14 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 15 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 16 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 17 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 18 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 19 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 20 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 21 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 22 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 23 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 24 },
  { id: 1, type: WidgetTypes.NUMBER, groupNo: 25 }
];

export { defaultWidgets };
