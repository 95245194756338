// @flow
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import * as authActions from "../../store/actions/auth";
import * as localizationActions from "../../store/actions/localization";
import * as settingsActions from "../../store/actions/settings";

import ThemeContext from '../../contexts/ThemeContext';
import ThemeSwitch from "../../components/ThemeSwitch";
import ClockSwitch from '../../components/ClockSwitch'
import AlertSwitch from '../../components/AlertSwitch'
import Select from "../../components/Select";
import LocalizationContext from '../../contexts/LocalizationContext';
import { selectThemeIcon } from "../../utils";
import LogoutIconLight from "../../images/logout-light.svg";
import LogoutIconDark from "../../images/logout-dark.svg";
import * as themeActions from "../../store/actions/theme";
import { isHeaderToolsModeActiveSelector, isClockVisibleSelector, isAlertVisibleSelector } from "../../store/selectors/settings";
import { currentLanguageSelector, availableLanguagesSelector } from "../../store/selectors/localization";
import { availableInstancesSelector } from "../../store/selectors/layoutSettings";
import Instances from './../settings-elements/Instances';


const styles = {
  container: {
    padding:'50px',    
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop:30,
  },
  row: {
    height: 60,
  },
  logOutButton: {
    margin: '10px',
    background: 'chocolate',
    color: 'white',
    paddingLeft: '12px',
    paddingRight: '12px',
  }
};

type GeneralProps = {
  actions: Object,
  availableLang: Array,
  currentLang: Object,
  availableInstances: Object,
  isHeaderToolsModeActive: boolean,  
  isClockVisible: boolean,
  isAlertVisible: boolean,
  onClockVisibilityChange: Function,
  onLogout: Function,
  onThemeChange: Function,
  onHeadersToolsModeChange: Function,
  onLanguageChange: Function,
  onInstanceChange: Function,
};

class General extends Component<GeneralProps> {
  render() {
    const { 
      themeType, 
      onLogout, 
      // inner
      currentLang,
      availableLang,
      availableInstances,
      // NOTE: hidden according to #3708. Can be deleted after the spring 2021  
      // isHeaderToolsModeActive,
      isClockVisible,
      isAlertVisible,
      onInstanceChange, 
      actions: {
        logout,
        changeTheme,
        changeLanguage,
        updateSettings
      } 
    } = this.props;
    const LogoutIcon = selectThemeIcon(
      themeType,
      LogoutIconLight,
      LogoutIconDark
    );
    const languages = availableLang.map(langTag => ({
      value: langTag,
      label: langTag.toUpperCase()
    }));
    const selectedLanguage = languages.filter(
      ({ value }) => value === currentLang
    );
    const onLogoutClick = event => {
      onLogout(event);
      logout();
    };
    const onLanguageChange = ({ value }) => {
      changeLanguage(value);
    };
    
    // NOTE: hidden according to #3708. Can be deleted after the spring 2021  
    // const onHeadersToolsModeChange = (active) => {
    //   updateSettings({ isHeaderToolsModeActive: active });
    // };
    
    const onClockVisibilityChange = (active) => {
      updateSettings({ isClockVisible: active });
    };

    const onAlertVisibilityChange = (active) => {
      updateSettings({ isAlertVisible: active });
    };
    const multipleInstances = availableInstances && availableInstances.length && availableInstances.length > 1? true: false;
    
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => (
              <div style={{...styles.container}}>
                <Row style={{...styles.row}}>
                  <Col sm={3}>
                    <Row style={{...styles.row}}>
                      <Col>
                        <Select
                          options={languages}
                          value={selectedLanguage}
                          isSearchable={false}
                          onChange={onLanguageChange}
                        />
                      </Col>
                    </Row>
                    <Row style={{...styles.row}}>
                      <Col>
                        <ThemeSwitch
                          themeType={themeType}
                          onThemeChange={changeTheme}
                        />
                      </Col>
                    </Row>
                    {/* // NOTE: hidden according to #3708. Can be deleted after the spring 2021  
                     <Row style={{...styles.row}}>
                      <Col>
                        <HeaderToolsModelSwitch
                          active={isHeaderToolsModeActive}
                          themeType={themeType}
                          onModeChange={onHeadersToolsModeChange}
                        />
                      </Col>
                    </Row> */}
                    <Row style={{...styles.row}}>
                      <Col>
                        <ClockSwitch
                          isVisble={isClockVisible}
                          themeType={themeType}
                          onModeChange={onClockVisibilityChange}
                        />
                      </Col>
                    </Row>
                    <Row style={{...styles.row}}>
                      <Col>
                        <AlertSwitch
                          isVisble={isAlertVisible}
                          themeType={themeType}
                          onModeChange={onAlertVisibilityChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          style={{
                            ...styles.logOutButton,
                          }}
                          variant="default"
                          onClick={ onLogoutClick }>
                          <img alt="logout" src={LogoutIcon} style={{...styles.icon}} />
                          {localization.LOGOUT}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={8}>
                    {multipleInstances && (
                      <Instances onInstanceChange={ onInstanceChange } availableInstances={ availableInstances } themeType={ themeType } />
                    )}
                  </Col>
                </Row>                
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({
  isHeaderToolsModeActive: isHeaderToolsModeActiveSelector(state),
  isClockVisible: isClockVisibleSelector(state),
  isAlertVisible: isAlertVisibleSelector(state),
  currentLang: currentLanguageSelector(state),
  availableLang: availableLanguagesSelector(state),
  availableInstances: availableInstancesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...themeActions,
      ...settingsActions,
      ...localizationActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(General);
