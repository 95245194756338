const fontFactor = {
  xlarge: 2.5,
  large: 2,
  medium: 1.9,
  small: 1.6,
  xsmall: 1.3,
}
const layouts = {
  comments: {
    layout_ids_below: 'Is numbers consisting of {1}.{2}.{3} and must be unique.',
    placeholer_1: '{1}: number of groups supported by this layout',
    placeholer_2: '{2}: number of kpi-fields supported (all groups included if more than one) by this layout',
    placeholer_3: "{3}: A simple counter from 1 and so on. This placeholder is added to ensure uniqueness of layout-id's. Some layouts supports the same number of groups and kpi-fields as others."
  },
  singleGroup: [
    /*
      ---------
      |   |   |
      | 1 | 2 |
      |   |   |
      ---------
    */
    {
      id: '1.2.0',
      description: 'One group, 2 very big widgets',
      row: 1,
      items: [
        { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
        { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.xlarge } }
      ]
    },
    /*
      ---------
      | 1 | 2 |
      ---------
      | 3 | 4 |
      ---------
    */
    {
      id: '1.4.0',
      description: 'One group, 4 big widgets',
      col: 1,
      items: [
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.xlarge } }
          ]
        },
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 1, index: 4, fontFactor: fontFactor.xlarge } }
          ]
        }
      ]
    },
    /*
      -------------
      | 1 | 2 | 3 |
      -------------
      | 4 | 5 | 6 |
      -------------
    */
    {
      id: '1.0.6',
      description: 'One group, 6 widgets',
      col: 1,
      items: [
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.large } },
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.large } },
            { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.large } }
          ]
        },
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 4, fontFactor: fontFactor.large } },
            { col: 1, item: { group: 1, index: 5, fontFactor: fontFactor.large } },
            { col: 1, item: { group: 1, index: 6, fontFactor: fontFactor.large } }
          ]
        }
      ]
    },
    /*
      -----------------
      |       |       |
      |   1   |   2   |
      |       |       |
      -----------------
      | 3 | 4 | 5 | 6 |
      -----------------
    */
    {
      id: '1.2.4',
      description: 'One group, 2 big and 4 small widgets',
      col: 1,
      items: [
        {
          row: 2,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.xlarge } }
          ]
        },
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 1, index: 4, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 1, index: 5, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 1, index: 6, fontFactor: fontFactor.small } }
          ]
        }
      ]
    },
    /*
      ---------------
      |     | 2 | 3 |
      |  1  ---------
      |     | 4 | 5 |
      ---------------
    */
    {
      id: '1.1.4',
      description: 'One group, 1 big and 4 small widgets',
      row: 1,
      items: [
        {
          col: 1,
          item: { group: 1, index: 1, fontFactor: fontFactor.xlarge }
        },
        {
          col: 1,
          items: [
            {
              row: 1,
              items: [
                { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 1, index: 4, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 1, index: 5, fontFactor: fontFactor.small } }
              ]
            }
          ]
        }
      ]
    },
    /*
      ---------------
      |     | 2 | 3 |
      |     ---------
      |  1  | 4 | 5 |
      |     ---------
      |     | 6 | 7 |
      ---------------
    */
    {
      id: '1.1.6',
      description: 'One group, 1 big and 6 small widgets',
      row: 1,
      items: [
        {
          col: 1,
          item: { group: 1, index: 1, fontFactor: fontFactor.xlarge }
        },
        {
          col: 1,
          items: [
            {
              row: 1,
              items: [
                { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 1, index: 4, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 1, index: 5, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 1, index: 6, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 1, index: 7, fontFactor: fontFactor.small } }
              ]
            }
          ]
        }
      ]
    }
  ],
  multiGroup: [
    /*
      -------------
      |     |     |
      | 1.1 | 2.1 |
      |     |     |
      -------------
    */
    {
      id: '2.2.0',
      description: 'Two groups, 2 very big widgets',
      /*col: 1,
      items: [
        {
          row: 1,
          items: [{ col: 1, item: { group: 1, index: 1 } }]
        },
        {
          row: 1,
          items: [{ col: 1, item: { group: 2, index: 1 } }]
        }*/
      /* {
          row: 1,
          items: [{ col: 1, item: { group: 2, index: 1 } }]
        }*/
      row: 1,
      items: [
        { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
        { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.xlarge } }
      ]
    },
    /*
      -------------
      | 1.1 | 2.1 |
      -------------
      | 1.2 | 2.2 |
      -------------
    */
    {
      id: '2.4.0',
      description: 'Two groups, 4 big widgets',
      col: 1,
      items: [
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.xlarge } }
          ]
        },
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 2, index: 2, fontFactor: fontFactor.xlarge } }
          ]
        }
      ]
    },
    /*
      -------------------
      | 1.1 | 2.1 | 3.1 |
      -------------------
      | 1.2 | 2.2 | 3.2 |
      -------------------
      | 1.3 | 2.3 | 3.3 |
      -------------------
    */
    {
      id: '3.0.9',
      description: 'Three groups, 9 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.medium } }
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 2, index: 2, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 2, index: 3, fontFactor: fontFactor.medium } }
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 3, index: 2, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 3, index: 3, fontFactor: fontFactor.medium } }
          ]
        }
      ]
    },
    /*
      -------------------------
      |           |           |
      |    1.1    |    2.1    |
      |           |           |
      -------------------------
      | 1.2 | 1.3 | 2.2 | 2.3 |
      -------------------------
    */
    {
      id: '2.2.4',
      description: 'Two groups, 2 big and 4 small widgets',
      col: 1,
      items: [
        {
          row: 2,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xlarge } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.xlarge } }
          ]
        },
        {
          row: 1,
          items: [
            { col: 1, item: { group: 1, index: 2, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 1, index: 3, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 2, index: 2, fontFactor: fontFactor.small } },
            { col: 1, item: { group: 2, index: 3, fontFactor: fontFactor.small } }
          ]
        }
      ]
    },
    
    /*
      ---------------------
      |       | 2.1 | 2.2 |
      |  1.1  -------------
      |       | 2.3 | 2.4 |
      ---------------------
    */
    {
      id: '2.1.4',
      description: 'Two groups, 1 big and 4 small widgets',
      row: 1,
      items: [
        {
          col: 1,
          item: { group: 1, index: 1, fontFactor: fontFactor.xlarge }
        },
        {
          col: 1,
          items: [
            {
              row: 1,
              items: [
                { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 2, index: 2, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 2, index: 3, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 2, index: 4, fontFactor: fontFactor.small } }
              ]
            }
          ]
        }
      ]
    },
    /*
      ---------------------
      |       | 2.1 | 2.2 |
      |       -------------
      |  1.1  | 2.3 | 2.4 |
      |       -------------
      |       | 2.5 | 2.6 |
      ---------------------
    */
    {
      id: '2.1.6',
      description: 'Two groups, 1 big and 6 small widgets',
      row: 1,
      items: [
        {
          col: 1,
          item: { group: 1, index: 1, fontFactor: fontFactor.xlarge }
        },
        {
          col: 1,
          items: [
            {
              row: 1,
              items: [
                { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 2, index: 2, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 2, index: 3, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 2, index: 4, fontFactor: fontFactor.small } }
              ]
            },
            {
              row: 1,
              items: [
                { col: 1, item: { group: 2, index: 5, fontFactor: fontFactor.small } },
                { col: 1, item: { group: 2, index: 6, fontFactor: fontFactor.small } }
              ]
            }
          ]
        }
      ]
    },
    /*
      -------------
      | 1.1 | 2.1 |
      -------------
      | 3.1 | 4.1 |
      -------------
    */
    {
      id: '4.0.4',
      description: '4 groups, 4 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.medium } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 4, index: 1, fontFactor: fontFactor.medium } },
          ]
        }
      ]
    },
    /*
      -------------------
      | 1.1 | 2.1 | 3.1 |
      -------------------
      | 4.1 | 5.1 | 6.1 |
      -------------------
    */
    {
      id: '6.0.6',
      description: '6 groups, 6 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.medium } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 4, index: 1, fontFactor: fontFactor.medium } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 5, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 6, index: 1, fontFactor: fontFactor.medium } },
          ]
        }
      ]
    },
    /*
      -------------------
      | 1.1 | 2.1 | 3.1 |
      -------------------
      | 4.1 | 5.1 | 6.1 |
      -------------------
      | 7.1 | 8.1 | 9.1 |
      -------------------
    */
    {
      id: '9.0.9',
      description: '9 groups, 9 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.medium } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 4, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 5, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 6, index: 1, fontFactor: fontFactor.medium } },
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 7, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 8, index: 1, fontFactor: fontFactor.medium } },
            { col: 1, item: { group: 9, index: 1, fontFactor: fontFactor.medium } }
          ]
        }
      ]
    },
    /*
      -------------------
      | 1.1 | 2.1 | 3.1 | 4.1 |
      -------------------
      | 5.1 | 6.1 | 7.1 | 8.1 |
      -------------------
      | 9.1 |10.1 |11.1 |12.1 |
      -------------------
      |13.1 |14.1 |15.1 |16.1 |
      -------------------
    */
    {
      id: '16.0.16',
      description: '16 groups, 16 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 4, index: 1, fontFactor: fontFactor.xsmall } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 5, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 6, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 7, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 8, index: 1, fontFactor: fontFactor.xsmall } },
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 9, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 10, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 11, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 12, index: 1, fontFactor: fontFactor.xsmall } }
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 13, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 14, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 15, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 16, index: 1, fontFactor: fontFactor.xsmall } }
          ]
        }
      ]
    },
    /*
      -------------------
      | 1.1 | 2.1 | 3.1 | 4.1 | 5.1 |
      -------------------
      | 6.1 | 7.1 | 8.1 | 9.1 |10.1 |
      -------------------
      |11.1 |12.1 |13.1 |14.1 |15.1 |
      -------------------
      |16.1 |17.1 |18.1 |19.1 |20.1 |
      -------------------
      |21.1 |22.1 |23.1 |24.1 |25.1 |
      -------------------
    */
    {
      id: '25.0.25',
      description: '25 groups, 25 widgets',
      row: 1,
      items: [
        {
          col: 1,
          items: [
            { col: 1, item: { group: 1, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 2, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 3, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 4, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 5, index: 1, fontFactor: fontFactor.xsmall } },
          ]
        },
        {
          col: 1,
          items: [  
            { col: 1, item: { group: 6, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 7, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 8, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 9, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 10, index: 1, fontFactor: fontFactor.xsmall } },
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 11, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 12, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 13, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 14, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 15, index: 1, fontFactor: fontFactor.xsmall } }
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 16, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 17, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 18, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 19, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 20, index: 1, fontFactor: fontFactor.xsmall } }
          ]
        },
        {
          col: 1,
          items: [
            { col: 1, item: { group: 21, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 22, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 23, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 24, index: 1, fontFactor: fontFactor.xsmall } },
            { col: 1, item: { group: 25, index: 1, fontFactor: fontFactor.xsmall } }
          ]
        }
      ]
    },
  ]
}

export { layouts }
