import ReduxActions from '../../constants/redux-actions';
import { storeSettings } from './settings';

const changeTheme = (themeType) => (dispatch) => {
  dispatch(storeSettings({ themeType }));
  dispatch({
    type: ReduxActions.CHANGE_THEME,
    themeType,
  });
};

export {
  changeTheme,
};
