import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const groupsRecord = (state) => state.groups;

const attendingGroupsSelector = createSelector(
  groupsRecord,
  (record = EMPTY_OBJECT) => record.attendingGroups,
);

export {
  attendingGroupsSelector,
};
