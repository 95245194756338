import React, { Component } from 'react';
import './ContextMenu.css';

class ContextMenu extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible: false,
      selectedValue: undefined,
    };

    
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  open({ clientX, clientY }, type, kpi) {
    const afterStateUpdate = () => {
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = this.root.offsetWidth;
      const rootH = this.root.offsetHeight;

      const top = screenH - clientY <= rootH;
      const left = screenW - clientX <= rootW;

      this.root.style.top = top ? `${clientY - rootH - 5}px` : `${clientY + 5}px`;
      this.root.style.left = left ? `${clientX - rootW - 5}px` : `${clientX + 5}px`;
    };

    this.setState(
      {
        visible: true,
        selectedValue: kpi || type,
      },
      afterStateUpdate,
    );
  }

  close() {
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  }

  handleClick(event) {
    const { onChange } = this.props;
    const { target } = event;
    const selectedKpi = target.dataset.kpi;
    onChange(selectedKpi);
  }

  render() {
    const { visible, selectedValue } = this.state;
    const { options } = this.props;
    return visible && options.length
      ? (
        <div
          role="button"
          ref={(ref) => {
            this.root = ref;
          }}
          className="contextMenu"
          onClick={this.handleClick}
        >
          {
            options
              .map(
                ({ label, value, icon }) => {
                  const selectedClass = value === selectedValue ? 'contextMenu--selected' : '';
                  const className = `contextMenu--option ${selectedClass}`;
                  const iconImage = icon
                    ? <img className="contextMenu--option--icon" src={icon} alt="icon" />
                    : null;
                  return (
                    <div
                      key={value}
                      className={className}
                      data-kpi={value}
                    >
                      {iconImage}
                      {label}
                    </div>
                  );
                },
              )
          }
        </div>
      )
      : null;
  }
}

export default ContextMenu;
