import React from 'react';
import NumberWidget from './widgets/NumberWidget';
import BarChartWidget from './widgets/BarChartWidget';
import PieChartWidget from './widgets/PieChartWidget';
import ChartTableWidget from './widgets/ChartTableWidget';
import WidgetTypes from '../constants/widget-types';
import AgentListWidget from './widgets/AgentListWidget';

const styles = {
  widgetContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 10,
    outline: 'none',
  },
  widgetInternalContainer: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 15,   
    padding: '10px',
    width: '100%', 
    height: '100%', 
  }
};

type WidgetProps = {
  type: String,
  id: Number,
  groupId: Number,
  groupNo: Number,
  props: Object,
  triggered: Boolean,
  handleGroupChange: Function
};


type DashboardItemProps = {
  widget: WidgetProps,
  theme: Object,
  handleClick: Function,
  fontFactor: number
};

class DashboardItem extends React.Component<DashboardItemProps> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isActive: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleWidgetClick = this.handleWidgetClick.bind(this);

    this.widgetContainer = undefined;
  }

  handleMouseEnter() {
    this.setState({ isActive: true });
  }

  handleMouseLeave() {
    this.setState({ isActive: false });
  }

  handleWidgetClick(event) {
    const {
      x, y, width, height,
    } = this.widgetContainer.getBoundingClientRect();
    const { widget, handleClick, themeType, localization } = this.props;
    const { id, groupNo, groupId, type, props: { kpi } } = widget;
    handleClick(event, { id, groupNo, groupId, kpi, type, themeType, localization }, {
      x, y, width, height,
    });
  }

  render() {
    const {
      widget,
      theme,
      groups,
      handleGroupChange,
      fontFactor,
      isAlertVisible,
    } = this.props;
    const { isActive } = this.state;
    const widgetTypeMap = {
      [WidgetTypes.NUMBER]: NumberWidget,
      [WidgetTypes.BAR_CHART]: BarChartWidget,
      [WidgetTypes.PIE_CHART]: PieChartWidget,
      [WidgetTypes.CHART_GRID]: ChartTableWidget,
      [WidgetTypes.AGENT_SIMPLE_LIST]: AgentListWidget,
      [WidgetTypes.TOP_LIST_IN]: AgentListWidget,
      [WidgetTypes.TOP_LIST_OUT]: AgentListWidget,
      // eslint-disable-next-line
      ["AGENT_LIST"]: AgentListWidget, // hack to pass old settings in DB
      [WidgetTypes.AGENTS_PIE_CHART]: PieChartWidget,
    };
    const Widget = widgetTypeMap[widget.type];
    if (!Widget) {
      throw new Error(`There is not widget for the type ${widget.type}`);
    }
    return (
      <div
        className="widget"
        role="button"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleWidgetClick}
        ref={(widgetContainer) => { this.widgetContainer = widgetContainer; }}
        style={{
          ...styles.widgetContainer,
          backgroundColor: isActive
            ? theme.WIDGET_BACKGROUND_ACTIVE
            : theme.WIDGET_BACKGROUND,
          borderColor: theme.BACKGROUND,
        }}
      >
        <div style={{
          ...styles.widgetInternalContainer,
          borderColor: widget.triggered && isAlertVisible ? "chocolate" : "transparent"}}>
          <Widget
            {...widget.props}
            id={widget.id}
            type={widget.type}
            groupNo={widget.groupNo}
            groupId={widget.groupId}
            groups={groups}
            fontFactor={fontFactor}
            onGroupChange={handleGroupChange}
          />
        </div>
      </div>
    );
  }
}

export default DashboardItem;
