import ReduxActions from '../../constants/redux-actions';
//import mockedGroups from "../../configs/groups";


const defaultState = {
  attendingGroups: [],
};

const groups = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.UPDATE_ATTENDING_GROUPS: {
      return {
        ...state,
        attendingGroups: action.attendingGroups,
      };
    }
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default groups;
