import { tryParse } from './index';

const getItem = (key) => {
  const value = localStorage.getItem(key);
  return tryParse(value) || value;
};

const setItem = (key, item) => {
  const value = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;
  localStorage.setItem(key, value);
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

export {
  getItem,
  setItem,
  removeItem,
};
