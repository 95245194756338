// @flow
import React, { Component } from 'react';
import LinkSelect from "../LinkSelect";
import ChartTable from './ChartTable';
import LocalizationContext from '../../contexts/LocalizationContext';
import ThemeContext from '../../contexts/ThemeContext';

const styles = {
  widget: {
    display: 'flex',
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
    height: '100%',
    justifyContent: "space-between",
  },
};

type ChartTableWidgetProps = {
  successful: number,
  unsuccessful: number,
  successfulLabel?: string,
  unsuccessfulLabel?: string,
  totalLabel?: string,
  id: number,
  groupNo: number,
  groupId: number,
  fontFactor: number
};

class ChartTableWidget extends Component<ChartTableWidgetProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFirstRender: true,
      trHeight: 0
    };
  }
  componentDidMount() {
    const trHeight = this.trElement.clientHeight;
    this.setState({ trHeight });
  }
  render() {
    const {
      successful,
      unsuccessful,
      successfulLabel,
      unsuccessfulLabel,
      totalLabel,
      groups,
      onGroupChange,
      groupId,
      fontFactor
    } = this.props;

    const { isFirstRender, trHeight } = this.state;
    const options = groups.map(({ id, name }) => ({
      value: `${id}`,
      label: name
    }));
    const selectedValue = options.find(
      option => option.value === `${groupId}`
    ) || options[0];
    
    return (
      <LocalizationContext.Consumer>
        {(localization) => {
          const labels = {
            successfulLabel: localization[successfulLabel],
            unsuccessfulLabel: localization[unsuccessfulLabel],
            totalLabel: localization[totalLabel],
          };
          return (
            <ThemeContext.Consumer>
              {(theme) => (
                <div style={styles.widget}>
                  <table style={{ width: '100%', height: '100%' }}>
                    <tbody>
                      <tr style={{ height: '40px' }}>
                        <td style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                          <LinkSelect
                          className="link-select"
                          fontFactor={fontFactor}
                          options={options}
                          value={selectedValue}
                          onChange={event =>
                            onGroupChange(
                              event.value,
                              this.props.groupNo,
                              this.props.id,
                              this.props.kpi,
                              this.props.type
                            )
                          }
                          selectStyle={{
                            color: theme.TITLE
                          }}
                        />
                      </td></tr>
                      <tr ref={(trElement) => this.trElement = trElement}>
                        <td title={localization.TIP_CHANGE_MTRC} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>

                          <ChartTable
                            successful={successful}
                            unsuccessful={unsuccessful}
                            labels={labels}
                            isFirstRender={isFirstRender}
                            fontFactor={fontFactor}
                            theme={theme}
                            height={trHeight}
                          />
                        </td></tr>
                      </tbody>
                    </table >
                </div>
              )}
            </ThemeContext.Consumer>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

ChartTableWidget.defaultProps = {
  successfulLabel: 'ANSWERED',
  unsuccessfulLabel: 'MISSED',
  totalLabel: 'TOTAL',
};

export default ChartTableWidget;
