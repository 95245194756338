// @flow
import React from 'react';
import IconsSwitch from './IconsSwitch';
import ThemeTypes from '../constants/theme-types';
import { selectThemeIcon } from '../utils';

import SunLightIcon from '../images/sun-light.svg';
import SunDarkIcon from '../images/sun-dark.svg';

import MoonLightIcon from '../images/moon-light.svg';
import MoonDarkIcon from '../images/moon-dark.svg';

type ThemeSwitchProps = {
  themeType: ThemeTypes.DARK | ThemeTypes.LIGHT,
  onThemeChange: Function,
};

const styles = {
  sunIcon: {
    width: 24,
    height: 24,
    marginRight: 4,
  },
  moonIcon: {
    width: 20,
    height: 20,
    marginLeft: 4,
  },
};

const ThemeSwitch = (props: ThemeSwitchProps) => {
  const { themeType, onThemeChange, ...restProps } = props;
  const isChecked = themeType === ThemeTypes.DARK;
  const SunIcon = selectThemeIcon(themeType, SunLightIcon, SunDarkIcon);
  const MoonIcon = selectThemeIcon(themeType, MoonLightIcon, MoonDarkIcon);
  return (
    <IconsSwitch
      tooltipKey='SETTINGS_LIGHT_DARK_TOOLTIP'
      isChecked={isChecked}
      onChange={
        (checked) => onThemeChange(checked ? ThemeTypes.DARK : ThemeTypes.LIGHT)
      }
      leftIcon={SunIcon}
      leftIconStyle={styles.sunIcon}
      rightIcon={MoonIcon}
      rightIconStyle={styles.moonIcon}
      {...restProps}
    />
  );
};

export default ThemeSwitch;
