// @flow
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "react-bootstrap";
import ThemeSwitch from "../components/ThemeSwitch";
import * as localizationActions from "../store/actions/localization";
import * as themeActions from "../store/actions/theme";
import * as authActions from "../store/actions/auth";
import * as settingsActions from "../store/actions/settings";
import { changeInstance } from "../store/actions/layoutSettings";
import * as navigationActions from "../store/actions/navigation";
import {
  currentLanguageSelector,
  availableLanguagesSelector
} from "../store/selectors/localization";
import { themeTypeSelector } from "../store/selectors/theme";
import { userSelector } from "../store/selectors/auth";
import { isHeaderToolsModeActiveSelector, isClockVisibleSelector } from "../store/selectors/settings";
import {
  selectedInstanceSelector,
  availableInstancesSelector
} from "../store/selectors/layoutSettings";
import { currentScreenNameSelector } from "../store/selectors/navigation";
import { layoutSelector } from "../store/selectors/layout";
import ThemeTypes from "../constants/theme-types";
import ScreenNames from "../constants/screen-names";
import { selectThemeIcon } from "../utils";
import ThemeContext from "../contexts/ThemeContext";
import LocalizationContext from '../contexts/LocalizationContext';
import Clock from '../components/Clock'

import SaveIconDark from "../images/save-dark.svg";
import SaveIconLight from "../images/save-light.svg";
import SaveIconGreen from "../images/save-green.svg";
import SaveIconRed from "../images/save-red.svg";
import GearIconLight from "../images/gear-light.svg";
import GearIconDark from "../images/gear-dark.svg";
import LogoutIconLight from "../images/logout-light.svg";
import LogoutIconDark from "../images/logout-dark.svg";

import "./Header.css";

const styles = {
  languageSelect: {
    padding: "10px 0",
    maxWidth: '130px',
    width: '130px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: 60,
    width: '100%'
  },
  leftSide: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 20,
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 'auto',
    marginRight: 20
  },
  themeSwitch: {
    alignSelf: "center",
    marginRight: 10
  },
  layoutIcon: {
    width: 32,
    height: 24
  },
  settingsIcon: {
    width: 32,
    height: 24
  },
  saveIcon: {
    width: 24,
    height: 24
  },
  savedIcon: {
    width: 24,
    height: 24,
    marginLeft: -24,
    visibility: "hidden"
  },
  savedIconError: {
    width: 24,
    height: 24,
    marginLeft: -24,
    visibility: "hidden"
  },
  iconButton: {
    paddingLeft: 8,
    paddingRight: 8
  },
  backButtonTop: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 0,
  },
  logOutButton: {
    margin: '10px',
    background: 'chocolate',
    color: 'white',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
};

type HeaderProps = {
  currentLang: string,
  availableLang: Array,
  themeType: ThemeTypes.DARK | ThemeTypes.LIGHT,
  actions: Object,
  isHeaderToolsModeActive: boolean,
  isClockVisible: boolean,
  currentScreen: String,
  user: Object,
  onSaveLayoutSettingsClick: Function,
  onInstanceChange: Function,
  onLogout: Function,
  selectedInstance: Object,
  availableInstances: Array
};

const Header = (props: HeaderProps) => {
  const {
    themeType,
    isHeaderToolsModeActive,
    isClockVisible,
    currentScreen,
    user: { isAdmin },
    selectedInstance,
    onSaveLayoutSettingsClick,
    onLogout,
    actions: {
      changeTheme,
      logout,
      changeScreen
    }
  } = props;
  const settings = () => changeScreen(ScreenNames.SETTINGS);

  const SaveIcon = selectThemeIcon(themeType, SaveIconLight, SaveIconDark);
  const GearIcon = selectThemeIcon(themeType, GearIconLight, GearIconDark);
  const isDashboardScreen = currentScreen === ScreenNames.DASHBOARD;
  const LogoutIcon = selectThemeIcon(
    themeType,
    LogoutIconLight,
    LogoutIconDark
  );
  const handleLogout = event => {
    onLogout(event);
    logout();
  };
  const handleSaveLayoutSettings = event => {
    const btn = event.target;
    btn.classList.add("imgButtonSaving");

    onSaveLayoutSettingsClick(event, error => {
      //console.log('Save callback!!!');
      //console.log(`Save callback!!! ${error ? "ERROR: " + error : ""}`);

      const errMess = error;
      setTimeout(() => {
        btn.classList.remove("imgButtonSaving");
        var elSave = document.getElementById("imgButtonSaveConfig");
        if(elSave) {
          elSave.style.visibility = "hidden";
        }
        if (!errMess) {
          var elSaved = document.getElementById("imgButtonSavedConfig");
          if(elSave) {
            elSaved.style.visibility ="visible";
          }
        } else {
          var elError = document.getElementById("imgButtonSavedConfigError");
          if(elError) {
            elError.style.visibility = "visible";
          }
        }

        setTimeout(() => {
          var elSave = document.getElementById("imgButtonSaveConfig");
          if(elSave) {
            elSave.style.visibility = "visible";
          }
          var elSaved = document.getElementById("imgButtonSavedConfig");
          if(elSave) {
            elSaved.style.visibility ="hidden";
          }
          var elError = document.getElementById("imgButtonSavedConfigError");
          if(elError) {
            elError.style.visibility = "hidden";
          }
        }, 1000);
      }, 500);
    });
  };
  if(selectedInstance.shortName) { 
    document.title = "WB-" + selectedInstance.shortName; 
  }
    
  return (
    <LocalizationContext.Consumer>
      {(localization) => (

    <ThemeContext.Consumer>
      {theme => (
        <div
          style={{
            ...styles.container,
            backgroundColor: theme.HEADER_BACKGROUND
          }}
        >
          { isClockVisible &&
            <div style={styles.leftSide}>
            <Clock/>
            </div> 
          }
          <div style={styles.rightSide}>
            {[
              isDashboardScreen && (
                <Button
                  key="save-icon"
                  variant="link"
                  style={styles.iconButton}
                  onClick={event => handleSaveLayoutSettings(event)}
                  title={localization.SETTINGS_SAVE_TOOLTIP}
                >
                  <img
                    id="imgButtonSaveConfig"
                    src={SaveIcon}
                    style={styles.saveIcon}
                    alt="save"
                  />
                  <img
                    id="imgButtonSavedConfig"
                    src={SaveIconGreen}
                    style={styles.savedIcon}
                    alt="save"
                  />
                  <img
                    id="imgButtonSavedConfigError"
                    src={SaveIconRed}
                    style={styles.savedIconError}
                    alt="save"
                  />
                </Button>
              ),
              isHeaderToolsModeActive && isDashboardScreen && (
                <div key="theme-select" style={styles.themeSwitch}>
                <ThemeSwitch
                  themeType={themeType}
                  onThemeChange={changeTheme}
                />
              </div>),
              isDashboardScreen && isAdmin && (
                <Button
                  key="settings-icon"
                  variant="link"
                  style={styles.iconButton}
                  onClick={settings}
                  title={localization.SETTINGS_SETTINGS_TOOLTIP}
                >
                  <img
                    src={GearIcon}
                    style={styles.settingsIcon}
                    alt="setings"
                  />
                </Button>
              ),
              isDashboardScreen  && !isAdmin && (
                <Button
                  style={{
                    ...styles.logOutButton,
                  }}
                  variant="default"
                  onClick={ handleLogout }>
                  <img alt="logout" src={LogoutIcon} style={{...styles.icon}} />
                  {localization.LOGOUT}
                </Button>
              )
            ]}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
      )}
    </LocalizationContext.Consumer>
  );
};

const mapStateToProps = state => ({
  themeType: themeTypeSelector(state),
  currentLang: currentLanguageSelector(state),
  availableLang: availableLanguagesSelector(state),
  selectedInstance: selectedInstanceSelector(state),
  availableInstances: availableInstancesSelector(state),
  user: userSelector(state),
  isHeaderToolsModeActive: isHeaderToolsModeActiveSelector(state),
  isClockVisible: isClockVisibleSelector(state),
  currentScreen: currentScreenNameSelector(state),
  layout: layoutSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...localizationActions,
      ...themeActions,
      ...authActions,
      ...settingsActions,
      ...navigationActions,
      changeInstance
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
