import getCommon from './common'

export default (stateThemeType, stateTheme) => {
  const { theme, common } = getCommon(stateThemeType, stateTheme)

  const txts = {
    descrpt: 'Wallboard näyttää reaaliaikaisesti vastausryhmien jonotilanteen. Sivu koostuu ruudukosta jonka sisältöä ja rakennetta käyttäjä voi muokata.',
    cellHead: 'Solut:',
    cellDescrpt: ' Valitse jokaiseen soluun avainluku jota haluat seurata. Solut voivat näyttää avainlukuja eri ryhmistä.',
    metricsHead: 'AVAINLUVUT',
    metricsSubHead: 'Valitse avainluku:',
    metricsDescrpt: 'Klikkaa solua ja valitse seurattava avainluku pudotusvalikosta.',
    metricsChartSubHead: 'Näytä kaavio solussa:',
    metricsChartDescrpt: 'Voit esittää tietyt avainluvut graafisesti pylväsdiagrammina, ympyräkaaviona tai ruudukkona (vastaamattomat, vastatut tai yhteensä).',
    metricsOptnsHead: 'Avainluvut:',
    metricsOptnsQueHead: '&bull; Jonossa:',
    metricsOptnsQueDescrpt: 'Montako puhelua on jonossa odottamassa vastaamista.', 
    metricsOptnsLgdInHead: '&bull; Agentteja sisään<wbr>kirjautuneena:',
    metricsOptnsLgdInDescrpt: 'Montako agenttia on ryhmässä sisäänkirjautuneena.',
    metricsOptnsAvailHead: '&bull; Agentteja vapaana:',
    metricsOptnsAvailDescrpt: 'Montako agenttia on vapaana ottamaan vastaan puheluita.',
    metricsOptnsTotHead: '&bull; Yhteensä:',
    metricsOptnsTotDescrpt: 'Puheluita yhteensä viimeisen 12h aikana.',
    metricsOptnsAnswHead: '&bull; Vastattuja:',
    metricsOptnsAnswDescrpt: 'Vastattuja puheluita yhteensä viimeisen 12h aikana.',
    metricsOptnsMissHead: '&bull; Vastaamattomia:',
    metricsOptnsMissDescrpt: 'Vastaamattomien puheluiden kokonaismäärä viimeisen 12h aikana.',
    metricsOptnsLtstHead: '&bull; Viimeisin kesto:',
    metricsOptnsLtstDescrpt: 'Viimeisimmän puhelun jonotusaika (tässä ryhmässä).',
    metricsOptnsAvQueTimHead: '&bull; Jonotusaika keskimäärin:',
    metricsOptnsAvQueTimDescrpt: 'Keskimääräinen jonotusaika viimeisen 12h aikana.',
    metricsOptnsAvCallDurHead: '&bull; Puhelun kesto keskimäärin:',
    metricsOptnsAvCallDurDescrpt: 'Keskimääräinen vastatun puhelun kesto viimeisen 12h aikana.', /* is always ANSWERED? not called? */
    metricsOptnsFwdedHead: '&bull; Välitetty:',
    metricsOptnsFwdedDescrpt: 'Välitettyjä puheluita yhteensä viimeisen 12h aikana.',
    metricsOptnsChartHead: '&bull; Pylväsdiagrammi,<br />&nbsp;&nbsp;&nbsp;ympyrädiagrammi<br />&nbsp;&nbsp;&nbsp;tai taulukko:',
    metricsOptnsChartDescrpt: 'Näyttää Vastatut, Vastaamattomat ja Puheluita yhteensä -määrät viimeisen 12h ajalta.',
    toolbarHead: 'TYÖKALUT',
    toolbarDescrpt: 'Ruudun oikeassa ylälaidassa olevilla nappuloilla muutetaan asetuksia.',
    layoutHead: 'Ulkonäkö:',
    layoutDescrpt: 'Valitse ruudukon ulkonäkö eri vaihtoehdoista. Samalla sivulla voidaan näyttää avainlukuja yhdestä tai useammasta ryhmästä.',
    saveHead: 'Tallenna:',
    saveDescrpt: 'Tallentaa konfiguraation, valinnat näkyvät kaikilla käyttäjillä.', /* (ko. konfiguraatiossa) */
    lightnessHead: 'Päivä / yö tila:',
    lightnessDescrpt: 'Vaihda näkymää yö ja päivä tilojen välillä, asetus on selainkohtainen eikä sitä tallenneta.',
    lightness2Head: 'Päivä / yö tila:',
    lightness2Descrpt: '(Sama kuin edellä).',
    settingsHeader: 'ASETUKSET',
    buttonsOnOffDescrpt: 'Näytä tai piilota työkalut.',
    languageDroplist: 'FI',
    languageDescrpt: 'Valitse kieli.',
    answerGroupDroplist: 'WB 1',
    answerGroupHead: 'Valitse konfiguraatio:',
    answerGroupDescrpt: 'Huom. Asetus näkyy ainostaan usean Wallboard konfiguraation tilanneilla asiakkailla.',
    answerGroupDescrpt2: 'Tallentaa keskitetysti valitun ulkonäön, avainluvut sja ryhmän(t). Jos yrityksessä on käytössä yksi Wallboard konfiguraatio: Kaikki Wallboardit näyttävät saman sisällön. Jos yrityksessä on käytössä useampi Wallboard konfiguraatio: Kaikki Wallboardit näyttävät saman sisällön valitusta konfiguraatiosta.',
    helpDescrpt: 'Ohje (tämä sivu)',
    logoutDescrpt: 'Uloskirjautuminen, lukuja ei näytetä uloskirjautumisen jälkeen.'
  }

  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,

    settingsItems: `<br><div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right +
      common.left + common.buttonsIcon + common.middle100Minus141px + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle100Minus141px + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle100Minus141px + `${txts.logoutDescrpt}` + common.right
  }

  return `<span style="color: ${theme.obj.LABEL}"><div style="float:left;padding: 0 0 10px 0px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right +
    '</div></span>'
}

  /*
  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle +  `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,
      
    settingsItems: `<div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` + 
      common.left + common.lightnessIcon + common.middle + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right + 
      common.left + common.buttonsIcon + common.middle + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle + `${txts.logoutDescrpt}` + common.right,
  }

  return `<span style="color: ${theme.obj.LABEL}">` + `<br /><div style="float:left;width:51%;padding:0 80px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="width:44%;float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right + 
    '</div></span>'
}
*/