import fi from './fi'
import en from './en'
import sv from './sv'
import nl from './nl'
import de from './de'
import da from './da'

export default {
  fi: (themeType, theme) => fi(themeType, theme),
  sv: (themeType, theme) => sv(themeType, theme),
  de: (themeType, theme) => de(themeType, theme),
  da: (themeType, theme) => da(themeType, theme),
  nl: (themeType, theme) => nl(themeType, theme),
  en: (themeType, theme) => en(themeType, theme)
}
