import ReduxActions from '../../constants/redux-actions';

const defaultState = {
  loggedIn: false,
  logging: false,
  isAdmin: false,
  username: undefined,
  error: undefined,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.LOGIN_STARTED:
      return Object.assign({}, state, {
        logging: true,
      });
    case ReduxActions.LOGIN_FINISHED: {
      const { isAdmin, username } = action;
      return Object.assign({}, state, {
        isAdmin,
        username,
        loggedIn: true,
        logging: false,
        error: undefined,
      });
    }
    case ReduxActions.LOGIN_ERROR:
      return Object.assign({}, state, {
        loggedIn: false,
        logging: false,
        error: action.error,
      });
    case ReduxActions.RESET_STATE:
      return {...state, ...defaultState};
    case ReduxActions.LOGOUT:
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
};

export default auth;
