// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import ThemeContext from '../contexts/ThemeContext';
import * as authActions from '../store/actions/auth';
import { loggingSelector } from '../store/selectors/auth';

const styles = {
  container: {
    height: 'calc(100% - 120px)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: 500,
  },
};

type LoginProps = {
  logging: boolean,
  actions: Object,
};

class Login extends Component<LoginProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: '',
      password: '',
      loginFail: false
    };

    this.handleUsernameChage = this.handleUsernameChage.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginResponse = this.handleLoginResponse.bind(this);
  }

  handleUsernameChage(event) {
    const { value } = event.target;
    this.setState({ username: value });
  }

  handlePasswordChange(event) {
    const { value } = event.target;
    this.setState({ password: value });
  }

  handleLoginResponse(success) {
    if (!success) {
      this.setState({ loginFail: true });
      setTimeout(() => {
        this.setState({ loginFail: false });
      }, 1500);
    }
  }

  handleLogin() {
    const { actions: { login } } = this.props;
    const { username, password } = this.state;
    login(username, password, this.handleLoginResponse);
  }

  render() {
    const { username, password, loginFail } = this.state;
    const { logging } = this.props;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div style={{ ...styles.container, background: theme.BACKGROUND }}>
            <Form style={styles.form}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ fontWeight: (loginFail ? 'bold' : '400'), color: (loginFail ? '#bd566a' : theme.LABEL) }}>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  autoComplete="username"
                  value={username}
                  onChange={this.handleUsernameChage}
                  style={{ color: (loginFail ? '#bd566a' : '#6c757d'), borderColor: (loginFail ? '#bd566a' : '#ced4da') }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label style={{ fontWeight: (loginFail ? 'bold' : '400'), color: (loginFail ? '#bd566a' : theme.LABEL) }}>Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  value={password}
                  onChange={this.handlePasswordChange}
                  style={{ color: (loginFail ? '#bd566a' : '#6c757d'), borderColor: (loginFail ? '#bd566a' : '#ced4da') }}
                />
              </Form.Group>
              <Button
                className="float-right"
                style={{ background: (loginFail ? '#9b3448' : theme.THEME_SWTICH_BULLET), color: (loginFail ? '#ff9aae' : theme.LABEL), borderColor: (loginFail ? '#bd566a' : '#007bff') }}
                variant="primary"
                type="button"
                onClick={this.handleLogin}
              >

                {logging && (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;
                  </span>
                )}
                Login
              </Button>
            </Form>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  logging: loggingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...authActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
