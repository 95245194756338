import axios from "../utils/axios-utility";
import httpStatusCodes from "../constants/http-status-codes";
import { CORE_BASE, DEV_ENV, STAGE_ENV } from "../configs";

const loadConfigs = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${CORE_BASE}/que/instances`)
      .then(({ status, data }) => {
        data.isDevEnv = DEV_ENV
        data.isStageEnv = STAGE_ENV
        status === httpStatusCodes.OK ? resolve(data): reject(data)
      })
      .catch(reject);
  });

const saveConfigs = layoutSettings =>
  new Promise((resolve, reject) => {
    axios
      .post(`${CORE_BASE}/que/savelayout`, layoutSettings)
      .then(({ status, data }) =>
        status === httpStatusCodes.OK ? resolve(data) : reject(data)
      )
      .catch(reject);
  });

const saveCulture = culture =>
  new Promise((resolve, reject) => {
    axios
      .put(`${CORE_BASE}/que/culture/${culture}`)
      .then(({ status, data }) =>
        status === httpStatusCodes.OK ? resolve(data) : reject(data)
      )
      .catch(reject);
  });

export { saveConfigs, loadConfigs, saveCulture };
