import helpContent from './constants/helpcontent/all'

const localizations = {
  getAvailableLanguages: () => {
    const avLangs = []
    for (var key in localizations) {
      if (localizations.hasOwnProperty(key) && key !== 'getAvailableLanguages') {
        // let val = localizations[key]
        avLangs.push(key)
      }
    }
    avLangs.sort()
    return avLangs
  },
  en: {
    IN_QUEUE: 'Calls in queue',
    LOGGED_IN_AGENTS: 'Available agents',
    AVAILABLE_AGENTS: 'Free agents',
    TOTAL: 'Calls total',
    ANSWERED: 'Answered calls',
    MISSED: 'Missed calls',
    FORWARDED: 'Forwarded calls',
    LATEST: 'Recent call waiting time',
    LONGEST_WAITING: 'Longest waiting',
    AVERAGE_DURATION: 'Average call duration',
    AVERAGE_WAIT: 'Average waiting time',
    MISSED_CALLS: 'Missed calls',
    CALLS_TOTAL: 'Calls total',
    WAITING_TIME: 'Waiting time',
    AGENTS: 'Agents',
    CALLS_BAR_CHART_LABEL: 'Bar chart (Calls)',
    CALLS_PIE_CHART_LABEL: 'Pie chart (Calls)',
    CALLS_CHART_GRID_LABEL: 'Grid (Calls)',
    HELP_TITLE: 'Help',
    getHelpContent: (themeType, theme) => helpContent.en(themeType, theme),
    HELP_CONTENT_ADMIN: 'This is a placeholder for admin-helpcontent',
    LOGOUT: 'Log out',
    HELP: 'Help',
    ALERTS: 'Alerts',
    BACK: 'Back',
    CANCEL: 'Cancel',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Information from a single group',
    LAYOUT_HEADER_MULTI: 'Information from multiple groups',
    TIP_CHANGE_GRP: 'Change group...',
    TIP_CHANGE_MTRC: 'Change metric or chart...',
    SETTINGS_HEADER_SINGLE: 'Settings',
    SETTINGS_GENERAL: 'General',
    SETTINGS_ALERTS: 'Alerts',
    SETTINGS_SCREENS: 'Screens',
    SETTINGS_LAYOUTS: 'Layouts',
    SETTINGS_HELP: 'Helps',
    SETTINGS_SAVE_TOOLTIP: 'Save',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Light/Dark',
    SETTINGS_SETTINGS_TOOLTIP: 'Settings',
    SETTINGS_CLOCK_TOOLTIP: 'Clock',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visual Notifications',
    INSTANCE_EDIT: 'Edit',
    INSTANCE_SAVE: 'Save',
    INSTANCE_CANCEL: 'Cancel',
    ALL_GROUPS: 'All Groups',
    AGENT_LIST: 'Agent list (alphabetical)',
    TOP_LIST_IN: 'Toplist Incoming',
    TOP_LIST_OUT: 'Toplist Outgoing',    
    AGENTS_PIE_CHART_LABEL: 'Pie chart (Agents)',
    FREE_AGENTS: 'Free Agents',
    AVAILABLE_AGENT_LIST: 'Available agents',
    AGENTS_IN_CALL: 'Busy agents'
  },
  sv: {
    IN_QUEUE: 'Samtal i kö',
    LOGGED_IN_AGENTS: 'Tillgängliga agenter',
    AVAILABLE_AGENTS: 'Lediga agenter ',
    TOTAL: 'Samtal totalt',
    ANSWERED: 'Besvarade samtal',
    MISSED: 'Missade samtal',
    FORWARDED: 'Vidarebefordrade samtal',
    LATEST: 'Kötid senaste samtalet',
    LONGEST_WAITING: 'Längsta väntetid',
    AVERAGE_DURATION: 'Genomsnittlig samtalstid',
    AVERAGE_WAIT: 'Genomsnittlig kötid',
    MISSED_CALLS: 'Missade samtal',
    CALLS_TOTAL: 'Samtal totalt',
    WAITING_TIME: 'Väntetid',
    AGENTS: 'Agenter',
    CALLS_BAR_CHART: '',
    CALLS_PIE_CHART: '',
    CALLS_CHART_GRID_LABEL: '',
    HELP_TITLE: 'Hjälp',
    getHelpContent: (themeType, theme) => helpContent.sv(themeType, theme),
    HELP_CONTENT_ADMIN: 'Reserverad plats för admin-hjälptexter',
    LOGOUT: 'Logga ut',
    HELP: 'Hjälp',
    ALERTS: 'Varningar',
    BACK: 'Tillbaka',
    CANCEL: 'Avbryt',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Layouts som visar endast en grupp:',
    LAYOUT_HEADER_MULTI: 'Layouts med stöd för att visa flera grupper:',
    TIP_CHANGE_GRP: 'Byt grupp...',
    TIP_CHANGE_MTRC: 'Byt mätvärde eller diagram...',
    SETTINGS_HEADER_SINGLE: 'Inställningar',
    SETTINGS_GENERAL: 'Allmänt',
    SETTINGS_ALERTS: 'Larm',
    SETTINGS_SCREENS: 'Skärmar',
    SETTINGS_LAYOUTS: 'Layouts',
    SETTINGS_HELP: 'Hjälp',
    SETTINGS_SAVE_TOOLTIP: 'Spara',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Ljus/Mörk',
    SETTINGS_SETTINGS_TOOLTIP: 'Inställningar',
    SETTINGS_CLOCK_TOOLTIP: 'Klocka',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visuella Notifikationer',
    INSTANCE_EDIT: 'Ändra',
    INSTANCE_SAVE: 'Spara',
    INSTANCE_CANCEL: 'Avbryt',
    ALL_GROUPS: 'Alla grupper',
    AGENT_LIST: 'Agent listor (alfabetisk ordning)',
    TOP_LIST_IN: 'Topplista inkommande',
    TOP_LIST_OUT: 'Topplista utgående',    
    AGENTS_PIE_CHART_LABEL: 'Cirkeldiagram (agenter)',
    FREE_AGENTS: 'Lediga agenter',
    AVAILABLE_AGENT_LIST: 'Tillgängliga agenter',
    AGENTS_IN_CALL: 'Upptagna i samtal'
  },
  da: {
    IN_QUEUE: 'I kø',
    LOGGED_IN_AGENTS: 'Logged in agents',
    AVAILABLE_AGENTS: 'Logget ind agenter',
    TOTAL: 'i alt',
    ANSWERED: 'Besvaret',
    MISSED: 'Savnet',
    FORWARDED: 'Viderebragte',
    LATEST: 'Seneste',
    LONGEST_WAITING: 'Længst ventende',
    AVERAGE_DURATION: 'Gennemsnit opkaldsvarighed',
    AVERAGE_WAIT: 'Gennemsnitlig køetid',
    MISSED_CALLS: 'Ubesvarede opkald',
    CALLS_TOTAL: 'Opkald i alt',
    WAITING_TIME: 'Ventetid',
    AGENTS: 'Agenter',
    CALLS_BAR_CHART_LABEL: 'Søjlediagram (Calls)',
    CALLS_PIE_CHART_LABEL: 'Søjlediagram (Calls)',
    CALLS_CHART_GRID_LABEL: 'Grid (Calls)',
    HELP_TITLE: 'Hjælp',
    getHelpContent: (themeType, theme) => helpContent.da(themeType, theme),
    HELP_CONTENT_ADMIN: 'Dette er en pladsholder for admin-helpcontent',
    LOGOUT: 'Log ud',
    HELP: 'Hjælp',
    ALERTS: 'Alerts',
    BACK: 'Tilbage',
    CANCEL: 'Afbestille',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Oplysninger fra en gruppe',
    LAYOUT_HEADER_MULTI: 'Oplysninger fra flere grupper',
    TIP_CHANGE_GRP: 'Skift gruppe...',
    TIP_CHANGE_MTRC: 'Skift metric eller diagram...',
    SETTINGS_HEADER_SINGLE: 'Indstillinger',
    SETTINGS_GENERAL: 'Alment',
    SETTINGS_ALERTS: 'Alerts',
    SETTINGS_SCREENS: 'Skærme',
    SETTINGS_LAYOUTS: 'Layouts',
    SETTINGS_HELP: 'Hjælp',
    SETTINGS_SAVE_TOOLTIP: 'Gemme',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Lys/Mørk',
    SETTINGS_SETTINGS_TOOLTIP: 'Indstillinger',
    SETTINGS_CLOCK_TOOLTIP: 'Ur',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visuelle meddelelser',
    INSTANCE_EDIT: 'Redigere',
    INSTANCE_SAVE: 'Spare',
    INSTANCE_CANCEL: 'Annulering',
    ALL_GROUPS: 'Alle grupper',
    AGENT_LIST: 'Agentlister (alfabetisk orden)',
    TOP_LIST_IN: 'Toplister indgående',
    TOP_LIST_OUT: 'Toplister udgående',    
    AGENTS_PIE_CHART_LABEL: 'Lagkagediagram (Agents)',
    FREE_AGENTS: 'Ledige agenter',
    AVAILABLE_AGENT_LIST: 'Tilgængelige agenter',
    AGENTS_IN_CALL: 'Optaget i samtale'
  },
  fi: {
    IN_QUEUE: 'Jonossa',
    LOGGED_IN_AGENTS: 'Agentteja sisäänkirjautuneena',
    AVAILABLE_AGENTS: 'Agentteja vapaana',
    TOTAL: 'Yhteensä',
    ANSWERED: 'Vastattuja',
    MISSED: 'Vastaamattomia',
    FORWARDED: 'Välitetty',
    LATEST: 'Viimeisin',
    LONGEST_WAITING: 'Pisin jonotusaika',
    AVERAGE_DURATION: 'Puhelun kesto keskimäärin',
    AVERAGE_WAIT: 'Jonotusaika keskimäärin',
    MISSED_CALLS: 'Ei vastattuja puheluita',
    CALLS_TOTAL: 'Puheluita yhteensä',
    WAITING_TIME: 'Jonotusaika',
    AGENTS: 'Agentteja',
    CALLS_BAR_CHART_LABEL: 'Pylväsdiagrammi (Puheluita)',
    CALLS_PIE_CHART_LABEL: 'Ympyrädiagrammi (Puheluita)',
    CALLS_CHART_GRID_LABEL: 'Taulukko (Puheluita)',
    HELP_TITLE: 'Ohje',
    getHelpContent: (themeType, theme) => helpContent.fi(themeType, theme),
    HELP_CONTENT_ADMIN: 'Tämä on järjestelmänvalvojan-helpcontentin paikkamerkki',
    LOGOUT: 'Kirjaudu ulos',
    HELP: 'Ohje',
    ALERTS: 'Varoitukset',
    BACK: 'Takaisin',
    CANCEL: 'Peruuta',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Yhden ryhmän näkymät:',
    LAYOUT_HEADER_MULTI: 'Usean ryhmän näkymät:',
    TIP_CHANGE_GRP: 'Change group...',
    TIP_CHANGE_MTRC: 'Change metric or chart...',
    SETTINGS_HEADER_SINGLE: 'Asetukset',
    SETTINGS_GENERAL: 'Yleisesti',
    SETTINGS_ALERTS: 'Varoitukset',
    SETTINGS_SCREENS: 'Näytöt',
    SETTINGS_LAYOUTS: 'Ulkonäkö',
    SETTINGS_HELP: 'Ohje',
    SETTINGS_SAVE_TOOLTIP: 'Tallentaa',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Vaalea/Tumma',
    SETTINGS_SETTINGS_TOOLTIP: 'Asetukset',
    SETTINGS_CLOCK_TOOLTIP: 'Kello',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visuaaliset ilmoitukset',
    INSTANCE_EDIT: 'Leikata',
    INSTANCE_SAVE: 'Tallenna',
    INSTANCE_CANCEL: 'Peruuttaa',
    ALL_GROUPS: 'Kaikki ryhmät',
    AGENT_LIST: 'Agenttiluettelo (aakkosjärjestyksessä)',
    TOP_LIST_IN: 'Suosituimmat saapuvat',
    TOP_LIST_OUT: 'Suosituimmat lähtevät',    
    AGENTS_PIE_CHART_LABEL: 'Ympyräkaavio (edustajat)',
    FREE_AGENTS: 'Ilmaiset edustajat',
    AVAILABLE_AGENT_LIST: 'Saatavilla olevat agentit',
    AGENTS_IN_CALL: 'Varattu agentit'
  },
  de: {
    IN_QUEUE: 'In der Warteschlange',
    LOGGED_IN_AGENTS: 'Eingeloggte Agenten',
    AVAILABLE_AGENTS: 'Verfügbare Agenten',
    TOTAL: 'Gesamt',
    ANSWERED: 'Antwort',
    MISSED: 'Verpasst',
    FORWARDED: 'Übermittelt',
    LATEST: 'Letzte',
    LONGEST_WAITING: 'Längste Wartezeit',
    AVERAGE_DURATION: 'Durchschnittlicher Anruf',
    AVERAGE_WAIT: 'Durchschnittliche Wartezeit',
    MISSED_CALLS: 'Verpasste Anrufe',
    CALLS_TOTAL: 'Anruf insgesamt',
    WAITING_TIME: 'Wartezeit',
    AGENTS: 'Agenten',
    CALLS_BAR_CHART_LABEL: 'Balkendiagramm (Anrufe)',
    CALLS_PIE_CHART_LABEL: 'Balkendiagramm (Anrufe)',
    CALLS_CHART_GRID_LABEL: 'Zeitplan (Anrufe)',
    HELP_TITLE: 'Hilfe',
    getHelpContent: (themeType, theme) => helpContent.de(themeType, theme),
    HELP_CONTENT_ADMIN: 'Dies ist ein Platzhalter für admin-helpcontent',
    LOGOUT: 'Ausloggen',
    HELP: 'Hilfe',
    ALERTS: 'Warnungen',
    BACK: 'Zurück', // 'Zurück',
    CANCEL: 'Abbrechen', // 'Abbrechen',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Einzelgruppenlayouts:',
    LAYOUT_HEADER_MULTI: 'Layouts für mehrere Gruppen:',
    TIP_CHANGE_GRP: 'Change group...',
    TIP_CHANGE_MTRC: 'Change metric or chart...',
    SETTINGS_HEADER_SINGLE: 'Einstellungen',
    SETTINGS_GENERAL: 'Allgemeines',
    SETTINGS_ALERTS: 'Warnungen',
    SETTINGS_SCREENS: 'Schirme',
    SETTINGS_LAYOUTS: 'Entwürfe',
    SETTINGS_HELP: 'Hilfe',
    SETTINGS_SAVE_TOOLTIP: 'Sparen',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Hell/Dunkel',
    SETTINGS_SETTINGS_TOOLTIP: 'Die einstellungen',
    SETTINGS_CLOCK_TOOLTIP: 'Uhr',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visuelle Benachrichtigungen',
    INSTANCE_EDIT: 'Redigieren',
    INSTANCE_SAVE: 'Speichern',
    INSTANCE_CANCEL: 'Streichen',
    ALL_GROUPS: 'Alle Gruppen',
    AGENT_LIST: 'Agentenliste (alphabetisch)',
    TOP_LIST_IN: 'Toplist Incoming',
    TOP_LIST_OUT: 'Toplist ausgehend',    
    AGENTS_PIE_CHART_LABEL: 'Kreisdiagramm (Agenten)',
    FREE_AGENTS: 'Freie Agenten',
    AVAILABLE_AGENT_LIST: 'Verfügbare Agenten',
    AGENTS_IN_CALL: 'Beschäftigte Agenten'
  },
  nl: {
    IN_QUEUE: 'In de wachtrij',
    LOGGED_IN_AGENTS: 'Ingelogde agenten',
    AVAILABLE_AGENTS: 'Beschikbare agenten',
    TOTAL: 'Totaal',
    ANSWERED: 'Beantwoord',
    MISSED: 'Gemist',
    FORWARDED: 'Doorverbonden',
    LATEST: 'Laatste',
    LONGEST_WAITING: 'Langste wachten',
    AVERAGE_DURATION: 'Gemiddelde gespreksduur',
    AVERAGE_WAIT: 'Gemiddelde wachttijd',
    MISSED_CALLS: 'Gemiste gesprekken',
    CALLS_TOTAL: 'Gesprekken totaal',
    WAITING_TIME: 'Wachttijd',
    AGENTS: 'Agenten',
    CALLS_BAR_CHART_LABEL: 'Staafdiagram (gesprekken)',
    CALLS_PIE_CHART_LABEL: 'Cirkeldiagram (gesprekken)',
    CALLS_CHART_GRID_LABEL: 'Tabel (gesprekken)',
    HELP_TITLE: 'Help',
    getHelpContent: (themeType, theme) => helpContent.nl(themeType, theme),
    HELP_CONTENT_ADMIN: 'Dit is een tijdelijke aanduiding voor admin-helpcontent',
    LOGOUT: 'Afmelden',
    HELP: 'Help',
    ALERTS: 'Waarschuwingen',
    BACK: 'Terug',
    CANCEL: 'Annuleer',
    OK: 'OK',
    LAYOUT_HEADER_SINGLE: 'Indelingen voor één groep:',
    LAYOUT_HEADER_MULTI: 'Indelingen voor meerdere groepen:',
    TIP_CHANGE_GRP: 'Verander van groep...',
    TIP_CHANGE_MTRC: 'Verander statistiek of diagram...',
    SETTINGS_HEADER_SINGLE: 'Instellingen',
    SETTINGS_GENERAL: 'Algemeen',
    SETTINGS_ALERTS: 'Waarschuwingen',
    SETTINGS_SCREENS: 'Scherm',
    SETTINGS_LAYOUTS: 'Lay-out',
    SETTINGS_HELP: 'Help',
    SETTINGS_SAVE_TOOLTIP: 'Sparen',
    SETTINGS_LIGHT_DARK_TOOLTIP: 'Licht/Donker',
    SETTINGS_SETTINGS_TOOLTIP: 'Instellingen',
    SETTINGS_CLOCK_TOOLTIP: 'Klok',
    SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP: 'Visuele meldingen',
    INSTANCE_EDIT: 'Redigeren',
    INSTANCE_SAVE: 'Opslaan',
    INSTANCE_CANCEL: 'Annuleren',
    ALL_GROUPS: 'Alle groepen',
    AGENT_LIST: 'Agentlijst (alfabetisch)',
    TOP_LIST_IN: 'Toplijst inkomend',
    TOP_LIST_OUT: 'Toplijst uitgaand',    
    AGENTS_PIE_CHART_LABEL: 'Cirkeldiagram (agenten)',
    FREE_AGENTS: 'Vrije agenten',
    AVAILABLE_AGENT_LIST: 'Beschikbare agenten',
    AGENTS_IN_CALL: 'Agenten in gesprek'
  }
}

export default localizations
