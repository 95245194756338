import axios from '../utils/axios-utility';
import httpStatusCodes from '../constants/http-status-codes';
import { LOGIN_BASE } from '../configs';

// const testCoreAuth = (token, cb) => {
//   axios({
//     url: `${CORE_BASE}/que/testauth`,
//     method: 'GET',
//     headers: {
//       'Authorization': `Bearer ${token}`
//     }
//   })
//     .then(({ status, data }) => {
//       if (status === httpStatusCodes.OK) {
//         return cb();
//       }
//       return cb(data);
//     })
//     .catch(err => cb(err));
// };

const login = (username, password) => new Promise(
  (resolve, reject) => {
    axios({
      url: `${LOGIN_BASE}/Login`,
      method: 'POST',
      data: {
        username,
        password,
      },
    })
      .then(({ status, data }) => {
        if (status === httpStatusCodes.OK) {
          //testCoreAuth(
          return resolve(data);
        }
        return reject(data);
      })
      .catch(reject);
  },
);

const checkAuth = (accessToken) => new Promise(
  (resolve, reject) => {
      axios({
        method: 'GET',
        url: `${LOGIN_BASE}/Login/testauth`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(
          ({ status }) => status === httpStatusCodes.OK
            ? resolve()
            : reject(),
        )
        .catch(reject);
  });

export {
  login,
  checkAuth,
};
