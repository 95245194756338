import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const authRecord = (state) => state.auth;

const userSelector = createSelector(
  authRecord,
  (record = EMPTY_OBJECT) => ({
    username: record.username,
    isAdmin: record.isAdmin,
  }),
);

const loggedInSelector = createSelector(
  authRecord,
  (record = EMPTY_OBJECT) => record.loggedIn,
);

const loggingSelector = createSelector(
  authRecord,
  (record = EMPTY_OBJECT) => record.logging,
);

export {
  userSelector,
  loggedInSelector,
  loggingSelector,
};
