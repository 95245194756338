import { createSelector } from 'reselect'
const DefaultLogoLight = '/img/branding/logo-light.svg'
const DefaultLogoDark = '/img/branding/logo-dark.svg'

const EMPTY_OBJECT = {}
const EMPTY_ARRAY = []

const availableInstancesRecord = state => {
  if (
    state.layoutSettings &&
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.length
  ) {
    return state.layoutSettings.layoutsSettings.map(ls => ({
      accountId: ls.accountId,
      id: ls.instanceId,
      name: ls.name,
      shortName: ls.shortName,
      layoutId: ls.layoutId,
      logoDark: ls.logoDark,
      logoLight: ls.logoLight
    }))
  } else if (
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.layoutId
  ) {
    const ls = state.layoutSettings.layoutsSettings
    return [
      {
        accountId: ls.accountId,
        id: ls.instanceId,
        name: ls.name,
        shortName: ls.shortName,
        layoutId: ls.layoutId,
        logoDark: ls.logoDark,
        logoLight: ls.logoLight
      }
    ]
  } else {
    return EMPTY_ARRAY
  }
}
const selectedInstanceRecord = state => {
  if (
    state.layoutSettings &&
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.length
  ) {
    const layoutsSettings = state.layoutSettings.layoutsSettings
    const selectedInstance = state.layoutSettings.selectedInstance
    let laySet = state.layoutSettings.layoutsSettings[0] // default

    // if (
    //   selectedInstance &&
    //   layoutsSettings.some(ls => ls.accountId === selectedInstance)
    // ) {
    //   laySet = layoutsSettings.find(ls => ls.accountId === selectedInstance)
    // }
    if (
      selectedInstance &&
      layoutsSettings.some(ls => +ls.instanceId === +selectedInstance)
    ) {
      laySet = layoutsSettings.find(ls => +ls.instanceId === +selectedInstance)
    }

    return {
      accountId: laySet.accountId,
      id: laySet.instanceId,
      name: laySet.name,
      shortName: laySet.shortName,
      layoutId: laySet.layoutId,
      logoDark: laySet.logoDark,
      logoLight: laySet.logoLight
    }
  } else if (
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.layoutId
  ) {
    const laySet = state.layoutSettings.layoutsSettings
    return {
      accountId: laySet.accountId,
      id: laySet.instanceId,
      name: laySet.name,
      shortName: laySet.shortName,
      layoutId: laySet.layoutId,
      logoDark: laySet.logoDark,
      logoLight: laySet.logoLight
    }
  } else {
    return EMPTY_OBJECT
  }
}

const layoutSettingsRecord = state => {
  if (
    state.layoutSettings &&
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.length
  ) {
    const layoutsSettings = state.layoutSettings.layoutsSettings
    const selectedInstance = state.layoutSettings.selectedInstance

    // if (
    //   selectedInstance &&
    //   layoutsSettings.some(ls => ls.accountId === selectedInstance)
    // ) {
    //   return layoutsSettings.find(ls => ls.accountId === selectedInstance)
    // }
    if (
      selectedInstance &&
      layoutsSettings.some(ls => +ls.instanceId === +selectedInstance)
    ) {
      return layoutsSettings.find(ls => +ls.instanceId === +selectedInstance)
    }

    return layoutsSettings[0]
  } else if (
    state.layoutSettings.layoutsSettings &&
    state.layoutSettings.layoutsSettings.layoutId
  ) {
    return state.layoutSettings.layoutsSettings
  } else {
    return EMPTY_OBJECT
  }
}

const availableInstancesSelector = createSelector(
  availableInstancesRecord,
  (record = [EMPTY_OBJECT]) => record
)
const selectedInstanceSelector = createSelector(
  selectedInstanceRecord,
  (record = EMPTY_OBJECT) => record
)
const selectedInstanceLogoSelector = createSelector(
  selectedInstanceRecord,
  (record = { dark: DefaultLogoDark, light: DefaultLogoLight }) => ({
    dark: record.logoDark ? `/img/branding/${record.logoDark}` : record.dark,
    light: record.logoLight ? `/img/branding/${record.logoLight}` : record.light
  })
)

const layoutSettingsSelector = createSelector(
  layoutSettingsRecord,
  (record = EMPTY_OBJECT) => record
)

export { layoutSettingsSelector, selectedInstanceSelector, availableInstancesSelector, selectedInstanceLogoSelector }
