import axios from '../utils/axios-utility';
import httpStatusCodes from '../constants/http-status-codes';
import { CORE_BASE } from '../configs';

const renameInstance = (instanceId, instanceName, accountId) => 
  new Promise((resolve, reject) => {
    axios
      .post(`${CORE_BASE}/instance/rename`, 
      {
        instanceId,
        instanceName,
        accountId,
      })
      .then(({ status, data }) =>
        status === httpStatusCodes.OK ? resolve(data) : reject(data)
      )
      .catch((e) => { 
        reject(e.response.data);
      })
  },
);

export {
  renameInstance,
};
