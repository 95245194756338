import React, { Component } from "react";
import Moment from 'react-moment';
import ThemeTypes from '../constants/theme-types';
import Themes from '../constants/themes';

const clockStyle =  {
  display: 'table-cell',
  verticalAlign: 'middle',
  color: Themes[ThemeTypes.DEFAULT].TEXT,
  fontSize: '1.5rem',
  paddingLeft:'2pt'
}

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      theme:clockStyle
    };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {
    return (
      <div style={{
        display:'flex',    
        alignItems: 'center'
      }}>
        <div style={this.state.theme}>
          <Moment format="HH:mm:ss" date={this.state.date}/>
        </div> 
      </div>
    );
  }
}

export default Clock
