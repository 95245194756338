import React from 'react';
import Switch from './Switch';
import LocalizationContext from '../contexts/LocalizationContext';

const styles = {
  icon: {
    width: 22,
    height: 22,
    marginRight: 4,
    marginLeft: 4,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  leftIcon: {
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-right',
  },
  rightIcon: {
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-left',
  },
};

type IconsSwitchProps = {
  isChecked: boolean,
  onChange: Function,
  leftIcon: Object,
  rightIcon: Object,
  leftIconStyle?: Object,
  rightIconStyle?: Object,
  tooltipKey?: string,
};

const IconsSwitch = (props: IconsSwitchProps) => {
  const {
    isChecked,
    onChange,
    leftIcon,
    rightIcon,
    leftIconStyle,
    rightIconStyle,
    tooltipKey,
    ...restProps
  } = props;
  return (
    <LocalizationContext.Consumer>
        {localization => (
          <div style={styles.container} title={localization[tooltipKey]}>
            <div style={styles.leftIcon}>
              <img src={leftIcon} alt="icon" style={{ ...styles.icon, ...leftIconStyle }}/>
            </div>
            &nbsp;
            <Switch
              isChecked={isChecked}
              onChange={onChange}
              {...restProps}
            />
            &nbsp;
            <div style={styles.rightIcon}>
              <img src={rightIcon} alt="icon" style={{ ...styles.icon, ...rightIconStyle }} />
            </div>
          </div>
        )}
    </LocalizationContext.Consumer>);
};

IconsSwitch.defaultProps = {
  leftIconStyle: {},
  rightIconStyle: {},
};

export default IconsSwitch;
