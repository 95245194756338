import React from 'react';
import ReactSelect from 'react-select';
import ThemeContext from '../contexts/ThemeContext';

const getStyles = (theme) => ({
  //container: (provided, { isSelected }) => ({ ...provided, maxWidth: '130px' }),
  //control: (provided, { isSelected }) => ({ ...provided, maxWidth: '130px' }),
  //menu: (provided, { isSelected }) => ({ ...provided, maxWidth: '130px' }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color: isSelected ? theme.LABEL : theme.NUMBER,
    //maxWidth: '130px'
  }),
});

const getSelectTheme = (theme) => ({
  primary: theme.WIDGET_BACKGROUND, // selected color
  primary25: theme.WIDGET_BACKGROUND, // hover color
  primary50: theme.BACKGROUND, // select click color
  neutral0: theme.BACKGROUND, // background color
  neutral80: theme.LABEL, // selected font color
});

type SelectProps = {
  options: Object,
  defaultValue: Object,
  onChange: Function,
};

const Select = (props: SelectProps) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <ReactSelect
        {...props}
        styles={getStyles(theme)}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            ...getSelectTheme(theme),
          },
        })}
      />
    )}
  </ThemeContext.Consumer>
);

export default Select;
