import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const themeRecord = (state) => state.theme;

const themeTypeSelector = createSelector(
  themeRecord,
  (record = EMPTY_OBJECT) => record.themeType,
);

const themeSelector = createSelector(
  themeRecord,
  (record = EMPTY_OBJECT) => record.theme,
);

export {
  themeTypeSelector,
  themeSelector,
};
