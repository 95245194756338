import ReduxActions from "../../constants/redux-actions";
import StorageKeys from "../../constants/storage-keys";
import { saveConfigs, loadConfigs } from "../../api/configs";
import { getItem, setItem } from "../../utils/storage-utility";

const updateLayoutSettings = layoutSettings => dispatch => {
  if (!layoutSettings || !layoutSettings.layoutId) {
    return;
  }

  dispatch({
    type: ReduxActions.LOAD_LAYOUT,
    layoutId: layoutSettings.layoutId
  });

  layoutSettings.fields.forEach(field => {
    let { id, groupId, widgetType, groupNo } = field;
    let kpi = field.props && field.props.kpi ? field.props.kpi : null;
    dispatch({
      type: ReduxActions.LOAD_WIDGET_GROUP,
      id,
      groupId,
      groupNo
    });
    dispatch({
      type: ReduxActions.LOAD_WIDGET,
      id,
      widgetType,
      kpi,
      groupNo,
      groupId
    });
  });
};
const getActiveLayoutSettings = layoutsSettings => {
  const { selectedInstance } = getItem(StorageKeys.USER_SETTINGS) || {};

  if (!layoutsSettings || !layoutsSettings.length) {
    return {};
  }

  // if (layoutsSettings.some(ls => ls.accountId === selectedInstance)) {
  //   return layoutsSettings.find(ls => ls.accountId === selectedInstance);
  // }
  if (layoutsSettings.some(ls => +ls.instanceId === +selectedInstance)) {
    return layoutsSettings.find(ls => +ls.instanceId === +selectedInstance);
  }
  return layoutsSettings[0];
};

const loadLayoutSettings = (cb) => dispatch => {
  loadConfigs()
    .then(settingsLoaded => {
      dispatch({
        type: ReduxActions.LOAD_LAYOUT_SETTINGS,
        layoutsSettingsLoaded: settingsLoaded.layoutSettings
      });
      dispatch({
        type: ReduxActions.UPDATE_MENU_SETTINGS,
        alertsActive: settingsLoaded.alertsActive //&& (settingsLoaded.isDevEnv || settingsLoaded.isStageEnv)
      });
      dispatch(
        updateLayoutSettings(getActiveLayoutSettings(settingsLoaded.layoutSettings))
      );
      if (cb) {
        cb();
      }
      //dispatch({ type: ReduxActions.UPDATE_LAYOUT, layoutSettings.layout_id })
      //dispatch({ type: ReduxActions.UPDATE_LAYOUT, layoutSettings.layout_id })
    })
    .catch(error => {
      console.error(error)
      if (cb) {
        cb(error);
      }
    });
};

const saveLayoutSettings = (layoutSettings, callback) => dispatch => {
  saveConfigs(layoutSettings)
    .then(() => {
      if (callback) {
        callback();
      }
    })
    .catch(error => {
      console.error(error);
      if (callback) {
        callback(error);
      }
    });
};

const changeInstance = selectedInstance => (dispatch, getState) => {
  if (!selectedInstance) {
    return;
  }

  const oldSettings = getItem(StorageKeys.USER_SETTINGS);
  setItem(StorageKeys.USER_SETTINGS, { ...oldSettings, selectedInstance });

  dispatch({
    type: ReduxActions.UPDATE_INSTANCE,
    selectedInstance
  });

  const layOutSets = getState().layoutSettings.layoutsSettings;
  dispatch(updateLayoutSettings(getActiveLayoutSettings(layOutSets)));
};

export { loadLayoutSettings, saveLayoutSettings, changeInstance };
