import axios from 'axios';
import StorageKeys from '../constants/storage-keys';
import httpStatusCodes from '../constants/http-status-codes';
import { LOGIN_BASE } from '../configs';
import { getItem, setItem, removeItem } from './storage-utility';

/* eslint-disable dot-notation */
/* eslint-disable no-shadow */

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const { accessToken } = getItem(StorageKeys.AUTH_TOKENS) || {};

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (err) => Promise.reject(err),
);

const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { accessToken, refreshToken } = getItem(StorageKeys.AUTH_TOKENS) || {};
      // Reject promise if usual error
      if (error && error.response) {
        if (error.response.status !== httpStatusCodes.UNAUTHORIZED) {
          return Promise.reject(error);
        }
      }
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);
      return axios.post(`${LOGIN_BASE}/Login/renew`, {
        access_token: accessToken,
        refresh_token: refreshToken,
      })
        .then(
          (response) => {
            const {
              data: {
                auth: {
                  access_token: accessToken,
                  refresh_token: refreshToken,
                  refresh_token2: refreshToken2,
                },
              },
            } = response;
            setItem(StorageKeys.AUTH_TOKENS, { accessToken, refreshToken, refreshToken2 });
            // eslint-disable-next-line no-param-reassign
            if (error && error.response && error.response.config) {
              error.response.config.headers['Authorization'] = `Bearer ${accessToken}`;
              return axios(error.response.config);
            }
            else {
              if (response && response.config) {
                  response.config.headers['Authorization'] = `Bearer ${accessToken}`;
                  return axios(response.config);
              }
            }
          },
        )
        .catch(
          (error) => {
            removeItem(StorageKeys.AUTH_TOKENS);
            return Promise.reject(error);
          },
        )
        .finally(createAxiosResponseInterceptor);
    },
  );
};

createAxiosResponseInterceptor();

export const renewAccessToken = () => {
  const { accessToken, refreshToken } = getItem(StorageKeys.AUTH_TOKENS) || {}
  return axios.post(`${LOGIN_BASE}/Login/renew`, {  
    access_token: accessToken,
    refresh_token: refreshToken,
  })
    .then(
      (response) => {
        const {
          data: {
            auth: {
              access_token: accessToken,
              refresh_token: refreshToken,
              refresh_token2: refreshToken2
            },
          },
        } = response;

        setItem(StorageKeys.AUTH_TOKENS, { accessToken, refreshToken, refreshToken2 });
        updateAuthHeader(accessToken)

        return Promise.resolve(accessToken)
      },
    )
    .catch(
      (error) => {
        return Promise.reject(error);
      }
    )
}

export const updateAuthHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
};

export default axios;
