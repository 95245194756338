import getCommon from './common'

export default (stateThemeType, stateTheme) => {
  const { theme, common } = getCommon(stateThemeType, stateTheme)

  const txts = {
    descrpt: 'Wallboard bietet Überblick und Kontrolle über den aktuellen Status von Antwortgruppen. Verschiedene Layouts ermöglichen es den Benutzern, Metriken in unterschiedlichen Dispositionen anzuzeigen. <br />' +
      'Die Seite besteht aus einem Rastersystem, in dem eine oder mehrere von den Benutzern ausgewählte Gruppen angezeigt werden.',
    cellHead: 'Zellen:',
    cellDescrpt: 'In jeder Zelle können unterschiedliche Metriken angezeigt werden. Die ausgewählten Metriken sollten das widerspiegeln, was für jede Gruppe am wertvollsten ist.',
    metricsHead: 'METRIKS',
    metricsSubHead: 'Metrik auswählen:',
    metricsDescrpt: 'Klicken Sie auf die Zelle der Metrik / Zahl und wählen Sie die Metrik in der angezeigten Dropdown-Liste aus.',
    metricsChartSubHead: 'Festlegen eines Diagramms als Metrik:',
    metricsChartDescrpt: 'Spalte, Kreisdiagramm und Liste sind drei verschiedene grafische Optionen zum Anzeigen der Anrufe in Abwesenheit, Beantwortet und Insgesamt für die ausgewählte Gruppe.',
    metricsOptnsHead: 'Metrikoptionen:',
    metricsOptnsQueHead: '&bull; In Warteschlange:',
    metricsOptnsQueDescrpt: 'Wie viele Anrufe warten darauf, beantwortet zu werden.',
    metricsOptnsLgdInHead: '&bull; Angemeldete Agenten:',
    metricsOptnsLgdInDescrpt: 'Wie viele Agenten sind an der Gruppe angemeldet?',
    metricsOptnsAvailHead: '&bull; Verfügbare Agenten:',
    metricsOptnsAvailDescrpt: 'Wie viele Agenten sind verfügbar, um Anrufe entgegenzunehmen.',
    metricsOptnsTotHead: '&bull; Insgesamt:',
    metricsOptnsTotDescrpt: 'Anrufe für die letzten 12 Stunden insgesamt.',
    metricsOptnsAnswHead: '&bull; Beantwortet:',
    metricsOptnsAnswDescrpt: 'Gesamtzahl der beantworteten Anrufe in den letzten 12 Stunden.',
    metricsOptnsMissHead: '&bull; Verpasst:',
    metricsOptnsMissDescrpt: 'Insgesamt verpasste Anrufe in den letzten 12 Stunden.',
    metricsOptnsLtstHead: '&bull; Letzte:',
    metricsOptnsLtstDescrpt: 'Wartezeit für den letzten Anruf bei der Gruppe.',
    metricsOptnsAvQueTimHead: '&bull; Durchschnittliche Wartezeit:',
    metricsOptnsAvQueTimDescrpt: 'Durchschnittliche Wartezeit für die letzten 12 Stunden.',
    metricsOptnsAvCallDurHead: '&bull; Durchschnittliche Anrufdauer:',
    metricsOptnsAvCallDurDescrpt: 'Durchschnittliche Dauer der beantworteten Anrufe in den letzten 12 Stunden.',
    metricsOptnsFwdedHead: '&bull; Weitergeleitet:',
    metricsOptnsFwdedDescrpt: 'Gesamtzahl der Anrufe, die in den letzten 12 Stunden an ein anderes Ziel weitergeleitet wurden.',
    metricsOptnsChartHead: '&bull; Balkendiagramm (Anrufe), <br />&nbsp;&nbsp;&nbsp;Kreisdiagramm (Anrufe), <br />&nbsp;&nbsp;&nbsp;Liste (Anrufe):',
    metricsOptnsChartDescrpt: 'Zeigt die beantworteten, entgangenen und gesamten Anrufe der letzten 12 Stunden an.',
    toolbarHead: 'WERKZEUGLEISTE',
    toolbarDescrpt: 'Oben rechts auf der Seite befinden sich mehrere Schaltflächen, mit denen Sie das Verhalten von Wallboard ändern können.',
    layoutHead: 'Layout:',
    layoutDescrpt: 'Wählen Sie ein Layout für eine Gruppe oder ein Layout für mehrere Gruppen, um Messdaten aus mehreren Gruppen auf derselben Seite anzuzeigen.',
    saveHead: 'Speichern:',
    saveDescrpt: 'Konfiguration speichern. Die Auswahl wird in allen anderen Browsern angezeigt, die dieselbe Wallboard-Konfiguration aufweisen.',
    lightnessHead: 'Dunkel / Hell-Modus:',
    lightnessDescrpt: 'Wechseln Sie zwischen Hell- und Dunkel-Modus. Diese Einstellung ist für jeden Browser, der Wallboard anzeigt, lokal und wird nicht in der Konfiguration gespeichert.',
    lightness2Head: 'Dunkel / Hell-Modus:',
    lightness2Descrpt: 'Wie oben.',
    settingsHeader: 'EINSTELLUNGSMENÜ',
    buttonsOnOffDescrpt: 'Schaltflächen in der Symbolleiste ein- oder ausblenden.',
    languageDroplist: 'DE',
    languageDescrpt: 'Dropdown zur Auswahl der Sprache aus den verfügbaren Sprachen.',
    answerGroupDroplist: 'WB 1',
    answerGroupHead: 'Konfiguration auswählen:',
    answerGroupDescrpt: 'Dieses Dropdown-Menü wird nur angezeigt, wenn mehr als eine Konfiguration von Wallboard verfügbar ist.',
    answerGroupDescrpt2: 'Eine Konfiguration wird zentral gespeichert und besteht aus ausgewählten Layouts, Gruppen und Metriken. Alle Wallboards, für die dieselbe Konfiguration festgelegt ist (wenn nur eine Konfiguration verfügbar ist, ist dies immer dieselbe), zeigen dasselbe Layout, dieselben Gruppen und dieselben Metriken an.',
    helpDescrpt: 'Hilfe (diese Seite)',
    logoutDescrpt: 'Abmelden, aktueller Benutzer ist abgemeldet und Metrik aus Gruppen wird nicht angezeigt.'
  }

  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,

    settingsItems: `<br><div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right +
      common.left + common.buttonsIcon + common.middle100Minus141px + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle100Minus141px + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle100Minus141px + `${txts.logoutDescrpt}` + common.right
  }

  return `<span style="color: ${theme.obj.LABEL}"><div style="float:left;padding: 0 0 10px 0px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right +
    '</div></span>'
}
