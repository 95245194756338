import getCommon from './common'

export default (stateThemeType, stateTheme) => {
  const { theme, common } = getCommon(stateThemeType, stateTheme)

  const txts = {
    descrpt: 'Wallboard gives overview and control of current status of response groups. Various layouts enables the users to display metrics in different dispositions. <br />' +
      'The page consists of a grid system where one or more groups are displayed as selected by the users.',
    cellHead: 'Cells:',
    cellDescrpt: 'Each cell can display different metrics and the metrics chosen should reflect what is most valuable to see for each group.',
    metricsHead: 'METRICS',
    metricsSubHead: 'Select metric:',
    metricsDescrpt: 'Click on the cell of the metric / number and choose metric in the dropdown that appears.',
    metricsChartSubHead: 'Set a chart as metric:',
    metricsChartDescrpt: 'Column, pie chart and list are three different graphical options to view Missed, Answered and Total calls for chosen group.',
    metricsOptnsHead: 'Metrics options:',
    metricsOptnsQueHead: '&bull; Calls in queue:',
    metricsOptnsQueDescrpt: 'How many calls are waiting to be answered.',
    metricsOptnsLgdInHead: '&bull; Logged in agents:',
    metricsOptnsLgdInDescrpt: 'How many agents are logged in to the group.',
    metricsOptnsAvailHead: '&bull; Available agents:',
    metricsOptnsAvailDescrpt: 'How many agents are available to receive calls.',
    metricsOptnsTotHead: '&bull; Total:',
    metricsOptnsTotDescrpt: 'Total calls for the last 12h.',
    metricsOptnsAnswHead: '&bull; Answered:',
    metricsOptnsAnswDescrpt: 'Total answered calls the last 12h.',
    metricsOptnsMissHead: '&bull; Missed:',
    metricsOptnsMissDescrpt: 'Total missed calls the last 12h.',
    metricsOptnsLtstHead: '&bull; Latest:',
    metricsOptnsLtstDescrpt: 'Queue time for the latest call to the group.',
    metricsOptnsAvQueTimHead: '&bull; Average queue duration:',
    metricsOptnsAvQueTimDescrpt: 'Average queue time for the last 12h.',
    metricsOptnsAvCallDurHead: '&bull; Average call duration:',
    metricsOptnsAvCallDurDescrpt: 'Average duration of answered calls for the last 12h.',
    metricsOptnsFwdedHead: '&bull; Forwarded:',
    metricsOptnsFwdedDescrpt: 'Total calls forwarded to another destination in the last 12h.',
    metricsOptnsChartHead: '&bull; Bar Chart (Calls), <br />&nbsp;&nbsp;&nbsp;Pie Chart (Calls), <br />&nbsp;&nbsp;&nbsp;List (Calls):',
    metricsOptnsChartDescrpt: 'Displays answered, missed and total calls for the last 12h.',
    toolbarHead: 'TOOLBAR',
    toolbarDescrpt: 'On the upper right side of the page there are several buttons to change to behavior of Wallboard.',
    layoutHead: 'Layout:',
    layoutDescrpt: 'Choose layout for one group or multi-group layout to display metrics from several groups on the same page.',
    saveHead: 'Save:',
    saveDescrpt: 'Save configuration. Selections will be displayed on all other browsers that shows the same configuration of Wallboard.',
    lightnessHead: 'Dark / light mode:',
    lightnessDescrpt: 'Switch between light mode or dark mode, this setting is local for each browser that displays Wallboard and is not saved in configuration.',
    lightness2Head: 'Dark / light mode:',
    lightness2Descrpt: 'Same as above.',
    settingsHeader: 'SETTINGS MENU',
    buttonsOnOffDescrpt: 'Show or hide buttons in Toolbar.',
    languageDroplist: 'EN',
    languageDescrpt: 'Dropdown for selecting language from available languages.',
    answerGroupDroplist: 'WB 1',
    answerGroupHead: 'Select configuration:',
    answerGroupDescrpt: 'This dropdown is only displayed if there is more than one configuration of Wallboard available.',
    answerGroupDescrpt2: "A configuration is saved centrally and consists of selected layout, group(s) and metrics. All Wallboards that are set to display the same configuration (it's always the same if only one configuration is available), displays the same layout, group(s) and metrics.",
    helpDescrpt: 'Help (this page)',
    logoutDescrpt: 'Log out, current user is logged out and redirected to log in page.'
  }

  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,

    settingsItems: `<br><div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right +
      common.left + common.buttonsIcon + common.middle100Minus141px + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle100Minus141px + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle100Minus141px + `${txts.logoutDescrpt}` + common.right
  }

  return `<span style="color: ${theme.obj.LABEL}"><div style="float:left;padding: 0 0 10px 0px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right +
    '</div></span>'
}
