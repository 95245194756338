// @flow
import React from 'react';
import IconsSwitch from './IconsSwitch';
import { selectThemeIcon } from '../utils';

import AlertActiveLight from '../images/alert-active-light.svg'
import AlertActiveDark from '../images/alert-active-dark.svg'

import AlertInactiveLight from '../images/alert-inactive-light.svg'
import AlertInactiveDark from '../images/alert-inactive-dark.svg'

type AlertSwitchProps = {
  isVisble: boolean,
  onModeChange: Function,
  themeType: String,
};

const styles = {
  icon: {
    width: 22,
    height: 22,
    marginRight: 4,
    marginLeft: 4,
  },
};

const AlertSwitch = (props: AlertSwitchProps) => {
  const {
    isVisble,
    onModeChange,
    themeType,
    ...restProps
  } = props;
  const AlertActiveIcon = selectThemeIcon(themeType, AlertActiveLight, AlertActiveDark);
  const AlertInactiveIcon = selectThemeIcon(themeType, AlertInactiveLight, AlertInactiveDark);
  return (
    <IconsSwitch
      tooltipKey='SETTINGS_VISUAL_NOTIFICATIONS_TOOLTIP'
      isChecked={isVisble}
      onChange={onModeChange}
      leftIcon={AlertInactiveIcon}
      rightIcon={AlertActiveIcon}
      leftIconStyle={styles.icon}
      rightIconStyle={styles.icon}
      {...restProps}
    />
  );
};

export default AlertSwitch;
