import ReduxActions from '../../constants/redux-actions';

const defaultState = {
  renamed: false,
  renaming: false,
  renameError: undefined,
};

const instances = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.INSTANCE_RENAME_STARTED:
      return Object.assign({}, state, {
        renaming: true,
      });
    case ReduxActions.INSTANCE_RENAME_FINISHED: {
      return Object.assign({}, state, {
        renamed: true,
        renaming: false,
        renameError: undefined,
      });
    }
    case ReduxActions.INSTANCE_RENAME_ERROR:
      return Object.assign({}, state, {
        renamed: false,
        renaming: false,
        renameError: action.error,
      });
    case ReduxActions.INSTANCE_RENAME_RESET:
      return Object.assign({}, state, {...defaultState});
    default:
      return state;
  }
};

export default instances;
