// @flow
import React from 'react';
import IconsSwitch from './IconsSwitch';
import { selectThemeIcon } from '../utils';

import ClockActiveLight from '../images/clock-active-light.svg'
import ClockActiveDark from '../images/clock-active-dark.svg'

import ClockInactiveLight from '../images/clock-inactive-light.svg'
import ClockInactiveDark from '../images/clock-inactive-dark.svg'

type ClockSwitchProps = {
  isVisble: boolean,
  onModeChange: Function,
  themeType: String,
};

const styles = {
  icon: {
    width: 22,
    height: 22,
    marginRight: 4,
    marginLeft: 4,
  },
};

const ClockSwitch = (props: ClockSwitchProps) => {
  const {
    isVisble,
    onModeChange,
    themeType,
    ...restProps
  } = props;
  const ClockActiveIcon = selectThemeIcon(themeType, ClockActiveLight, ClockActiveDark);
  const ClockInactiveIcon = selectThemeIcon(themeType, ClockInactiveLight, ClockInactiveDark);
  return (
    <IconsSwitch
      tooltipKey='SETTINGS_CLOCK_TOOLTIP'
      isChecked={isVisble}
      onChange={onModeChange}
      leftIcon={ClockInactiveIcon}
      rightIcon={ClockActiveIcon}
      leftIconStyle={styles.icon}
      rightIconStyle={styles.icon}
      {...restProps}
    />
  );
};

export default ClockSwitch;
