// @flow
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from '../constants';
import ThemeTypes from '../constants/theme-types';

/* eslint camelcase: "off" */

const parseLangTag = (str: string) => str.toLowerCase().split('-')[0];

const getCurrentLanguage = () => {
  const { language, userLanguage } = window.navigator;
  const browserLanguage = language || userLanguage;
  if (browserLanguage) {
    const langTag = parseLangTag(browserLanguage);
    if (AVAILABLE_LANGUAGES.includes(langTag)) {
      return langTag;
    }
  }

  return DEFAULT_LANGUAGE;
};

const isDarkTheme = (themeType) => themeType === ThemeTypes.DARK;

const selectThemeIcon = (themeType, lightIcon, darkIcon) => isDarkTheme(themeType)? darkIcon: lightIcon;


const tryParse = (strValue: string) => {
  try {
    return JSON.parse(strValue);
  } catch (err) {
    return undefined;
  }
};


const delay = (time = 500) => new Promise(
  (resolve) => {
    setTimeout(resolve, time);
  }
);

export {
  parseLangTag,
  getCurrentLanguage,
  isDarkTheme,
  selectThemeIcon,
  tryParse,
  delay,
};
