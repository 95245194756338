import React from 'react';
import ReactSelect from 'react-select';
import ThemeContext from '../contexts/ThemeContext';
import LocalizationContext from "../contexts/LocalizationContext";

const styles = {
  selectContainer: {
    width: '100%',
  },
};

const getStyles = (theme, selectStyle, optionFontSize) => ({
  option: (provided, state) => {
    const { isSelected } = state;
    return {
      ...provided,
      color: isSelected ? theme.LABEL : theme.NUMBER,
      fontSize: optionFontSize,
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderStyle: 'none',
    boxShadow: '',
  }),
  valueContainer: (provided) => ({
    ...provided,
    justifyContent: 'center',
    overflow: 'visible',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    ...selectStyle,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
});

const getSelectTheme = (theme) => ({
  primary: theme.WIDGET_BACKGROUND, // selected color
  primary25: theme.WIDGET_BACKGROUND, // hover color
  primary50: theme.BACKGROUND, // select click color
  neutral0: theme.BACKGROUND, // background color
  neutral80: theme.LABEL, // selected font color
});

type SelectProps = {
  options: Object,
  defaultValue: Object,
  selectStyle: Object,
  containerStyle: Object,
  onChange: Function,
  fontFactor: Number
};

const Select = (props: SelectProps) => {
  const {
    options, 
    defaultValue, 
    containerStyle, 
    selectStyle, 
    onChange, 
    fontFactor,
    ...restProps
  } = props;
  const labelFontSize = (() => {
    return 12 * fontFactor + "px";
  })();
  const optionFontSize = (() => {
    return (12 * 0.8 * fontFactor) + "px";
  })();

  return (
    <LocalizationContext.Consumer>
      {localization => (
        <ThemeContext.Consumer>
          {(theme) => (
            <div title={ localization.TIP_CHANGE_GRP } style={ 
              { 
                ...styles.selectContainer, 
                ...containerStyle,
                fontSize: labelFontSize
              }}>
              <ReactSelect
                {...restProps}
                options={options}
                defaultValue={defaultValue}
                onChange={onChange}
                styles={getStyles(theme, selectStyle, optionFontSize)}
                menuPlacement="top"
                isSearchable={false}
                theme={(selectTheme) => ({
                  ...selectTheme,
                  colors: {
                    ...selectTheme.colors,
                    ...getSelectTheme(theme),
                  },
                })}
              />
            </div>
          )}
        </ThemeContext.Consumer>
      )}
    </LocalizationContext.Consumer>
  );
};

export default Select;
