import ReduxActions from '../../constants/redux-actions';

const updateLayout = (layout) => (dispatch) => {
  dispatch({
    type: ReduxActions.UPDATE_LAYOUT,
    layout,
  });
};

export {
  updateLayout,
};
