import React from "react";

const backgroundColors = {
  1: '#04A8DE',
  2: '#438ec8',
  3: '#496eb4',
  4: '#4959a7',
  5: '#5e4fa2',
  6: '#824ea1',
  7: '#aa53a1',
  8: '#cd4da0',
  9: '#ea4c87',
  10:'#ef5756',
  11:'#f37856',
  12:'#f79558',
  13:'#fb9e58',
  14:'#fdb456',
  15:'#fdc854',
  16:'#fcdd51',
  17:'#fbee52',
  18:'#f6ee5b',
  19:'#d3df55',
  20:'#add04e',
  21:'#81c451',
  22:'#5abb47',
  23:'#6cc49a',
  24:'#42bed8',
  25:'#1e91ca',
}
const fontColors = {
  1: '#444444',
  2: '#444444',
  3: '#444444',
  4: '#5fa8ba',
  5: '#86c2d1',
  6: '#86c2d1',
  7: '#86c2d1',
  8: '#86c2d1',
  9: '#86c2d1',
  10: '#86c2d1',
  11: '#795839',
  12: '#795839',
  13: '#795839',
  14: '#795839',
  15: '#795839',
  16: '#795839',
  17: '#795839',
  18: '#795839',
  19: '#795839',
  20: '#795839',
  21: '#795839',
  22: '#795839',
  23: '#795839',
  24: '#444444',
  25: '#444444',
}

const styles = {
  cell: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color:'black',
    margin: '0.15rem',
    verticalAlign: 'middle',
    display: 'flex',
    flexGrow: 1,     /* do not grow   - initial value: 0 */
    flexShrink: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  platform: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 auto',
    backgroundColor:'inherit',
    margin:'1px 10px',
    minHeight: '87px',
    width: '150px',
  }
}

type CellWithNumberProps = {
  layout: Object,
  withNumbers: boolean,
  onClick: Function,
  style: Object
};

const renderLayout = (
  {
    layout,
    withNumbers,
    },
  level = 0,
  itemIndex = 0,

) => {
  const {
    row, col, items, item,
  } = layout;
  const direction = row ? 'row' : 'column';
  const style = {
    display: 'flex',
    flex: row || col,
    flexDirection: direction,
  };

  return (
    <div style={style} key={`${direction}-${level}-${itemIndex}`}>
      {items && items
        .map(
          (layoutItem, index) => renderLayout(
            {
              layout: layoutItem,
              withNumbers,
            },
            level + 1,
            index,
          ),
        )
      }
      {item && (
        <div style={{...styles.cell, background: backgroundColors[item.group]}}>
          <span style={{visibility: withNumbers ? 'visible ' : 'hidden', color: fontColors[item.group]}}>{item.group}</span>
        </div>
      )}
    </div>
  );
};

const CellWithNumber = (props: CellWithNumberProps) => {
  const {
    layout,
    withNumbers,
    onClick,
    style
  } = props;
  return (
    <div style={{...styles.platform, ...style}} onClick={onClick}>
      {renderLayout({layout, withNumbers})}
    </div>
  )
}

export default CellWithNumber;