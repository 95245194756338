import ReduxActions from "../../constants/redux-actions";
import Kpis from "../../constants/kpis";
const types = {
  0: undefined,//none
  1: Kpis.IN_QUEUE,//calls_in_que
  2: Kpis.AVAILABLE_AGENTS,//available -> 'Free agent' LABEL
  3: Kpis.AVAILABLE_AGENTS,//available_not_call - > TBD: Workaround, should be rerfactored
  4: Kpis.MISSED,//missed
  5: Kpis.FORWARDED,//forwarded
  6: Kpis.AVERAGE_WAIT,//average_wait
  7: Kpis.AVERAGE_DURATION,//call_duration
  8: Kpis.LOGGED_IN_AGENTS,//logged_in -> 'Available agent' LABEL
  9: Kpis.LATEST,//last_wait
  10: undefined,//last_miss,
  11: undefined,//last_forw
}

const updateWidget = (id, groupNo, groupId, widgetType, kpi) => dispatch => {
  dispatch({
    type: ReduxActions.UPDATE_WIDGET,
    id,
    groupNo,
    groupId,
    widgetType,
    kpi
  });
};
const updateWidgetsData = (layoutSettings, quesStats) => dispatch => {
  if (!quesStats || quesStats.length === 0) {
    return;
  }
  const secondsToTime = value => {
    //return value;
    if (!value) {
      return "0";
    }
    value = Number(value);
    if (+value === 0) {
      return "0";
    }

    let result = "";
    let hours = 0;
    if (value / 3600 > 0.9999) {
      hours = Math.trunc(value / 3600);
      value = value - 3600 * hours;
      if (hours < 10) {
        hours = "0" + hours;
      }
      result = result + hours + ":";
    }
    let minutes = 0;
    if (value / 60 > 0.9999) {
      minutes = Math.trunc(value / 60);
      value = value - 60 * minutes;
      if (minutes < 10 && hours) {
        minutes = "0" + minutes;
      }
      result = result + minutes + ":";
    }
    let seconds = value;
    if (seconds < 10 && minutes) {
      seconds = "0" + seconds;
    }
    result = result + seconds;

    return result;
  };

  quesStats.forEach(queStats => {
    //TODO:Remove
    // queStats.agents = [
    //   { id: '1', firstName: 'Yuriy', lastName:'Mishchenko', activity:'In Call', isAvailable: false, inbound: 2, outbound: 4 },
    //   { id: '2', firstName: 'Den', lastName:'Deeeee', activity:'Busy', isAvailable: false, inbound: 12, outbound: 34 },
    //   { id: '3', firstName: 'John', lastName:'Feee', activity:'In Call', isAvailable: false, inbound: 2, outbound: 4 },
    //   { id: '4', firstName: 'Mark', lastName:'Fooo', activity:'In Call', isAvailable: false, inbound: 12, outbound: 3 },
    //   { id: '5', firstName: 'Peter', lastName:'Kukuuuu', activity:'Available', isAvailable: false, inbound: 2, outbound: 4 },
    //   { id: '6', firstName: 'Ralf', lastName:'Gaaaav', activity:'In Call', isAvailable: false, inbound: 22, outbound: 4 },
    //   { id: '7', firstName: 'Andrey', lastName:'Myau', activity:'In Call', isAvailable: false, inbound: 32, outbound: 5 },
    //   { id: '8', firstName: 'Andrus', lastName:'Wooow', activity:'Dead', isAvailable: false, inbound: 2, outbound: 4 },
    //   { id: '9', firstName: 'Simon', lastName:'Justsimon', activity:'In Call', isAvailable: false, inbound: 4, outbound: 4 },
    //   { id: '10', firstName: 'Mattias', lastName:'Justmattias', activity:'In Call', isAvailable: false, inbound: 2, outbound: 4 },
    //   { id: '11', firstName: 'Nikita', lastName:'Rostovskiy', activity:'Fired', isAvailable: false, inbound: 2, outbound: 8 },
    //   { id: '12', firstName: 'FJfgdf', lastName:'Kim', activity:'In Call', isAvailable: false, inbound: 6, outbound: 4 },
    //   { id: '13', firstName: 'Lsdgue', lastName:'Roy', activity:'In Call', isAvailable: false, inbound: 2, outbound: 14 },
    // ];
    //
    
    const data = {};
    data.groupId = queStats.id;
    data.nightMode = queStats.nightMode;
    data[Kpis.IN_QUEUE] = queStats.status.callsWaiting;
    data[Kpis.LOGGED_IN_AGENTS] = queStats.status.loggedInAgents;
    data[Kpis.AVAILABLE_AGENTS] = queStats.status.freeAgents;
    data[Kpis.TOTAL] = queStats.stats.totalCalls;
    data[Kpis.ANSWERED] = queStats.stats.answeredCalls;
    data[Kpis.MISSED] = queStats.stats.missedCalls;
    data[Kpis.LATEST] = secondsToTime(queStats.stats.latestWaitTime);
    data[Kpis.AVERAGE_WAIT] = secondsToTime(queStats.stats.averageWaitTime);
    data[Kpis.AVERAGE_DURATION] = secondsToTime(queStats.stats.averageDuration);
    data[Kpis.FORWARDED] = queStats.stats.forwardedCalls;
    data[Kpis.AGENT_LIST] = queStats.agents;
    // const data = {
    //   groupId: queStats.id,
    //   nightMode: queStats.nightMode,
    //   inQue: queStats.status.callsWaiting,
    //   loggedIn: queStats.status.loggedInAgents,
    //   availableAgents: queStats.status.freeAgents,
    //   total: queStats.stats.total,
    //   answered: queStats.stats.answeredCalls,
    //   missed: queStats.stats.missedCalls,
    //   latestWait: queStats.stats.latestWaitTime,
    //   avWait: queStats.stats.averageWaitTime,
    //   avDuration: queStats.stats.averageDuration
    // };
    dispatch({
      type: ReduxActions.UPDATE_WIDGET_DATA,
      layoutSettings,
      data
    });
  });
};

const updateWidgetsAlertData = (alertStats) => dispatch => {
  if (!alertStats || alertStats.length === 0) {
    dispatch({
      type: ReduxActions.UPDATE_WIDGET_ALERT_DATA,
      data: null
    });
  }
  alertStats.forEach(alertStat => {
    const data = {};
    data.groupId = alertStat.groupId;
    if (alertStat.alerts) {
      alertStat.alerts.forEach(alert => {
        if(types[alert.metric]) { 
          data[types[alert.metric]] = alert.triggered;
        }
      });
    }
   
    dispatch({
      type: ReduxActions.UPDATE_WIDGET_ALERT_DATA,
      data
    });
  });
};

const updateWidgetAttendingGroup = (
  groupId,
  groupNo,
  widgetId,
  kpi,
  widgetType,
) => dispatch => {
  dispatch({
    type: ReduxActions.UPDATE_WIDGET_GROUP,
    groupId,
    groupNo,
    widgetId,
    kpi,
    widgetType
  });
};

export {
  updateWidget,
  //updateWidgetData,
  updateWidgetsData,
  updateWidgetAttendingGroup,
  updateWidgetsAlertData
};
