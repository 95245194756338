import { createSelector } from "reselect";

const EMPTY_OBJECT = {};

const widgetsRecord = state => state.widgets;

const widgetsSelector = createSelector(
  widgetsRecord,
  (record = EMPTY_OBJECT) => record.widgets
);

export { widgetsSelector };
