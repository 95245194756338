// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { isDarkTheme } from '../utils';
import ThemeContext from '../contexts/ThemeContext';
import ScreenNames from "../constants/screen-names";
//import DefaultLogoLight from '../images/logo-light.svg';
//import DefaultLogoDark from '../images/logo-dark.svg';

import { currentScreenNameSelector } from "../store/selectors/navigation";
import {
  selectedInstanceSelector, selectedInstanceLogoSelector,
} from "../store/selectors/layoutSettings";
import {
  themeTypeSelector,
} from "../store/selectors/theme";

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 60,
    width: '100%'
  },
  logoContainer: {
    marginRight: 20,
  },
  logo: {
    height: 40,
    fontSize: 20,
  },
};

type FooterProps = {
  themeType: String,
  selectedInstance: Object,
  currentScreen: String,
  brandLogo: Object
};

class Footer extends Component<FooterProps> {
  componentDidMount() {
    //const self = this;
    /* setTimeout(() => {
      const { themeType, selectedInstance, brandLogo } = self.props;
      const { logoDark, logoLight } = brandLogo;
      let tmpLogo = ''
      if (isDarkTheme(themeType)) {
        tmpLogo = logoDark ? logoDark : DefaultLogoDark;
      }
      else {
        tmpLogo = logoLight ? logoLight : DefaultLogoLight;
      }
      //if (logo.includes('logo-light.svg')) {
      if (/logo-light(\..*)?\.svg/i.test(tmpLogo)) {
        tmpLogo = DefaultLogoLight;
      }
      //else if (logo.includes('logo-dark.svg')) {
      else if (/logo-dark(\..*)?\.svg/i.test(tmpLogo)) {
        tmpLogo = DefaultLogoDark;
      }
      else if (!tmpLogo.includes('/img/branding/')) {
        tmpLogo = `/img/branding/${tmpLogo}`;
      }
      //self.setState({ brandLogo: tmpLogo }); 
    }, 1200);
 */  }

  render() {
    const { currentScreen, themeType, brandLogo } = this.props;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div
            style={{
              ...styles.container,
              backgroundColor: theme.HEADER_BACKGROUND,
            }}
          >
            <span style={{ ...styles.logoContainer, color: theme.LOGO }}>
              <b>
                {themeType && currentScreen !== ScreenNames.LOGIN && 
                  <img alt=""
                  src={(isDarkTheme(themeType)? brandLogo.dark: brandLogo.light)}
                    // alt={APP_NAME}
                    style={styles.logo}
                  />
                }
              </b>
            </span>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
  selectedInstance: selectedInstanceSelector(state),
  currentScreen: currentScreenNameSelector(state),
  brandLogo: selectedInstanceLogoSelector(state),
  themeType: themeTypeSelector(state)
});

export default connect(
  mapStateToProps,
)(Footer);
//export default Footer;
