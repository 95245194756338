import ReduxActions from '../../constants/redux-actions';

const defaultState = {
  isFirstLogin: true,
  isHeaderToolsModeActive: true,
  isClockVisible: true,
  isAlertVisible: true,
  selectedSettingsTab: '',
  selectedInstance: '',
  alertsActive: false,
};

const help = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.UPDATE_SETTINGS: {
      const { isFirstLogin, isHeaderToolsModeActive, selectedInstance, isClockVisible, isAlertVisible, selectedSettingsTab } = action;
      return { 
        ...state, 
        isFirstLogin: isFirstLogin !== undefined ? isFirstLogin : state.isFirstLogin,
        isHeaderToolsModeActive:
          isHeaderToolsModeActive !== undefined
            ? isHeaderToolsModeActive
            : state.isHeaderToolsModeActive,
        isClockVisible:
          isClockVisible !== undefined
            ? isClockVisible
            : state.isClockVisible,    
        isAlertVisible:
          isAlertVisible !== undefined
            ? isAlertVisible
            : state.isAlertVisible,    
        selectedSettingsTab: selectedSettingsTab !== undefined && selectedSettingsTab !== null ? selectedSettingsTab : state.selectedSettingsTab,
        selectedInstance: selectedInstance? selectedInstance: state.selectedInstance,
      };
    }
    case ReduxActions.UPDATE_MENU_SETTINGS: {
      return {
        ...state,
        alertsActive: action.alertsActive? true: false,
      };
    }
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState, isFirstLogin: false };
    default:
      return state;
  }
};

export default help;
