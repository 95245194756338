import getCommon from './common'

export default (stateThemeType, stateTheme) => {
  const { theme, common } = getCommon(stateThemeType, stateTheme)

  const txts = {
    descrpt: 'Wallboard ger en översikt och kontroll av statusen i svarsgrupper. Olika layouts möjliggör visning av nyckelvärden i olika dispositioner. <br />' +
      'Sidan består av ett rutnät som visar en eller flera grupper efter användarens val.',
    cellHead: 'Celler:',
    cellDescrpt: 'Varje cell kan visa olika mätetal och valda värden borde återspegla det som är viktigast för varje grupp.',
    metricsHead: 'MÄTETAL',
    metricsSubHead: 'Välj mätetal:',
    metricsDescrpt: 'Klicka i cellen för mätetalet / numret och välj mätetal i undermenyn som visas.',
    metricsChartSubHead: 'Välj ett diagram för mätetal:',
    metricsChartDescrpt: 'Stapel-, cirkel-diagram och lista är tre olika grafiska alternativ som visar missade, besvarade och totalt antal samtal för valda grupper.',
    metricsOptnsHead: 'Val av mätetal:',
    metricsOptnsQueHead: '&bull; Samtal i kö:',
    metricsOptnsQueDescrpt: 'Hur många samtal som för närvarande väntar.',
    metricsOptnsLgdInHead: '&bull; Tillgängliga agenter:',
    metricsOptnsLgdInDescrpt: 'Hur många agenter som för närvarande är tillgängliga i gruppen.',
    metricsOptnsAvailHead: '&bull; Lediga agenter:',
    metricsOptnsAvailDescrpt: 'Hur många agenter som för närvarande är lediga att ta emot samtal.',
    metricsOptnsTotHead: '&bull; Samtal totalt:',
    metricsOptnsTotDescrpt: 'Totalt antal samtal de senaste 12h.',
    metricsOptnsAnswHead: '&bull; Besvarade samtal:',
    metricsOptnsAnswDescrpt: 'Totalt antal besvarade samtal de senaste 12h.',
    metricsOptnsMissHead: '&bull; Missade samtal:',
    metricsOptnsMissDescrpt: 'Totalt antal missade samtal de senaste 12h.',
    metricsOptnsLtstHead: '&bull; Kötid senaste samtalet:',
    metricsOptnsLtstDescrpt: 'Kötid för senaste samtalet till gruppen.',
    metricsOptnsAvQueTimHead: '&bull; Genomsnittlig kötid:',
    metricsOptnsAvQueTimDescrpt: 'Genomsnittlig kötid de senaste 12h.',
    metricsOptnsAvCallDurHead: '&bull; Genomsnittlig samtalstid:',
    metricsOptnsAvCallDurDescrpt: 'Genomsnittlig samtalstid för besvarade samtal de senaste 12h.',
    metricsOptnsFwdedHead: '&bull; Vidarebefordrade samtal:',
    metricsOptnsFwdedDescrpt: 'Totalt antal samtal vidarebefordrade till annan destination de senaste 12h.',
    metricsOptnsChartHead: '&bull; Stapeldiagram (samtal), <br />&nbsp;&nbsp;&nbsp;Cirkeldiagram (samtal), <br />&nbsp;&nbsp;&nbsp;Lista (samtal):',
    metricsOptnsChartDescrpt: 'Visar missade, besvarade och totalt antal samtal de senaste 12h.',
    toolbarHead: 'VERKTYGSKNAPPAR',
    toolbarDescrpt: 'Överst till höger på sidan finns flera knappar tillför att ändra utseendet på Wallboard.',
    layoutHead: 'Layout:',
    layoutDescrpt: 'Välj layout för enbart en grupp eller layout som stöder flera grupper för att visa mätvärden för flera grupper på samma sida.',
    saveHead: 'Spara:',
    saveDescrpt: 'Spara konfiguration. Valen kommer att visas i alla andra webbläsare som visar samma konfiguration av Wallboard.',
    lightnessHead: 'Mörkt / ljust:',
    lightnessDescrpt: 'Växla mellan att visa sidan i ljusa eller mörka färger, denna inställning är lokal för varje webbläsare som visar Wallboard och sparas inte i någon gemensam konfiguration.',
    lightness2Head: 'Mörkt / ljust:',
    lightness2Descrpt: 'Samma som ovan.',
    settingsHeader: 'INSTÄLLNINGAR',
    buttonsOnOffDescrpt: 'Visa eller dölj knapparna i sidhuvudet.',
    languageDroplist: 'EN',
    languageDescrpt: 'Dropplista, välj språk ur listan av tillgängliga översättningar.',
    answerGroupDroplist: 'WB 1',
    answerGroupHead: 'Välj konfiguration:',
    answerGroupDescrpt: 'Denna dropplista visas bara om det finns mer än en konfiguration av Wallboard tillgängligt.',
    answerGroupDescrpt2: 'En konfiguration sparas centralt och består av vald layout, grupp(er) och mätetal. Alla Wallboards som är inställda att visa samma konfiguration (det är alltid samma om enbart en konfiguration finns tillgänglig), visar samma layout, grupp(er) och mätvärden.',
    helpDescrpt: 'Hjälp (denna sida)',
    logoutDescrpt: 'Logga ut, användaren loggas ut och sidan för att logga in visas.'
  }

  const contents = {
    descrpt: `<p> <br />${txts.descrpt}</p>`,

    cellDescrpt: `<span style="font-weight:bold">${txts.cellHead}</span> ${txts.cellDescrpt}</span>`,

    metricsDescrpt1: `<br /><br /><br /><span style="font-weight:bold">${txts.metricsHead}</span><br />` +
      `<span><b>${txts.metricsSubHead}</b> ${txts.metricsDescrpt}</span>`,
    metricsDescrpt2: `<br><br><b>${txts.metricsChartSubHead}</b> ${txts.metricsChartDescrpt} <br />`,

    metricOptnsDescrpt: `<br><span style="font-weight:bold">${txts.metricsOptnsHead}</span><table>` +
      `<tr><td style="font-weight: bold; width: 190px; vertical-align: top">${txts.metricsOptnsQueHead}</td> <td>${txts.metricsOptnsQueDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLgdInHead}</td> <td>${txts.metricsOptnsLgdInDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvailHead}</td> <td>${txts.metricsOptnsAvailDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsTotHead}</td> <td>${txts.metricsOptnsTotDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAnswHead}</td> <td>${txts.metricsOptnsAnswDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsMissHead}</td> <td>${txts.metricsOptnsMissDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsLtstHead}</td> <td>${txts.metricsOptnsLtstDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvQueTimHead}</td> <td>${txts.metricsOptnsAvQueTimDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsAvCallDurHead}</td> <td>${txts.metricsOptnsAvCallDurDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsFwdedHead}</td> <td>${txts.metricsOptnsFwdedDescrpt}</td></tr>` +
      `<tr><td style="font-weight: bold; vertical-align: top">${txts.metricsOptnsChartHead}</td> <td style="vertical-align: top">${txts.metricsOptnsChartDescrpt}</td></tr>` +
      '</table>',

    toolBarItems: `<span style="font-weight:bold">${txts.toolbarHead}</span> <br />${txts.toolbarDescrpt}<br /><br />` +
      common.left + common.layoutIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.layoutHead}</span> ${txts.layoutDescrpt}` + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
      common.left + common.saveIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.saveHead}</span> ${txts.saveDescrpt}` + common.right +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightnessHead}</span> ${txts.lightnessDescrpt}` + common.right,

    settingsItems: `<br><div style="font-weight:bold; margin-top: 20px">${txts.settingsHeader} &nbsp;` + common.settingsIcon + `</div>` +
      common.left + common.lightnessIcon + common.middle100Minus141px + `<span style="font-weight:bold">${txts.lightness2Head}</span> ${txts.lightness2Descrpt}` + common.right +
      common.left + common.buttonsIcon + common.middle100Minus141px + `${txts.buttonsOnOffDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.languageDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `${txts.languageDescrpt}` + common.right +
      common.left + common.droplistStart + `${txts.answerGroupDroplist}` + common.droplistEnd + common.middle100Minus141px2 + `<strong>${txts.answerGroupHead}</strong> ${txts.answerGroupDescrpt}<br />${txts.answerGroupDescrpt2}` + common.right +
      common.left + common.helpIcon + common.middle100Minus141px + `${txts.helpDescrpt}` + common.right +
      common.left + common.logoutIcon + common.middle100Minus141px + `${txts.logoutDescrpt}` + common.right
  }

  return `<span style="color: ${theme.obj.LABEL}"><div style="float:left;padding: 0 0 10px 0px;font-size:12px;"${contents.descrpt}` +
    contents.cellDescrpt + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 +
    contents.metricsDescrpt1 + common.playButtonIcon_editCellContent + common.image_editCellContent + contents.metricsDescrpt2 +
    contents.metricOptnsDescrpt +
    '</div>' +
    '<div style="float:left;font-size:12px">' +
    common.leftDiv100 + contents.toolBarItems + common.right +
    common.leftDiv100 + contents.settingsItems + common.right +
    '</div></span>'
}

// const contents = {
//   description: '<p>Wallboard Core är en single page application för övervakning av kö-statistik i realtid av utvalda svarsgrupper som möjliggör för användare att få information om nuvarande status med endast en snabb blick.<br />' +
//     'Designen av sidan är minimalistisk, ren och med ett snyggt utseende anpassat till allt ifrån projektorer ned till mindre skärmar så som pekplattor. Vyerna är utformade att fokusera på nyckeltal.</p>' +
//     '<p style="margin-top: -10px">Om vissa kö-siffror är särskilt kritiska är det också möjligt att lägga till vår extratjänst för varningsmeddelanden som kan arbeta i förening med WB-Core. T ex: Om antalet uppringande i kö inte bör överstiga 5, kan man om det inträffar konfigurera systemet att skicka ut varningar till berörda avdelningar per SMS eller e-post.<br />' +
//     'Om du vill veta mer, välkommen att kontakta oss på support@soluno.se eller telefon 010 - 410 50 00.</p>',

//   settings: 'Öppna / stäng inställningar',
//   editCellContent: 'Ändra kö-siffra att visa',
//   layout: 'Välj layout. Layouts i vitt har stöd för att visa statistik till endast en svarsgrupp. Layouts med både rutor i vitt och olika nyanser av grå stödjer multipla grupper.',
//   save: 'Sparar vald layout och valda svarsgrupper och siffror för alla användare',
//   lightness: 'Ljus / mörk skärm',
//   buttons: 'Visa / göm verktygsknappar i sidhuvudet',
//   languageDroplist: 'SV',
//   language: 'Välj språk',
//   answerGroupDroplist: 'WB 1',
//   answerGroup: 'Välj svarsgrupp',
//   help: 'Visa / göm hjälpsida',
//   logout: 'Logga ut'
// }

// export default (stateThemeType, stateTheme) => {
//   const { theme, common } = getCommon(stateThemeType, stateTheme)

//   return `<span style="color: ${theme.obj.LABEL}">` + `<br />${contents.description}` + '</span>' +
//     `<div style="color: ${theme.obj.LABEL}; position: relative; width: 48%; float: left">` +
//     common.left + common.settingsIcon + common.middle + contents.settings + common.right +
//     common.left + common.layoutIcon + common.middle + contents.layout + common.playButtonIcon_changeLayout + common.image_changeLayout + common.right +
//     common.left + common.editContentIcon + common.middle + contents.editCellContent + common.playButtonIcon_editCellContent + common.image_editCellContent + common.right +
//     common.left + common.editContentIcon2 + common.middle + contents.editCellContent2 + common.playButtonIcon_editCellContent2 + common.image_editCellContent2 + common.right +
//     common.left + common.saveIcon + common.middle + contents.save + common.right +
//     `</div><div style="color: ${theme.obj.LABEL}; position: relative; width: 48%; float: left">` +
//     common.left + common.lightnessIcon + common.middle + contents.lightness + common.right +
//     common.left + common.buttonsIcon + common.middle + contents.buttons + common.right +
//     common.left + common.droplistStart + contents.languageDroplist + common.droplistEnd + common.middle + contents.language + common.right +
//     common.left + common.droplistStart + contents.answerGroupDroplist + common.droplistEnd + common.middle + contents.answerGroup + common.right +
//     common.left + common.helpIcon + common.middle + contents.help + common.right +
//     common.left + common.logoutIcon + common.middle + contents.logout + common.right +
//     common.iconsCopyright + '</div>'
// }
