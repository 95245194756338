import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const localizationRecord = (state) => state.localization;

const currentLanguageSelector = createSelector(
  localizationRecord,
  (record = EMPTY_OBJECT) => record.current,
);

const availableLanguagesSelector = createSelector(
  localizationRecord,
  (record = EMPTY_OBJECT) => record.languages,
);

const localizationSelector = createSelector(
  localizationRecord,
  (record = EMPTY_OBJECT) => record.localization,
);


export {
  currentLanguageSelector,
  availableLanguagesSelector,
  localizationSelector,
};
