import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { localizationSelector } from "../store/selectors/localization";
import { attendingGroupsSelector } from "../store/selectors/groups";
import { themeTypeSelector, themeSelector } from "../store/selectors/theme";
import { layoutSettingsSelector } from "../store/selectors/layoutSettings";
import Header from "./Header";
import Footer from "../components/Footer";
import Dashboard from "./Dashboard";
import AdminAlerts from "./settings-elements/AdminAlerts";
import Login from "./Login";
import Help from "./Help";
import LocalizationContext from "../contexts/LocalizationContext";
import ThemeContext from "../contexts/ThemeContext";
import { currentScreenNameSelector } from "../store/selectors/navigation";
import * as settingsActions from "../store/actions/settings";
import * as authActions from "../store/actions/auth";
import { updateWidgetsData, updateWidgetsAlertData } from "../store/actions/widgets";
import * as layoutSettingsActions from "../store/actions/layoutSettings";
import StorageKeys from "../constants/storage-keys";
import { CORE_BASE } from "../configs";
import { getItem } from "../utils/storage-utility";
import createWsClient from "../api/ws-client";
import { Spinner } from 'react-bootstrap';
import { renamingSelector } from "../store/selectors/instances";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ScreenNames from "../constants/screen-names";
import Settings from "./Settings";

type AppProps = {
  localization: Object,
  theme: Object,
  themeType: string,
  currentScreen: String,
  actions: Object,
  layoutSettings: Object,
  groups: Array
};



class App extends Component<AppProps> {
  constructor(context, props) {
    super(context, props);

    this.initWS = this.initWS.bind(this);
    this.handleSaveLayoutSettingsClick = this.handleSaveLayoutSettingsClick.bind(
      this
    );
    this.handleInstanceChange = this.handleInstanceChange.bind(
      this
    );
    this.handleLogout = this.handleLogout.bind(
      this
    );
  }

  
  
  componentDidMount() {
    this.props.actions.loadSettings();
    const { accessToken } = getItem(StorageKeys.AUTH_TOKENS) || {};
    
    if (accessToken) {
      this.props.actions.tryLogin();
    } 
    //this.props.actions.loadConfigs();
    this.initWS();
  }
  componentDidUpdate() {}

  handleSaveLayoutSettingsClick(event, callback) {
    event.preventDefault();

    //console.log("Save click handler!!!");
    //console.log(event);
    const callb = callback;
    this.props.actions.saveLayoutSettings(this.props.layoutSettings, error => {
      if (callb) {
        callb(error);
      }
    });
  }

  handleInstanceChange(event) {
    if (this.getDataForInstance) {
      this.getDataForInstance();
    }
  }
  
  handleLogout(event) {
    if (this.closeHub) {
      this.closeHub();
    }
    const initWS = this.initWS;
    setTimeout(() => initWS(), 500);
  }

  initWS() {
    const { accessToken } = getItem(StorageKeys.AUTH_TOKENS) || {};
    if (!accessToken || !this.props.layoutSettings.layoutId) {
      const initWS = this.initWS;
      setTimeout(() => initWS(), 500);
      return;
    }
    //console.log("Initializing Queue-hub connection...");
    
    const  {
        //sendHubMessage,
        sendHubData,
        subscribeToHub,
        //unsubscribeFromHub,
        closeHub,
        hubActionConstants
      } = createWsClient(
        `${CORE_BASE}/queHub`,
        accessToken,
        () => {
          // sendMessage('TEST');

          //console.log(this.props.layoutSettings);

          const grpIds = this.props.groups.map(g => g.id);
          subscribeToHub(hubActionConstants.UPD_STATS, grpIds, data => {
            this.props.actions.updateWidgetsData(this.props.layoutSettings, [
              data
            ]);
          });
          subscribeToHub(hubActionConstants.UPD_ALERTSTATS, grpIds, data => {
            this.props.actions.updateWidgetsAlertData( data );
          });

          subscribeToHub(hubActionConstants.NEW_CONFIGURATION, null, data => {
            this.props.actions.loadLayoutSettings(err => {
              if (err) {
                return;
              }
              this.getDataForInstance();  
            });
          });

          this.getDataForInstance = () => {
            sendHubData(hubActionConstants.ALL_STATS, "", data => {
              this.props.actions.updateWidgetsData(this.props.layoutSettings, data);
            });
            sendHubData(hubActionConstants.ALL_ALERTSTATS, "", data => {
              this.props.actions.updateWidgetsAlertData(data);
            });
          };

          this.getDataForInstance();

          this.closeHub = closeHub
          //sendWSMessage('WS conn', 'this.props.layoutSettings')
          // subscribe('MESSAGE', (...data) => console.log(data));
        }
      );
    
  }

  render() {
    const { localization, theme, themeType, currentScreen, renaming } = this.props;
    const ScreenMap = {
      [ScreenNames.LOGIN]: <Login />,
      [ScreenNames.WELCOME]: <Help />,
      [ScreenNames.DASHBOARD]: <Dashboard />,
      [ScreenNames.ADMIN_ALERTS]: <AdminAlerts />,
      [ScreenNames.SETTINGS]: <Settings onLogout={this.handleLogout} onInstanceChange={this.handleInstanceChange} />,
    };
    const Screen = () => ScreenMap[currentScreen];

    return (
      <LocalizationContext.Provider value={localization}>
        <ThemeContext.Provider value={theme}>
          <Header
            key="header"
            themeType={themeType}
            onSaveLayoutSettingsClick={this.handleSaveLayoutSettingsClick}
            onInstanceChange={this.handleInstanceChange}
            onLogout={this.handleLogout}
          />
          <Screen />
          <Footer themeType={themeType} />
          {renaming && (
            <div className="spinner-container">
              <Spinner
                style={{margin: "20%"}}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;
            </div>
          )}
        </ThemeContext.Provider>
      </LocalizationContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    localization: localizationSelector(state),
    theme: themeSelector(state),
    themeType: themeTypeSelector(state),
    currentScreen: currentScreenNameSelector(state),
    layoutSettings: layoutSettingsSelector(state),
    groups: attendingGroupsSelector(state),
    renaming: renamingSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...settingsActions,
      ...authActions,
      ...layoutSettingsActions,
      updateWidgetsData,
      updateWidgetsAlertData
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
