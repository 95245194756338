// @flow

import React from 'react';
import ReactSwitch from 'react-switch';
import ThemeContext from '../contexts/ThemeContext';

type SwitchProps = {
  isChecked: boolean,
  onChange: Function,
};

const Switch = (props: SwitchProps) => {
  const { isChecked, onChange, ...restProps } = props;
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ReactSwitch
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={theme.THEME_SWITCH_BACKGROUND}
          offHandleColor={theme.THEME_SWTICH_BULLET}
          onColor={theme.THEME_SWITCH_BACKGROUND}
          onHandleColor={theme.THEME_SWTICH_BULLET}
          activeBoxShadow=""
          checked={isChecked}
          onChange={onChange}
          {...restProps}
        />
      )}
    </ThemeContext.Consumer>
  );
};

export default Switch;
