// @flow
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import ThemeContext from '../../contexts/ThemeContext';
import LocalizationContext from '../../contexts/LocalizationContext';

const styles = {
  container: {
    padding:'40px 50px 50px 50px',
    height: '100%',
    overflow: 'auto'
  },
};

type HelpProps = {
  actions: Object,
  themeType: String,
};

class Help extends Component<HelpProps> {
  render() {
    const { themeType } = this.props;
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => (
              <div style={{...styles.container}}>
                  <Row>
                    <Col>
                      <p dangerouslySetInnerHTML={{ __html: localization.getHelpContent(themeType, theme) }}></p>
                      
                    </Col>
                  </Row>
                </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  // TBD
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      // ...navigationActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Help);
