// @flow
import React from "react";
import AnimatedNumber from "../AnimatedNumber";
import { ANIMATION_DURATION } from "../../constants";

const styles = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //alignItems: "flex-start",
  },
  tableRow: {
    display: "flex",
    //justifyContent: "flex-start",
    alignItems: "flex-start"
    //alignItems: "center"
  },
  tableNumber: {
    minWidth: 30,
    textAlign: "right",
    marginRight: 10,
    marginLeft: 15
  }
};

type ChartTableProps = {
  successful: number,
  unsuccessful: number,
  labels: Object,
  isFirstRender: boolean,
  theme: Object,
  fontFactor: number,
  marginLeft: string,
};

const ChartTable = (props: ChartTableProps) => {
  const {
    successful,
    unsuccessful,
    labels,
    isFirstRender,
    theme,
    fontFactor,
    addStyles,
    height
  } = props;
  const total = successful + unsuccessful;
  const { successfulLabel, unsuccessfulLabel, totalLabel } = labels;
  const textFontSize = (() => {
    return 12 * fontFactor + "px";
  })();
  let stylesTabCont = styles.tableContainer;
  if (addStyles) {
    stylesTabCont = { ...styles.tableContainer, ...addStyles };
  }
  if (height) {
    stylesTabCont = { ...styles.tableContainer, height: `${height}px` };
  }
  
  return (
    <div style={stylesTabCont}>
      <div style={styles.tableRow}>
        <span style={styles.tableNumber}>
          <AnimatedNumber
            isFirstRender={isFirstRender}
            value={unsuccessful}
            style={{ color: theme.UNSUC_BAR, fontSize: textFontSize }}
            animationDuration={ANIMATION_DURATION}
          />
        </span>
        <span
          style={{
            color: theme.LABEL,
            fontSize: textFontSize
          }}
        >
          {unsuccessfulLabel}
        </span>
      </div>
      <div style={styles.tableRow}>
        <span style={styles.tableNumber}>
          <AnimatedNumber
            isFirstRender={isFirstRender}
            value={successful}
            style={{ color: theme.SUC_BAR, fontSize: textFontSize }}
            animationDuration={ANIMATION_DURATION}
          />
        </span>
        <span
          style={{
            color: theme.LABEL,
            fontSize: textFontSize
          }}
        >
          {successfulLabel}
        </span>
      </div>
      <div style={styles.tableRow}>
        <span style={styles.tableNumber}>
          <AnimatedNumber
            isFirstRender={isFirstRender}
            value={total}
            style={{ color: theme.LABEL, fontSize: textFontSize }}
            animationDuration={ANIMATION_DURATION}
          />
        </span>
        <span
          style={{
            color: theme.LABEL,
            fontSize: textFontSize
          }}
        >
          {totalLabel}
        </span>
      </div>
    </div>
  );
};

export default ChartTable;
