import { createSelector } from 'reselect';
import { themeTypeSelector } from './theme';
import { currentLanguageSelector } from './localization';

const EMPTY_OBJECT = {};

const settingsRecord = (state) => state.settings;

const isFirstLoginSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.isFirstLogin,
);

const isHeaderToolsModeActiveSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.isHeaderToolsModeActive,
);

const isClockVisibleSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.isClockVisible,
);

const isAlertVisibleSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.isAlertVisible,
);

const selectedSettingsTabSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.selectedSettingsTab,
);

const alertsActiveSelector = createSelector(
  settingsRecord,
  (record = EMPTY_OBJECT) => record.alertsActive,
);

const userSettingsSelector = (state) => ({
  settings: {
    isFirstLogin: isFirstLoginSelector(state),
    isHeaderToolsModeActive: isHeaderToolsModeActiveSelector(state),
    isClockVisible: isClockVisibleSelector(state),
    isAlertVisible: isAlertVisibleSelector(state),
    selectedSettingsTab: selectedSettingsTabSelector(state),
    alertsActive: alertsActiveSelector(state),
  },
  currentLanguage: currentLanguageSelector(state),
  themeType: themeTypeSelector(state),
});

export {
  isFirstLoginSelector,
  isHeaderToolsModeActiveSelector,
  alertsActiveSelector,
  isClockVisibleSelector,
  selectedSettingsTabSelector,
  userSettingsSelector,
  isAlertVisibleSelector,
};
