import ReduxActions from "../../constants/redux-actions";

const defaultState = {
  selectedInstance: "",
  layoutsSettings: []
};

const layoutSettings = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.LOAD_LAYOUT_SETTINGS: {
      return {
        ...state,
        layoutsSettings: action.layoutsSettingsLoaded
      };
    }
    case ReduxActions.UPDATE_WIDGET: {
      /*
      action = {
        groupNo: 1
        id: 1
        kpi: "LOGGED_IN_AGENTS"
        type: "UPDATE_WIDGET"
        widgetType: "NUMBER"
      }
      state includes layoutsSettings
      */
      //state.layoutsSettings.groups.forEach(g => {
      // g looks like {
      //  groupId: 33101
      //  groupNo: 0
      //  name: "Soluno Support"
      //  type: "ATTENDANT"
      //}
      //});
      if (
        !state.layoutsSettings ||
        !state.layoutsSettings.length ||
        !action.widgetType ||
        !action.id ||
        !action.groupNo
      ) {
        return state;
      }

      let layoutSets = state.layoutsSettings.find(ls => +ls.instanceId === +state.selectedInstance) ?? state.layoutsSettings[0];
      
      const fields = layoutSets.fields;
      let currentWidget = fields.find(f => +f.groupNo === +action.groupNo && +f.id === +action.id);
      if(currentWidget)
      {
        currentWidget.widgetType = action.widgetType;
        if (!currentWidget.props) {
          currentWidget.props = {};
          currentWidget.props.kpi = "";
        }
        if (action.kpi) {
          currentWidget.props.kpi = action.kpi;
        }
      }   
      else {
        fields.push({
          id: action.id,
          groupNo: action.groupNo,
          groupId: action.groupId,
          widgetType: action.widgetType,
          props: {
            kpi: action.kpi
          },  
        })
      }

      return state;
    }
    case ReduxActions.UPDATE_WIDGET_GROUP: {
      if (
        !state.layoutsSettings ||
        !state.layoutsSettings.length ||
        !action.groupId ||
        !action.groupNo
      ) {
        return state;
      }

      let layoutSets = state.layoutsSettings.find(ls => +ls.instanceId === +state.selectedInstance) ?? state.layoutsSettings[0];
      if (!layoutSets.fields) {
        return state;
      }
      const fields = layoutSets.fields;
      if(!fields.find(f => f.groupNo === action.groupNo))
      {        
        fields.push({
          id: action.widgetId,
          groupNo: action.groupNo,
          groupId: action.groupId,
          widgetType: action.widgetType,
          props: {
            kpi: action.kpi
          },  
        })
      }

      layoutSets.fields.forEach(f => {
        if (f.groupNo === action.groupNo) {
          f.groupId = action.groupId;
          f.props.kpi = action.kpi;
        }
      });

      return state;
    }
    case ReduxActions.UPDATE_LAYOUT: {
      if (!state.layoutsSettings || !state.layoutsSettings.length) {
        return state;
      }
      let layoutSets = state.layoutsSettings.find(ls => +ls.instanceId === +state.selectedInstance) ?? state.layoutsSettings[0];
      layoutSets.layoutId = action.layout.id;
      return state;
    }
    case ReduxActions.UPDATE_INSTANCE: {
      state.selectedInstance = action.selectedInstance;
      return state;
    }
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default layoutSettings;
