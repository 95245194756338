import ReduxActions from '../../constants/redux-actions'
import ScreenNames from '../../constants/screen-names'
import StorageKeys from '../../constants/storage-keys'
import { isFirstLoginSelector } from '../selectors/settings'
import api from '../../api'
import { getItem, setItem, removeItem } from '../../utils/storage-utility'
import { loadGroups } from './groups'
import { loadLayoutSettings } from './layoutSettings'

const storeAuth = ({ username, isAdmin, tokens }) => {
  setItem(StorageKeys.AUTH, { username, isAdmin})
  if (tokens) {
    setItem(StorageKeys.AUTH_TOKENS, tokens)
  }
}

const finishLogin = ({ username, isAdmin }, isFirstLogin) => (dispatch) => {
  dispatch({
    type: ReduxActions.LOGIN_FINISHED,
    username,
  isAdmin})

  dispatch({
    type: ReduxActions.CHANGE_SCREEN,
    screenName: isFirstLogin ? ScreenNames.WELCOME : ScreenNames.DASHBOARD
  })
}

const tryLogin = () => (dispatch) => {
  dispatch({ type: ReduxActions.LOGIN_STARTED })

  return api.checkAuth()
    .then(() => {
      // if (status === 204) {
      //  dispatch({ type: ReduxActions.no })
      // } else {
      const { isAdmin, username } = getItem(StorageKeys.AUTH) || {}
      dispatch(loadGroups())
      dispatch(loadLayoutSettings())

      dispatch(finishLogin({ username, isAdmin}, false))
    // }
    })
    .catch(() => {
      dispatch({ type: ReduxActions.LOGIN_ERROR })
    })
}

const login = (username, password, cb) => (dispatch, getState) => {
  const state = getState()
  dispatch({ type: ReduxActions.LOGIN_STARTED })
  const isFirstLogin = isFirstLoginSelector(state)
  api.login(username, password)
    .then((response) => {
      const {isAdmin,
        // eslint-disable-next-line no-unused-vars
        bcsOrgId, auth: { access_token: accessToken, refresh_token: refreshToken, refresh_token2: refreshToken2 }} = response
      const tokens = { accessToken, refreshToken, refreshToken2}
      storeAuth({ username, isAdmin, tokens})
      dispatch(loadGroups())
      dispatch(loadLayoutSettings())
      dispatch(finishLogin({ username, isAdmin}, isFirstLogin))
    })
    .catch((error) => {
      if (error.response.status >= 400 && error.response.status < 500 && cb) {
        cb(false)
      }
      dispatch({ type: ReduxActions.LOGIN_ERROR, error})
    })
}

const logout = () => (dispatch) => {
  removeItem(StorageKeys.AUTH)
  removeItem(StorageKeys.AUTH_TOKENS)
  dispatch({ type: ReduxActions.LOGOUT })
  dispatch({ type: ReduxActions.RESET_STATE })
  dispatch({
    type: ReduxActions.CHANGE_SCREEN,
    screenName: ScreenNames.LOGIN
  })
}

export { tryLogin, login, logout }
