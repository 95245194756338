// import StorageKeys from '../storage-keys'
// import localizations from '../../localizations'
import ThemeTypes from '../theme-types'
import Themes from '../themes'
import { isDarkTheme /*, getCurrentLanguage */ } from '../../utils/index'

import GearIconLight from '../../images/gear-help-light.svg'
import GearIconDark from '../../images/gear-help-dark.svg'

import LogoutIconLight from '../../images/logout-light.svg'
import LogoutIconDark from '../../images/logout-dark.svg'

import InfoIconLight from '../../images/info-light.svg'
import InfoIconDark from '../../images/info-dark.svg'

import SaveIconLight from '../../images/save-light.svg'
import SaveIconDark from '../../images/save-dark.svg'

import SunIconLight from '../../images/sun-light.svg'
import SunIconDark from '../../images/sun-dark.svg'

import MoonIconLight from '../../images/moon-light.svg'
import MoonIconDark from '../../images/moon-dark.svg'

import ToolsActiveLight from '../../images/tools-active-light.svg'
import ToolsActiveDark from '../../images/tools-active-dark.svg'

import ToolsInactiveLight from '../../images/tools-inactive-light.svg'
import ToolsInactiveDark from '../../images/tools-inactive-dark.svg'

// --------------------- below here new icons ---------------------------

import EditLayoutHelpLightIcon from '../../images/layouts/2.4.0.svg'

//import PlayIconLight from '../../images/animated/play-icon-light.svg'
//import PlayIconDark from '../../images/animated/play-icon-dark.svg'

import CaretDownLight from '../../images/caret-down-light.svg' // cccccc
import CaretDownDark from '../../images/caret-down-dark.svg'

import EditContentLightIcon from '../../images/drop-down-light.svg'
// import CursorClickLightIcon from "../../images/cursor-click-light.svg"
import CursorLight from '../../images/cursor-click-light.svg'
import CursorDark from '../../images/cursor-click-dark.svg'

import ChangeLayoutImg from '../../images/changeLayout.gif'
import EditCellContentImg from '../../images/editCellContent.gif'
import EditCellContentImg2 from '../../images/editCellContent2.gif'

const isObject = (obj) => {
  return obj instanceof Object && obj.constructor === Object
}
const convertToCSSDeep = (jsObjWithPossibleObjs) => {
  let cssStr = ''
  for (let objKey in jsObjWithPossibleObjs) {
    if (isObject(jsObjWithPossibleObjs[objKey])) {
      cssStr += objKey + ' { ' + convertToCSSDeep(jsObjWithPossibleObjs[objKey]) + ' } '
    }else {
      cssStr += objKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ': ' + jsObjWithPossibleObjs[objKey] + '; '
    }
  }
  return cssStr
}

const addStylesToHead = (playButtonColor) => {
  const playButtonCSSStr = convertToCSSDeep({
    '@keyframes playcircleframes': {
      '0%': {
        opacity: 0.1,
        strokeDasharray: 650,
        strokeDashoffset: 650
      },
      '20%': {
        opacity: 0.1,
        strokeDasharray: 650,
        strokeDashoffset: 650
      },
      '60%': {
        opacity: 1
      },
      '100%': {
        opacity: 0.1,
        strokeDasharray: 650,
        strokeDashoffset: 0
      }
    },
    '.playcircle': {
      stroke: playButtonColor,
      animationDuration: '4s',
      animationName: 'playcircleframes',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in',
      fill: 'none',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10
    },
    '.playcontainer': {
      width: '30px',
      textAlign: 'center',
      margin: 0
    },
    '.playbutton': {
      display: 'inline-block'
    },
    '.playtriangle': {
      fill: 'none',
      strokeWidth: 1.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      stroke: playButtonColor,
      transform: 'translateY(0)'
    },
    '.playbutton:hover .playtriangle': {
      fill: playButtonColor,
      strokeWidth: 3.5
    },
    '.playbutton:hover .playcircle': {
      animationIterationCount: 0,
      strokeDasharray: 650,
      strokeDashoffset: 0
    }
  })

  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  head.appendChild(style)

  style.type = 'text/css'
  style.appendChild(document.createTextNode(playButtonCSSStr))
}
const getPlayButton = (commandOnClick) => {
  return '';
  // DON'T remove this code, will be uncomment when video is corrected
  // while (commandOnClick && commandOnClick.includes('"')) {
  //   commandOnClick = commandOnClick.replace('"', "'")
  // }

  // return '<span class="playcontainer">' +
  //   `<a href="#" class="playbutton" onClick="${commandOnClick}">` +
  //   '<svg version="1.1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30"> ' +
  //   '<polygon class="playtriangle" points="11,9 21,15 11,21" />' +
  //   '<circle class="playcircle" cx="15" cy="15" r="13"/>' +
  //   '</svg>' +
  //   '</a>' +
  //   '</span>'
}

/**
 * Laddar fint...
 * @param {Object} stateThemeType 
 * @param {Object} stateTheme 
 */
const loadThemeSettings = (stateThemeType, stateTheme) => {

  // const selectedLanguage = currentLanguage || getCurrentLanguage()
  // const selectedLocalization = localizations[selectedLanguage]

  const selectedThemeType = stateThemeType || ThemeTypes.DEFAULT
  const theme = {
    type: selectedThemeType,
    dark: isDarkTheme(selectedThemeType)
  }
  if (stateTheme) {
    theme.obj = stateTheme
  }else {
    theme.obj = Themes[selectedThemeType]
  }

  const icons = {
    gear: theme.dark ? GearIconDark : GearIconLight,
    logout: theme.dark ? LogoutIconDark : LogoutIconLight,
    info: theme.dark ? InfoIconDark : InfoIconLight,
    save: theme.dark ? SaveIconDark : SaveIconLight,
    sun: theme.dark ? SunIconDark : SunIconLight,
    moon: theme.dark ? MoonIconDark : MoonIconLight,
    toolsActive: theme.dark ? ToolsActiveDark : ToolsActiveLight,
    toolsInactive: theme.dark ? ToolsInactiveDark : ToolsInactiveLight,
    cursor: theme.dark ? CursorDark : CursorLight,
    // play: theme.dark ? PlayIconDark : PlayIconLight,
    // play: theme.dark ? playButton : playButton,
    caret: theme.dark ? CaretDownDark : CaretDownLight,
    editLayout: EditLayoutHelpLightIcon
  }

  addStylesToHead('white')
  return { icons, theme}
}

export default (stateThemeType, stateTheme) => {
  const { icons, theme } = loadThemeSettings(stateThemeType, stateTheme)
  /* eslint-disable */
  return {
    theme,
    common: {
      subHeaderStart: '<br><span style="font-weight:bold">', 
      subHeaderEnd: '</span>',

      leftDiv100: '<div style="float:left;clear:left;width:100%; text-align: left">',
      left: '<div style="float:left;clear:left;width:140px; text-align:center; transform: scale(.9); margin-top:20px">',
      middle: '</div><div style="float:left; width: 60%;margin-top:20px; padding-top: 6px">',
      middle2: '</div><div style="float:left; width: 60%;margin-top:20px; padding-top: 16px">',
      middle100Minus141px: '</div><div style="float:left; width: calc(100% - 141px);margin-top:20px">',
      middle100Minus141px2: '</div><div style="float:left; width: calc(100% - 141px);margin-top:20px; padding-top: 12px">',
      right: '</div>',
      settingsIcon: '<img src=' + icons.gear + ' style="width: 24px; height: 24px; margin-bottom: 0px">',
      editContentIcon: '<img src=' + EditContentLightIcon + ' style="width: 32px; height: 32px">',
      editContentIcon2: '<img src=' + icons.cursor + ' style="width: 32px; height: 32px">',
      layoutIcon: '<img src=' + EditLayoutHelpLightIcon + ' style="width: 50px; background-color: #8c8c8c">',
      saveIcon: '<img src=' + icons.save + '>',
      lightnessIcon: '<div style="float:left; clear: left; display: flex; align-items: center; justify-content: center;"> \
                                <img src=' + icons.sun + ' style="float:left"> \
                                <div style="position: relative; display: inline-block; margin: 0 6px; text-align: left; opacity: 1; border-radius: 14px"> \
                                    <div class="react-switch-bg" style="height: 28px; width: 56px; margin: 0px; position: relative; background: ' + ` ${theme.obj.THEME_SWITCH_BACKGROUND}` + '; border-radius: 14px; transition: background 0.25s ease 0s;"></div> \
                                    <div class="react-switch-handle" style="height: 26px; width: 26px; background: rgb(45, 107, 138); display: inline-block; border-radius: 50%; position: absolute; transform: translateX(29px); top: 1px; outline: 0px; border: 0px; transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;"></div><input type="checkbox" role="switch" checked="" style="border: 0px; clip: rect(0px, 0px, 0px, 0px); height: 1px; margin: -1px; overflow: hidden; padding: 0px; position: absolute; width: 1px;"> \
                                </div> \
                                <img src=' + icons.moon + ' style="width: 24px; height: 24px"> \
                        </div>',
      buttonsIcon: '<div style="float:left; clear: left; display: flex; align-items: center; justify-content: center;"> \
                        <img src=' + icons.toolsInactive + ' > \
                        <div style="position: relative; display: inline-block; margin: 0 6px; text-align: left; opacity: 1; border-radius: 14px"> \
                            <div class="react-switch-bg" style="height: 28px; width: 56px; margin: 0px; position: relative; background: ' + ` ${theme.obj.THEME_SWITCH_BACKGROUND}` + '; border-radius: 14px; transition: background 0.25s ease 0s;"></div> \
                            <div class="react-switch-handle" style="height: 26px; width: 26px; background: rgb(45, 107, 138); display: inline-block; border-radius: 50%; position: absolute; transform: translateX(29px); top: 1px; outline: 0px; border: 0px; transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;"></div><input type="checkbox" role="switch" checked="" style="border: 0px; clip: rect(0px, 0px, 0px, 0px); height: 1px; margin: -1px; overflow: hidden; padding: 0px; position: absolute; width: 1px;"> \
                        </div> \
                        <img src=' + icons.toolsActive + ' style="width:30px; height:30px"> \
                      </div>',
      helpIcon: '<img src=' + icons.info + ' style="width: 32px; height: 32px">',
      logoutIcon: '<img src=' + icons.logout + ' style="width: 32px; height: 32px">',
      droplistStart: '<div style="float:left;margin: 8px 0 0 10px"> \
                            <div style="height: 40px; width:110px; border: solid 1px #fff;  background-color: ' + ` ${theme.obj.THEME_SWITCH_BACKGROUND}` + '; border-radius: 4px"> \
                                <div style="float:left; margin:7px 10px; font-size: 15px;">',
      droplistEnd: '</div> \
                                <div aria-hidden="true" style="float: right; margin:7px 9px"> \
                                    <img src=' + icons.caret + '> \
                                </div>  \
                            </div>  \
                        </div>',
      // playButtonIcon_changeLayout: '<a href="#" style="margin:0 6px" onClick="document.getElementById(\'changeLayoutImg\').style.display = \'block\'"> \
      //                   <img src=' + icons.play + '> \
      //               </a>',
      playButtonIcon_changeLayout: '&nbsp;' + getPlayButton('document.getElementById("changeLayoutImg").style.display = "block"'),
      playButtonIcon_editCellContent: '&nbsp;' + getPlayButton('document.getElementById("editCellContentImg").style.display = "block"'),
      playButtonIcon_editCellContent2: '&nbsp;' + getPlayButton('document.getElementById("editCellContentImg2").style.display = "block"'),
      // playButtonIcon_editCellContent: '<a href="#" style="margin:0 6px" onClick="document.getElementById(\'editCellContentImg\').style.display = \'block\'"> \
      //                       <img src=' + icons.play + '> \
      //                   </a>',
      // playButtonIcon_editCellContent2: '<a href="#" style="margin:0 6px" onClick="document.getElementById(\'editCellContentImg2\').style.display = \'block\'"> \
      //                       <img src=' + icons.play + '> \
      //                   </a>',
      image_changeLayout: '<span id="changeLayoutImg" style="display:none; position: fixed; z-index: 1; -webkit-box-shadow: #44748c 0px 0px 20px; -moz-box-shadow: #44748c 0px 0px 20px; box-shadow: #44748c 0px 0px 20px; border-radius: 8px; width:669px; height:480px; left: 50%; margin-left: -335px; top: 80px;"><img style="border: solid 2px #fff;border-radius: 8px;" onClick="document.getElementById(\'changeLayoutImg\').style.display = \'none\'" src="' + ChangeLayoutImg + '"></span>',
      image_editCellContent: '<span id="editCellContentImg" style="display:none; position: fixed; z-index: 1; -webkit-box-shadow: #44748c 0px 0px 20px; -moz-box-shadow: #44748c 0px 0px 20px; box-shadow: #44748c 0px 0px 20px; border-radius: 8px; width:350px; height:374px; left: 50%; margin-left: -175px; top: 80px;"><img style="border: solid 2px #fff;border-radius: 8px;" onClick="document.getElementById(\'editCellContentImg\').style.display = \'none\'" src="' + EditCellContentImg + '"></span>',
      image_editCellContent2: '<span id="editCellContentImg2" style="display:none; position: fixed; z-index: 1; -webkit-box-shadow: #44748c 0px 0px 20px; -moz-box-shadow: #44748c 0px 0px 20px; box-shadow: #44748c 0px 0px 20px; border-radius: 8px; width:640px; height:564px; left: 50%; margin-left: -320px; top: 80px;"><img style="border: solid 2px #fff;border-radius: 8px;" onClick="document.getElementById(\'editCellContentImg2\').style.display = \'none\'" src="' + EditCellContentImg2 + '"></span>',
    }
  }
  /* eslint-enable */
}
