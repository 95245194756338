import { combineReducers } from 'redux'
import layoutReducer from './layout'
import widgetsReducer from './widgets'
import groupsSelector from './groups'
import localizationReducer from './localization'
import themeReducer from './theme'
import authReducer from './auth'
import settingsReducer from './settings'
import navigationReducer from './navigation'
import layoutSettings from './layoutSettings'
import instancesReducer from './instances'

export default combineReducers({
  layout: layoutReducer,
  widgets: widgetsReducer,
  groups: groupsSelector,
  localization: localizationReducer,
  theme: themeReducer,
  auth: authReducer,
  settings: settingsReducer,
  navigation: navigationReducer,
  layoutSettings: layoutSettings,
  instances: instancesReducer,
})
