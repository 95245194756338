// @flow
import React, { Component } from "react";
import LinkSelect from "../LinkSelect";
import ChartTable from "./ChartTable";
import LocalizationContext from "../../contexts/LocalizationContext";
import ThemeContext from "../../contexts/ThemeContext";
import { ANIMATION_DURATION } from "../../constants";

const styles = {
  widget: {
    display: "flex",
    flex: 1,
    //alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
  },
  chartContainer: {
    //display: "flex",
    //flexDirection: "column",
    //height: "10vh"
    //height: "calc(80%)",
    //height: '100%'
    //display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'flex-start',
    width: '40px',
    height: 'calc(100%)', 
    minHeight: '100%'
  },
  unsuccessfulBar: {
    width: 40
  },
  successfulBar: {
    width: 40
  },
  chartTD: { 
    //paddingTop: '1%',
    width: '42px',
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center", 
    
  }
};

const getChartHeight = (successful, unsuccessful) => {
  const total = successful + unsuccessful;
  const totalHeight = 100;
  const unsuccessfulHeight = (totalHeight / total) * unsuccessful;
  const successfulHeight = totalHeight - unsuccessfulHeight;
  return {
    successfulHeight: `${successfulHeight}%`,
    unsuccessfulHeight: `${unsuccessfulHeight}%`
  };
};

type ChartProps = {
  successfulHeight: number,
  unsuccessfulHeight: number,
  opacity: number,
  theme: Object
};

const Chart = (props: ChartProps) => {
  const { successfulHeight, unsuccessfulHeight, opacity, theme, height } = props;
  return (  

    <div style={{ ...styles.chartContainer, opacity, height: `${height}px` }}>
      <div
        style={{
          ...styles.unsuccessfulBar,
          backgroundColor: theme.UNSUC_BAR,
          height: unsuccessfulHeight
        }}
      />
      <div
        style={{
          ...styles.successfulBar,
          backgroundColor: theme.SUC_BAR,
          height: successfulHeight
        }}
      />
    </div>
  );
};

type BarChartWidgetProps = {
  successful: number,
  unsuccessful: number,
  successfulLabel?: string,
  unsuccessfulLabel?: string,
  totalLabel?: string,
  id: number,
  groupNo: number,
  groupId: number,
  fontFactor: number,
};

//Step::4
class BarChartWidget extends Component<BarChartWidgetProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFirstRender: true,
      opacity: 0,
      intervalId: 0,
      timeoutId: 0,
      trHeight: 0
    };
  }

  componentDidMount() {
    const trHeight = this.trElement.clientHeight;
    this.setState({ trHeight });
    const timeoutId = setTimeout(() => {
      clearInterval(this.state.intervalId);
      this.setState({
        isFirstRender: false,
        opacity: 1
      });
    }, (ANIMATION_DURATION * 1000) + 10);
    this.setState({
      timeoutId: timeoutId
    });
    this.animateOpacity();
  }
  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
    clearInterval(this.state.intervalId);
  }
  animateOpacity(numberOfUpdates = 100) {
    const intervalTime = (ANIMATION_DURATION * 1000) / numberOfUpdates;
    const intervalId = setInterval(() => {
      const { opacity } = this.state;
      if (this.state.opacity >= 1) {
        clearInterval(this.state.intervalId);
      } else {
        this.setState({ opacity: opacity + 1 / numberOfUpdates });
      }
    }, intervalTime);
    this.setState({
      intervalId: intervalId
    });
  }

  render() {
    //Step::5
    const {
      successful,
      unsuccessful,
      successfulLabel,
      unsuccessfulLabel,
      totalLabel,
      groups,
      onGroupChange,
      groupId,
      fontFactor
    } = this.props;

    const { isFirstRender, opacity, trHeight } = this.state;
    const options = groups.map(({ id, name }) => ({
      value: `${id}`,
      label: name
    }));
    const selectedValue = options.find(
      option => option.value === `${groupId}`
    ) || options[0];

    return (
      <LocalizationContext.Consumer>
        {localization => {
          const labels = {
            successfulLabel: localization[successfulLabel],
            unsuccessfulLabel: localization[unsuccessfulLabel],
            totalLabel: localization[totalLabel]
          };
          const { successfulHeight, unsuccessfulHeight } = getChartHeight(
            successful,
            unsuccessful
          );
          return (
            <ThemeContext.Consumer>
              {theme => (
                <div style={styles.widget}>
                  <table style={{ width: '100%', height: '100%' }}>
                    <tbody >
                      <tr style={{ height: '40px' }}>
                        <td style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                          <LinkSelect
                          className="link-select"
                          fontFactor={fontFactor}
                          options={options}
                          value={selectedValue}
                          onChange={event =>
                            onGroupChange(
                              event.value,
                              this.props.groupNo,
                              this.props.id,
                              this.props.kpi,
                              this.props.type
                            )
                          }
                          selectStyle={{
                            color: theme.TITLE
                          }}
                        />
                      </td></tr>
                      {/* <tr style={{ height: '50%' }}><td style={styles.chartTD}> */}
                      <tr ref={(trElement) => this.trElement = trElement}>
                        <td title={localization.TIP_CHANGE_MTRC} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Chart
                          successfulHeight={successfulHeight}
                          unsuccessfulHeight={unsuccessfulHeight}
                          opacity={opacity}
                          theme={theme}
                          height={trHeight}
                        />
                        <ChartTable
                          successful={successful}
                          unsuccessful={unsuccessful}
                          labels={labels}
                          isFirstRender={isFirstRender}
                          fontFactor={fontFactor}
                          theme={theme}
                        />
                      </td></tr>
                    </tbody>
                  </table>
                </div>
              )}
            </ThemeContext.Consumer>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

BarChartWidget.defaultProps = {
  successfulLabel: "ANSWERED",
  unsuccessfulLabel: "MISSED",
  totalLabel: "TOTAL"
};

export default BarChartWidget;
