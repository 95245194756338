import ReduxActions from "../../constants/redux-actions";
import ThemeTypes from "../../constants/theme-types";
import themes from '../../constants/themes';

const defaultState = {
  themeType: ThemeTypes.DEFAULT,
  theme: themes[ThemeTypes.DEFAULT]
};

const theme = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.CHANGE_THEME:
      return Object.assign({}, state, {
        themeType: action.themeType,
        theme: themes[action.themeType]
      });
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default theme;
