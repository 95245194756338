import ReduxActions from '../../constants/redux-actions';
import api from '../../api';

const updateAttendingGroups = (attendingGroups) => (dispatch) => {
  dispatch({
    type: ReduxActions.UPDATE_ATTENDING_GROUPS,
    attendingGroups,
  });
};

const loadGroups = () => (dispatch) => {
  api.getGroups()
    .then((groups) => {
      dispatch(updateAttendingGroups(groups));
    })
    .catch((error) => console.error(error));
};

export {
  updateAttendingGroups,
  loadGroups,
};
