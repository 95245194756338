import ReduxActions from '../../constants/redux-actions';
import { AVAILABLE_LANGUAGES } from '../../constants';
import { getCurrentLanguage } from '../../utils';
import localizations from '../../localizations';

const currentLanguage = getCurrentLanguage();

const defaultState = {
  languages: AVAILABLE_LANGUAGES,
  current: currentLanguage,
  localization: localizations[currentLanguage],
};

const localization = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        current: action.langTag,
        localization: localizations[action.langTag],
      });
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default localization;
