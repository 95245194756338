import * as auth from './auth';
import * as groups from './groups';
import * as configs from './configs';
import * as instances from './instances';

export default {
  ...auth,
  ...groups,
  ...configs,
  ...instances,
};
