// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { layoutSelector, layoutsSelector } from '../../store/selectors/layout';
import * as navigationActions from '../../store/actions/navigation';
import * as layoutsActions from '../../store/actions/layouts';
import ThemeContext from '../../contexts/ThemeContext';
import LocalizationContext from '../../contexts/LocalizationContext';
import ScreenNames from '../../constants/screen-names';
import CellWithNumber from '../../components/CellWithNumber';

const styles = {
  container: {
    height: '100%',
    margin: 'auto',
    padding:'40px 50px 50px 50px',
  },
  layout: {
    borderColor: 'transparent',
    borderWidth: '5px',
    borderStyle: 'solid',
  },
  layoutContainer: {
  },
  currentLayout: {
    borderColor: 'dodgerblue',
  },
  selectedLayout: {
    borderColor: 'forestgreen',
  },
  heading: {
    fontSize: '20px',
  },
};

type LayoutsProps = {
  actions: Object,
};

class Layouts extends Component<LayoutsProps> {
  constructor(props, state) {
    super(props, state);

    this.state = {
      activeLayout: undefined,
    };

    this.selectLayout = this.selectLayout.bind(this);
    this.renderLayout = this.renderLayout.bind(this);
this.changeLayout = this.changeLayout.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  selectLayout(layout) {
    this.setState({ activeLayout: layout }, this.changeLayout);
  }

  changeLayout() {
    const { actions: { updateLayout } } = this.props;
    const { activeLayout } = this.state;
    if (activeLayout) {
      updateLayout(activeLayout);
    }
  }

  cancel() {
    const { actions: { changeScreen } } = this.props;
    changeScreen(ScreenNames.DASHBOARD);
  }

  renderLayout(layout, withNumbers) {
    const currentLayout = this.props.layout;
    const { activeLayout } = this.state;
    const { id } = layout;
    return (
        <CellWithNumber
          key={layout.id} 
          layout={layout} 
          withNumbers={withNumbers}
          onClick={() => this.selectLayout(layout)}
          style={{
            ...styles.layout,
            ...(id === currentLayout.id && styles.currentLayout),
            ...(activeLayout && id === activeLayout.id && styles.selectedLayout),
          }}
        />
    )
  }


  render() {
    const { layouts } = this.props;
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => (
              <div style={{ ...styles.container, color: theme.TEXT }}>
              <Row>
                  <Col>
                    <span style={styles.heading}>
                      { localization.LAYOUT_HEADER_SINGLE }
                    </span>
                  </Col>
                </Row>
                <Row style={styles.layoutContainer}>
                  {
                    layouts.singleGroup
                      .map(
                        (layout) => this.renderLayout(layout, false),
                      )
                  }
                </Row>
                <Row style={{paddingTop: '15px'}}>
                  <Col>
                    <span style={styles.heading}>
                      { localization.LAYOUT_HEADER_MULTI }
                    </span>
                  </Col>
                </Row>
                <Row style={styles.layoutContainer}>
                  {
                    layouts.multiGroup
                      .map(
                        (layout) => this.renderLayout(layout, true),
                      )
                  }
                </Row>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  layout: layoutSelector(state),
  layouts: layoutsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...navigationActions,
      ...layoutsActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layouts);
