import cloneDeep from 'lodash/cloneDeep';
import * as Ramda from "ramda";
import ReduxActions from "../../constants/redux-actions";
import Kpis from "../../constants/kpis";
import WidgetTypes from "../../constants/widget-types";
import { defaultWidgets } from "../../configs/widgets";

const defaultState = {
  widgets: defaultWidgets,
  queStats: [],
  alertStats: []
};
function sum(state, kpi)  {
  if(state.queStats?.length === 0) {
    return 0;
  } else if(state.queStats?.length === 1) {
    return state.queStats[kpi];
  } else {
    return state.queStats?.length ? state.queStats.map(i => i[kpi] ?? 0).reduce((a,b) => a + b) : 0;
  }
} 
function sumAgentCollection(state, kpi)  {
  if(state.queStats?.length === 0) {
    return 0;
  } else if(state.queStats?.length === 1) {
    return state.queStats[kpi];
  } else {
    if(!state.queStats?.length) {
      return 0;
    }
    let grouped = state.queStats.flatMap(i => i[kpi]).reduce((r, a) => {
      const index = r?.findIndex(i => i.id === a.id);
      if(index === -1) {
        r = [...r, cloneDeep(a)];
      } else {
        r[index].inbound += +a.inbound;
        r[index].outbound += +a.outbound; 
      }
      return r;
    }, []);

    return grouped;
  }
} 

const pieAgentProps = {
  successfulLabel: "FREE_AGENTS",
  unsuccessfulLabel: "AGENTS_IN_CALL",
  totalLabel: "AVAILABLE_AGENT_LIST"
};
const pieCallProps = {
  successfulLabel: "ANSWERED",
  unsuccessfulLabel: "MISSED",
  totalLabel: "TOTAL"
};

const widgets = (state = defaultState, action) => {
  switch (action.type) {
    case ReduxActions.UPDATE_WIDGET_DATA: {
      if (!action.data || !action.data.groupId) {
        return state;
      }
      const newState = { queStats: [...state.queStats], alertStats: [...state.alertStats], widgets: [...state.widgets] };

      const groupId = action.data.groupId;
      const data = action.data;
      let dataIndx = newState.queStats.findIndex(qs => +qs.groupId === +groupId);
      if (dataIndx >= 0) {
        newState.queStats[dataIndx] = data;
      } else {
        newState.queStats.push(data);
        dataIndx = newState.queStats.length - 1;
      }

      newState.widgets.forEach(w => {
        if(+w.groupId === 0) {
          if ( w.type === WidgetTypes.AGENTS_PIE_CHART ) {
            w.props = {
              successful: sum(newState, Kpis.AVAILABLE_AGENTS),
              unsuccessful: sum(newState, Kpis.LOGGED_IN_AGENTS) - sum(newState, Kpis.AVAILABLE_AGENTS),
              ...pieAgentProps,
            };
          } else if (
            w.type === WidgetTypes.BAR_CHART ||
            w.type === WidgetTypes.PIE_CHART ||
            w.type === WidgetTypes.CHART_GRID
          ) {
            w.props = {
              successful: sum(newState, Kpis.ANSWERED),
              unsuccessful: sum(newState, Kpis.MISSED),
              ...pieCallProps,
            };
          } else if(w.type === WidgetTypes.TOP_LIST_OUT ||
            w.type === WidgetTypes.AGENT_SIMPLE_LIST ||
            w.type === WidgetTypes.TOP_LIST_IN ) {
            w.props = { value: sumAgentCollection(newState, Kpis.AGENT_LIST) };
          } else  {
            w.props.value = sum(newState, w.props?.kpi)
          }
        }
        else if (+w.groupId === +groupId) {
          if ( w.type === WidgetTypes.AGENTS_PIE_CHART ) {
            w.props = {
              successful: data[Kpis.AVAILABLE_AGENTS],
              unsuccessful: data[Kpis.LOGGED_IN_AGENTS] - data[Kpis.AVAILABLE_AGENTS],
              ...pieAgentProps,
            };
          } else if (
            w.type === WidgetTypes.BAR_CHART ||
            w.type === WidgetTypes.PIE_CHART ||
            w.type === WidgetTypes.CHART_GRID
          ) {
            w.props = {
              successful: data[Kpis.ANSWERED] ? data[Kpis.ANSWERED] : 0,
              unsuccessful: data[Kpis.MISSED] ? data[Kpis.MISSED] : 0,
              ...pieCallProps,
            };
          } else if(w.type === WidgetTypes.TOP_LIST_OUT ||
            w.type === WidgetTypes.AGENT_SIMPLE_LIST ||
            w.type === WidgetTypes.TOP_LIST_IN ) {
            w.props = { value: data[Kpis.AGENT_LIST] };
          } else  {
            w.props.value = data[w.props?.kpi];
          }
        }
      });
      
      return newState;
    }
    case ReduxActions.UPDATE_WIDGET_ALERT_DATA: {
      if (!action.data || !action.data.groupId) {
        const newState = { queStats: [...state.queStats], alertStats: [], widgets: [...state.widgets] };
        newState.widgets.forEach(w => {
          w.triggered = false;
        });
        return newState;
      }
      const newState = { queStats: [...state.queStats], alertStats: [...state.alertStats], widgets: [...state.widgets] };

      const groupId = action.data.groupId;
      const data = action.data;
      let dataIndx = newState.alertStats.findIndex(qs => +qs.groupId === +groupId);
      if (dataIndx >= 0) {
        newState.alertStats[dataIndx] = data;
      } else {
        newState.alertStats.push(data);
      }

      newState.widgets.forEach(w => {
        if (+w.groupId === +groupId || +w.groupId === 0) { 
          // We do not update alertStat for complex widgets: approved by Mattias, BUT decided to leave for now
          if ( w.type === WidgetTypes.AGENTS_PIE_CHART ) {
            const isTriggeredAvailable = action.data[Kpis.AVAILABLE_AGENTS] ? !!action.data[Kpis.AVAILABLE_AGENTS] : 0;
            const isTriggeredLoggedIn = action.data[Kpis.LOGGED_IN_AGENTS] ? !!action.data[Kpis.LOGGED_IN_AGENTS] : 0;
            w.triggered = isTriggeredAvailable || isTriggeredLoggedIn;
          } else if( w.type === WidgetTypes.BAR_CHART ||
            w.type === WidgetTypes.PIE_CHART ||
            w.type === WidgetTypes.CHART_GRID){
            const isTriggeredAnswered = action.data[Kpis.ANSWERED] ? !!action.data[Kpis.ANSWERED] : 0;
            const isTriggeredMissed = action.data[Kpis.MISSED] ? !!action.data[Kpis.MISSED] : 0;
            w.triggered = isTriggeredAnswered || isTriggeredMissed;
          } else if (
            action.widgetType === WidgetTypes.AGENT_SIMPLE_LIST ||
            action.widgetType === WidgetTypes.TOP_LIST_IN ||
            action.widgetType === WidgetTypes.TOP_LIST_OUT
          ) {
            w.triggered = false;
          }
          else if(w.props?.kpi) {
            w.triggered = action.data[w.props?.kpi];
          } 
        } 
      });
      
      return newState;
    }
    case ReduxActions.UPDATE_WIDGET: {
      const indx1 = state.widgets.findIndex(
        w => +action.id === +w.id && +action.groupNo === +w.groupNo
      );
      if (indx1 < 0) {
        return state;
      }
      const newState = { queStats: [...state.queStats], alertStats: [...state.alertStats], widgets: [...state.widgets] };
      
      const processSingleGroup = (action) => {
        wdgt.type = action.widgetType;
        const queStat = newState.queStats.find(qs => +qs.groupId === +wdgt.groupId);
        const alertStat = newState.alertStats.find(qs => +qs.groupId === +wdgt.groupId);
        wdgt.props = wdgt.props ?? {};
        if ( action.widgetType === WidgetTypes.AGENTS_PIE_CHART ) {
          wdgt.props.successful = queStat && queStat[Kpis.AVAILABLE_AGENTS] ? queStat[Kpis.AVAILABLE_AGENTS] : 0;
          wdgt.props.unsuccessful = (queStat &&  queStat[Kpis.LOGGED_IN_AGENTS] ? queStat[Kpis.LOGGED_IN_AGENTS] : 0) - (queStat && queStat[Kpis.AVAILABLE_AGENTS] ? queStat[Kpis.AVAILABLE_AGENTS] : 0);
          wdgt.props = { ...wdgt.props, ...pieAgentProps };
          const isTriggeredAvailable = alertStat && alertStat[Kpis.AVAILABLE_AGENTS] ? !!alertStat[Kpis.AVAILABLE_AGENTS] : 0;
          const isTriggeredLoggedIn = alertStat && alertStat[Kpis.LOGGED_IN_AGENTS] ? !!alertStat[Kpis.LOGGED_IN_AGENTS] : 0;
          wdgt.triggered = isTriggeredAvailable || isTriggeredLoggedIn;          
        } else if (
          action.widgetType === WidgetTypes.BAR_CHART ||
          action.widgetType === WidgetTypes.PIE_CHART ||
          action.widgetType === WidgetTypes.CHART_GRID
        ) {
          wdgt.props.successful = queStat && queStat[Kpis.ANSWERED] ? queStat[Kpis.ANSWERED] : 0;
          wdgt.props.unsuccessful = queStat && queStat[Kpis.MISSED] ? queStat[Kpis.MISSED] : 0;
          wdgt.props = { ...wdgt.props, ...pieCallProps };
          // We do not update alertStat for complex widgets: approved by Mattias, BUT decided to leave for now
          const isTriggeredAnswered = alertStat && alertStat[Kpis.ANSWERED] ? !!alertStat[Kpis.ANSWERED] : 0;
          const isTriggeredMissed = alertStat && alertStat[Kpis.MISSED] ? !!alertStat[Kpis.MISSED] : 0;
          wdgt.triggered = isTriggeredAnswered || isTriggeredMissed;
        } else if (
          action.widgetType === WidgetTypes.AGENT_SIMPLE_LIST ||
          action.widgetType === WidgetTypes.TOP_LIST_IN ||
          action.widgetType === WidgetTypes.TOP_LIST_OUT
        ) {

          wdgt.props.value = queStat && queStat[Kpis.AGENT_LIST] ? queStat[Kpis.AGENT_LIST] : 0;
          wdgt.triggered = false;
        } else {
          wdgt.props.kpi = action.kpi;
          wdgt.props.value = queStat && queStat[action.kpi] ? queStat[action.kpi] : 0;
          wdgt.triggered = alertStat && alertStat[action.kpi] ? alertStat[action.kpi] : 0;
        }
      };
      
      const processAllGroups = (action) => {
        wdgt.type = action.widgetType;
        wdgt.props = wdgt.props ?? {};
        if ( action.widgetType === WidgetTypes.AGENTS_PIE_CHART ) {
          wdgt.props.successful = sum(newState, Kpis.AVAILABLE_AGENTS);
          wdgt.props.unsuccessful  = sum(newState, Kpis.LOGGED_IN_AGENTS) - sum(newState, Kpis.AVAILABLE_AGENTS);
          const isTriggeredAvailable = newState.alertStats?.length ? !!newState.alertStats.find(a => a[Kpis.AVAILABLE_AGENTS]) : false;
          const isTriggeredLoggedIn = newState.alertStats?.length ? !!newState.alertStats.find(a => a[Kpis.LOGGED_IN_AGENTS]) : false;
          wdgt.triggered = isTriggeredAvailable || isTriggeredLoggedIn;
          
        } else if (
          action.widgetType === WidgetTypes.BAR_CHART ||
          action.widgetType === WidgetTypes.PIE_CHART ||
          action.widgetType === WidgetTypes.CHART_GRID
        ) {
          wdgt.props.successful = sum(newState, Kpis.ANSWERED);
          wdgt.props.unsuccessful  = sum(newState, Kpis.MISSED);
          // We do not update alertStat for complex widgets: approved by Mattias, BUT decided to leave for now
          const isTriggeredAnswered = newState.alertStats?.length ? !!newState.alertStats.find(a => a[Kpis.ANSWERED]) : false;
          const isTriggeredMissed = newState.alertStats?.length ? !!newState.alertStats.find(a => a[Kpis.MISSED]) : false;
          wdgt.triggered = isTriggeredAnswered || isTriggeredMissed;
        } else if (
          action.widgetType === WidgetTypes.AGENT_SIMPLE_LIST ||
          action.widgetType === WidgetTypes.TOP_LIST_IN ||
          action.widgetType === WidgetTypes.TOP_LIST_OUT
        ) {
          wdgt.props.value = sumAgentCollection(newState, Kpis.AGENT_LIST);
          wdgt.triggered = false;
        } else {
          wdgt.props.kpi = action.kpi;
          wdgt.props.value =  sum(newState, action.kpi);
          wdgt.triggered = newState.alertStats?.length ? !!newState.alertStats.find(a => a[action.kpi]) : false;
        }
      };

      const wdgt = newState.widgets[indx1];
      if(+wdgt.groupId) {
        processSingleGroup(action);
      } else {
        processAllGroups(action);
      }
      return newState;
    }
    case ReduxActions.UPDATE_WIDGET_GROUP: {
      const newWGrpState = { queStats: [...state.queStats], alertStats: [...state.alertStats], widgets: [...state.widgets] };
      const processSingleGroup = (action) => {
        const queStat = newWGrpState.queStats.find(
          qs => parseInt(qs.groupId) === parseInt(action.groupId)
        );
        const alertStat = newWGrpState.alertStats.find(
          as => parseInt(as.groupId) === parseInt(action.groupId)
        );
        
        newWGrpState.widgets.forEach(wdgt => {
          wdgt.props = wdgt.props ?? {};
          if (+wdgt.groupNo === +action.groupNo) {
            wdgt.groupId = action.groupId;
            if ( wdgt.type === WidgetTypes.AGENTS_PIE_CHART ) {
              wdgt.props.successful = queStat && queStat[Kpis.AVAILABLE_AGENTS] ? queStat[Kpis.AVAILABLE_AGENTS] : 0;
              wdgt.props.unsuccessful = (queStat &&  queStat[Kpis.LOGGED_IN_AGENTS] ? queStat[Kpis.LOGGED_IN_AGENTS] : 0) - (queStat && queStat[Kpis.AVAILABLE_AGENTS] ? queStat[Kpis.AVAILABLE_AGENTS] : 0);
              const isTriggeredAvailable = alertStat && alertStat[Kpis.AVAILABLE_AGENTS] ? !!alertStat[Kpis.AVAILABLE_AGENTS] : 0;
              const isTriggeredLoggedIn = alertStat && alertStat[Kpis.LOGGED_IN_AGENTS] ? !!alertStat[Kpis.LOGGED_IN_AGENTS] : 0;
              wdgt.triggered = isTriggeredAvailable || isTriggeredLoggedIn;
            } else if (
              wdgt.type === WidgetTypes.BAR_CHART ||
              wdgt.type === WidgetTypes.PIE_CHART ||
              wdgt.type === WidgetTypes.CHART_GRID
            ) {
              wdgt.props.successful = queStat && queStat[Kpis.ANSWERED] ? queStat[Kpis.ANSWERED] : 0;
              wdgt.props.unsuccessful = queStat && queStat[Kpis.MISSED] ? queStat[Kpis.MISSED] : 0;
              // We do not update alertStat for complex widgets: approved by Mattias, BUT decided to leave for now
              const isTriggeredAnswered = alertStat && alertStat[Kpis.ANSWERED] ? !!alertStat[Kpis.ANSWERED] : 0;
              const isTriggeredMissed = alertStat && alertStat[Kpis.MISSED] ? !!alertStat[Kpis.MISSED] : 0;
              wdgt.triggered = isTriggeredAnswered || isTriggeredMissed;
            } else if (
              wdgt.type === WidgetTypes.AGENT_SIMPLE_LIST ||
              wdgt.type === WidgetTypes.TOP_LIST_IN ||
              wdgt.type === WidgetTypes.TOP_LIST_OUT
            ) {
              wdgt.props.value = queStat && queStat[Kpis.AGENT_LIST] ? queStat[Kpis.AGENT_LIST] : 0;
              wdgt.triggered = false;
            } else {
              wdgt.props.value = queStat && queStat[wdgt.props?.kpi] ? queStat[wdgt.props?.kpi] : 0;
              wdgt.triggered = alertStat && alertStat[wdgt.props?.kpi] ? alertStat[wdgt.props?.kpi] : 0;
            }
          }
        });
      }
      const processAllGroups = (action) => {
        newWGrpState.widgets.forEach(wdgt => {
          wdgt.props = wdgt.props ?? {};
          if (+wdgt.groupNo === +action.groupNo) {
            wdgt.groupId = action.groupId;
            if ( wdgt.type === WidgetTypes.AGENTS_PIE_CHART ) {
              wdgt.props.successful = sum(newWGrpState, Kpis.AVAILABLE_AGENTS);
              wdgt.props.unsuccessful  = sum(newWGrpState, Kpis.LOGGED_IN_AGENTS) - sum(newWGrpState, Kpis.AVAILABLE_AGENTS);
              const isTriggeredAvailable = newWGrpState.alertStats?.length ? !!newWGrpState.alertStats.find(a => a[Kpis.AVAILABLE_AGENTS]) : false;
              const isTriggeredLoggedIn = newWGrpState.alertStats?.length ? !!newWGrpState.alertStats.find(a => a[Kpis.LOGGED_IN_AGENTS]) : false;
              wdgt.triggered = isTriggeredAvailable || isTriggeredLoggedIn;
              
            } else if (
              wdgt.type === WidgetTypes.BAR_CHART ||
              wdgt.type === WidgetTypes.PIE_CHART ||
              wdgt.type === WidgetTypes.CHART_GRID
            ) {
              wdgt.props.successful = sum(newWGrpState, Kpis.ANSWERED);
              wdgt.props.unsuccessful = sum(newWGrpState, Kpis.MISSED);
              // We do not update alertStat for complex widgets: approved by Mattias, BUT decided to leave for now  
              const isTriggeredAnswered = newWGrpState.alertStats?.length ? !!newWGrpState.alertStats.find(a => a[Kpis.ANSWERED]) : false;
              const isTriggeredMissed = newWGrpState.alertStats?.length ? !!newWGrpState.alertStats.find(a => a[Kpis.MISSED]) : false;
              wdgt.triggered = isTriggeredAnswered || isTriggeredMissed;
            } else if (
              wdgt.type === WidgetTypes.AGENT_SIMPLE_LIST ||
              wdgt.type === WidgetTypes.TOP_LIST_IN ||
              wdgt.type === WidgetTypes.TOP_LIST_OUT
            ) {
              wdgt.props.value = sumAgentCollection(newWGrpState, Kpis.AGENT_LIST);
              wdgt.triggered = false;
            } else {
              if(wdgt.props?.kpi !== Kpis.TOTAL && wdgt.props?.kpi !== Kpis.ANSWERED && wdgt.props?.kpi !== Kpis.MISSED){
                wdgt.props.kpi = Kpis.TOTAL;
              }
              wdgt.props.value = sum(newWGrpState, wdgt.props?.kpi)
              wdgt.triggered = newWGrpState.alertStats?.length ? !!newWGrpState.alertStats.find(a => a[wdgt.props?.kpi]) : false;
            }
          }
        });
      }
      if(+action.groupId) {
        processSingleGroup(action);
      } else {
        processAllGroups(action);
      }
      return newWGrpState;
    }
    case ReduxActions.LOAD_WIDGET: {
      const indx3 = state.widgets.findIndex(
        w => +action.id === +w.id && +action.groupNo === +w.groupNo
      );
      if (indx3 >= 0) {
        var labelProps = null;
        if(action.widgetType === WidgetTypes.AGENTS_PIE_CHART) {
          labelProps = pieAgentProps;
        } else if (
          action.widgetType === WidgetTypes.BAR_CHART ||
          action.widgetType === WidgetTypes.PIE_CHART ||
          action.widgetType === WidgetTypes.CHART_GRID
        ) {
          labelProps = pieCallProps;
        }
        const typePth = Ramda.lensPath(["widgets", indx3, "type"]);
        let newState3 = Ramda.set(typePth, action.widgetType, state);
        const typePth2 = Ramda.lensPath(["widgets", indx3, "groupId"]);
        newState3 = Ramda.set(typePth2, action.groupId, newState3);
        if (action.widgetType === WidgetTypes.NUMBER) {
          const kpiPth = Ramda.lensPath(["widgets", indx3, "props", "kpi"]);
          return Ramda.set(kpiPth, action.kpi, newState3);
        }
        const kpiPth = Ramda.lensPath(["widgets", indx3, "props"]);
        return Ramda.set(kpiPth, { ...action.kpi, ...labelProps}, newState3);
      } else {
        return state;
      }
    }
    case ReduxActions.LOAD_WIDGET_GROUP: {
      const indx4 = state.widgets.findIndex(
        w => +action.id === +w.id && +action.groupNo === +w.groupNo
      );
      if (indx4 >= 0) {
        const grpIdPath = Ramda.lensPath(["widgets", indx4, "groupId"]);
        return Ramda.set(grpIdPath, action.groupId, state);
      } else {
        return state;
      }
    }
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default widgets;
