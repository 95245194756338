import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { layoutSelector } from '../store/selectors/layout';
import { widgetsSelector } from '../store/selectors/widgets';
import { attendingGroupsSelector } from '../store/selectors/groups';
import { themeTypeSelector } from '../store/selectors/theme';
import { isFirstLoginSelector } from '../store/selectors/settings';
import { isAlertVisibleSelector } from '../store/selectors/settings';
import * as widgetActions from '../store/actions/widgets';
import * as settingsActions from '../store/actions/settings';
import ThemeContext from '../contexts/ThemeContext';
import LocalizationContext from '../contexts/LocalizationContext';
import ContextMenu from '../components/ContextMenu/ContextMenu';
import DashboardItem from '../components/DashboardItem';
import Kpis from '../constants/kpis';
import WidgetTypes from '../constants/widget-types';
import { selectThemeIcon } from '../utils';

import BarChartIconDark from '../images/bar-chart-dark.svg';
import BarChartIconLight from '../images/bar-chart-light.svg';
import PieChartIconDark from '../images/pie-chart-dark.svg';
import PieChartIconLight from '../images/pie-chart-light.svg';
import ListIconDark from '../images/list-dark.svg';
import ListIconLight from '../images/list-light.svg';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc( 100% - 120px )',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: 10,
  },
};

const renderLayout = (
  {
    layout,
    widgets,
    groups,
    theme,
    themeType,
    localization,
    handleClick,
    handleGroupChange,
    isAlertVisible,
  },
  level = 0,
  itemIndex = 0,
) => {
  const {
    row, col, items, item,
  } = layout;
  const direction = row ? 'row' : 'column';
  const style = {
    display: 'flex',
    flex: row || col,
    flexDirection: direction,
  };
  return (
    <div style={style} key={`${direction}-${level}-${itemIndex}`}>
      {items && items
        .map(
          (layoutItem, index) => renderLayout(
            {
              layout: layoutItem,
              widgets,
              groups,
              theme,
              themeType,
              localization,
              handleClick,
              handleGroupChange,
              isAlertVisible,
            },
            level + 1,
            index,
          ),
        )
      }
      {item && (
        <DashboardItem
          widget={widgets.find(w => item.index === w.id && item.group === w.groupNo)}
          fontFactor={item.fontFactor}
          groups={groups}
          theme={theme}
          themeType={themeType}
          localization={localization}
          handleClick={handleClick}
          handleGroupChange={handleGroupChange}
          isAlertVisible={isAlertVisible}
        />          
      )}
    </div>
  );
};
function getChartContextMenuItems(localization, themeType) {
  return [
    {
      key: WidgetTypes.BAR_CHART,
      label: localization.CALLS_BAR_CHART_LABEL,
      icon: selectThemeIcon(themeType, BarChartIconLight, BarChartIconDark),
    },
    {
      key: WidgetTypes.PIE_CHART,
      label: localization.CALLS_PIE_CHART_LABEL,
      icon: selectThemeIcon(themeType, PieChartIconLight, PieChartIconDark),
    },
    {
      key: WidgetTypes.CHART_GRID,
      label: localization.CALLS_CHART_GRID_LABEL,
      icon: selectThemeIcon(themeType, ListIconLight, ListIconDark),
    },
    {
      key: WidgetTypes.AGENTS_PIE_CHART,
      label: localization.AGENTS_PIE_CHART_LABEL,
      icon: selectThemeIcon(themeType, PieChartIconLight, PieChartIconDark),
    },
    {
     key: WidgetTypes.AGENT_SIMPLE_LIST,
     label: localization.AGENT_LIST,
    },
    // {
    //   key: WidgetTypes.TOP_LIST_IN,
    //   label: localization.TOP_LIST_IN,
    // },
    //{
    //  key: WidgetTypes.TOP_LIST_OUT,
    //  label: localization.TOP_LIST_OUT,
    //},
  ];
}

function getContextMenu(localization, themeType, groupId) {
  const kpiOptions = Object.keys(Kpis)
    .filter(key => key !== Kpis.AGENT_LIST) // exist in getChartContextMenuItems
    .filter(key => +groupId !== 0 || (key === Kpis.TOTAL || key === Kpis.ANSWERED ||key === Kpis.MISSED))
    .map((key) => ({
      label: localization[key],
      value: key,
    }));
  const chartOptions = getChartContextMenuItems(localization, themeType)
    .map(({ key, label, icon }) => ({
      label,
      value: key,
      icon,
    }));
  return [...kpiOptions, ...chartOptions];
}

type DashboardProps = {
  layout: Object,
  widgets: Array,
  actions: Object,
  groups: Array,
  themeType: string,
  isFirstLogin: boolean,
};

class Dashboard extends Component<DashboardProps> {
  constructor(props, context) {
    super(props, context);
    this.menu = undefined;
    this.currentWidget = undefined;
    this.handleWidgetClick = this.handleWidgetClick.bind(this);
    this.isClickOnSelect = this.isClickOnSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleWidgetGroupChange = this.handleWidgetGroupChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener('click', this.handleClick);

    // if we are on the dashboard, set the firstLogin flag to false
    const { actions: { updateSettings }, isFirstLogin } = this.props;
    if (isFirstLogin) {
      updateSettings({ isFirstLogin: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  isClickOnSelect(linkSelectClass, widgetClass, node) {
    const isLinkSelect = (className) => className.indexOf(linkSelectClass) !== -1;
    const isWidgetNode = (className) => className.indexOf(widgetClass) !== -1;
    if (!node.className || typeof node.className !== 'string') {
      return false;
    }

    // eslint-disable-next-line no-shadow
    const traverseNode = (node) => isLinkSelect(node.className)
      ? true
      : isWidgetNode(node.className)
        ? false
        : traverseNode(node.parentNode);

    return traverseNode(node);
  }

  handleWidgetClick(event, { id, groupNo, groupId, type, kpi, themeType, localization }, bounds) {
    event.preventDefault();

    const clickOnSelect = this.isClickOnSelect('link-select', 'widget', event.target);
    if (!clickOnSelect) {
      if (!this.menu.state.visible) {
        this.currentWidget = { bounds, id, groupNo, groupId, type, kpi};
        this.menu.props.options.length = 0
        this.menu.props.options.push(...getContextMenu(localization, themeType, groupId));
        const { clientX, clientY } = event;
        this.menu.open({ clientX, clientY }, type, kpi);
      } else {
        this.closeMenu();
      }
    } else {
      this.closeMenu();
    }
  }

  handleWidgetGroupChange(groupId, groupNo, widgetId, kpi, type) {
    const { actions: { updateWidgetAttendingGroup } } = this.props;
    const possibleMenus = getContextMenu({},{},groupId);
    if(!possibleMenus.find(m => m.value === kpi)) {
      kpi = Kpis.TOTAL;
      type = WidgetTypes.NUMBER;
    }
    updateWidgetAttendingGroup(groupId, groupNo, widgetId, kpi, type);
  }

  handleClick({ clientX, clientY }) {
    if (!this.currentWidget) {
      return;
    }

    const {
      bounds: {
        x, y, width, height,
      },
    } = this.currentWidget;
    if (
      !(clientX >= x && clientX <= x + width)
      || !(clientY >= y && clientY <= y + height)
    ) {
      if (this.menu) {
        this.menu.close();
      }
    }
  }

  closeMenu() {
    if (this.menu) {
      this.menu.close();
    }
  }

  handleChange(kpiOrType) {
    if (!this.currentWidget) {
      return;
    }
    const { updateWidget } = this.props.actions;
    const { id, groupNo, groupId } = this.currentWidget;
    if (Object.keys(Kpis).includes(kpiOrType)) {
      updateWidget(id, groupNo, groupId, WidgetTypes.NUMBER, kpiOrType);
      this.closeMenu();
    }

    if (Object.keys(WidgetTypes).includes(kpiOrType)) {
      updateWidget(id, groupNo, groupId, kpiOrType);
      this.closeMenu();
    }
  }

  render() {
    const {
      layout,
      widgets,
      themeType,
      groups,
      isAlertVisible,
    } = this.props;
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => {
              const groupList = groups?.length ? [{name: localization.ALL_GROUPS, id: 0}, ...groups ] : [];
              return (
                <div
                  style={{ ...styles.container, borderColor: theme.BACKGROUND }}
                >
                  {renderLayout({
                    layout,
                    widgets,
                    groups: groupList,
                    theme,
                    themeType,
                    localization,
                    handleClick: this.handleWidgetClick,
                    handleGroupChange: this.handleWidgetGroupChange,
                    isAlertVisible: isAlertVisible,
                  })}
                  <ContextMenu
                    ref={(menu) => {
                      this.menu = menu;
                    }}
                    options={[]}
                    onChange={this.handleChange}
                  />
                </div>
              );
            }}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  layout: layoutSelector(state),
  widgets: widgetsSelector(state),
  themeType: themeTypeSelector(state),
  isFirstLogin: isFirstLoginSelector(state),
  groups: attendingGroupsSelector(state),
  isAlertVisible: isAlertVisibleSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...widgetActions,
      ...settingsActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
