import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const instancesRecord = (state) => state.instances;

const renamingSelector = createSelector(
  instancesRecord,
  (record = EMPTY_OBJECT) => record.renaming,
);
const renameErrorSelector = createSelector(
  instancesRecord,
  (record = EMPTY_OBJECT) => record.renameError,
);

export {
  renamingSelector,
  renameErrorSelector,
};
