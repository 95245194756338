// @flow
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import ThemeContext from '../../contexts/ThemeContext';

import LocalizationContext from '../../contexts/LocalizationContext';
import { changeInstance } from "../../store/actions/layoutSettings";
import { selectedInstanceSelector } from "../../store/selectors/layoutSettings";

import { renameErrorSelector } from "../../store/selectors/instances";
import { renameInstance, resetToDefault } from "../../store/actions/instances";

import SaveIconLight from "../../images/save-light.svg";
import SaveIconDark from "../../images/save-dark.svg";
import GearIconLight from "../../images/gear-light.svg";
import GearIconDark from "../../images/gear-dark.svg";
import LogoutIconLight from '../../images/logout-light.svg'
import WarningIconLight from '../../images/warning-light.svg'
import ThemeTypes from '../../constants/theme-types';

type InstancesProps = {
  actions: Object,
  themeType: String,
  availableInstances: Array,
  selectedInstance: Object,
  onInstanceChange: Function,
};

const styles = {
  container: {
    padding:'0px 50px 50px 50px',
  },
  imageButton: {
    height: 20,
    padding: '0 2px',
    margin: '5px 2px',
    verticalAlign: 'top'
  },
  imageError: {
    height: 20,
    margin: '3px 2px 3px 14px',
    verticalAlign: 'top'
  },
  screenBlock: {
    padding: 10,
    border: 'solid',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 10,
  },
  screenBlockContainer: {
    margin: "8px 0",
  },
  selectedLightScreen: {
    background:"#2D6B8A",
  },
  selectedDarkScreen: {
    background:"dimgray",
  },
  screenInput: {
    height: 30,
    margin: 1,
    width:'calc(100% - 60px)',
  },
  screenText: {
    width:'calc(100% - 55px)',
    display: 'inline-block',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  viewContainer: {
    paddingTop:3,
    width: '100%'
  }
}

class Instances extends Component<InstancesProps> {
  constructor(props) {
    super(props);
    this.state = {};
    this.switchEditMode = this.switchEditMode.bind(this);
    this.changeInstanceName = this.changeInstanceName.bind(this);
    this.saveInstanceName = this.saveInstanceName.bind(this);
    this.handleInstanceChange = this.handleInstanceChange.bind(this);
  }

  switchEditMode(event, instance) {
    event.stopPropagation();
    this.props.actions.resetToDefault();
    this.setState({editingInstanceName: instance ? instance.label : undefined, editedItem: instance });
  }
  changeInstanceName(event) {
    this.setState({editingInstanceName: event.target.value});
  }
  saveInstanceName(event) {
    event.stopPropagation();
    if(!this.state.editedItem || !this.state.editingInstanceName || this.state.editingInstanceName === this.state.editedItem.label) { 
      return; 
    }
    const instanceData = this.props.availableInstances.find(i => +i.id === +this.state.editedItem.value);
    this.props.actions.renameInstance(this.state.editedItem.value, this.state.editingInstanceName, instanceData.accountId);
  };

  handleInstanceChange(instance) {
    const { 
      onInstanceChange,
      actions: {
        changeInstance,
        resetToDefault
      }} = this.props;
    changeInstance(instance.value);
    onInstanceChange(instance);
    resetToDefault();
  };

  renderInstance(instance, localization) {    
    const { themeType, 
      selectedInstance
    } = this.props;
    const isEditMode = this.state.editedItem && this.state.editedItem.value === instance.value;
    const isSelected = instance.value === selectedInstance.id;
    const selectedStyle = themeType === ThemeTypes.DARK ? styles.selectedDarkScreen : styles.selectedLightScreen;
    const saveIcon = themeType === ThemeTypes.DARK ? SaveIconDark : SaveIconLight;
    const gearIcon = themeType === ThemeTypes.DARK ? GearIconDark : GearIconLight;

    return (
      <Col key={instance.value} sm={4} style={{...styles.screenBlockContainer}}>
        <Container style={{...styles.screenBlock, ...(isSelected ? selectedStyle : "") }} onClick={() => this.handleInstanceChange(instance)}>
          {
            [(isEditMode && (<div key={"Edit" + instance.value}>
              <input style={{...styles.screenInput}}
                type="text" 
                onClick={(e) => e.stopPropagation()}
                value={this.state.editingInstanceName} 
                onChange={this.changeInstanceName} />
              <img alt="" style={{...styles.imageButton}}
                src={saveIcon} 
                title={localization.INSTANCE_SAVE} 
                onClick={(event) => this.saveInstanceName(event, instance)} />
              <img alt="" style={{...styles.imageButton}} 
                src={LogoutIconLight} 
                title={localization.INSTANCE_CANCEL} 
                onClick={this.switchEditMode} />
            </div>)),
            (!isEditMode && (<div key={"View" + instance.value} style={{...styles.viewContainer}}>
              <div style={{...styles.screenText}}>
                {instance.label.toUpperCase()}
              </div>
              <img alt="" style={{...styles.imageButton}}
                src={gearIcon}
                title={localization.INSTANCE_EDIT} 
                onClick={(event) => this.switchEditMode(event, instance)} />
            </div>))]
          }
        </Container>
      </Col>);
  }

  render() {
    const { 
      availableInstances,
      renameError,
     } = this.props;
    
    const instances = availableInstances.map(inst => ({
      value: inst.id,
      label: inst.shortName,
    }));
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => (
              <div style={{...styles.container}}>
                <Row>
                  { renameError ? <div><img alt="" style={{...styles.imageError}} src={WarningIconLight} /> { renameError }</div> : null }
                </Row>
                <Row>
                  {instances.map((instance) => this.renderInstance(instance, localization))}
                </Row>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedInstance: selectedInstanceSelector(state),
  renameError: renameErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeInstance,
      renameInstance,
      resetToDefault,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Instances);
