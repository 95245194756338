export default {
    // Background color - #f0f3f5 ( layout ), #ffffff boxes, #000000 number
    // Icon Color - #616e7d - cog wheel
    // Section Header Text color - #616e7d ( group name )
    // Bold-text- color - #575756
    // Thin-text- color - #878787

    // Dark mode

    // Darkmode â€“ backgound color 1e1e1f
    // Darkmode Boxcolor  616e7d

    // Background  color - #1E1E1F ( layout ), 616e7d boxes #ffffff number
    // Icon color - #616e7d - cog wheel
    // Thin text- color - #ffffff

    DARK: {
        THEME_SWTICH_BULLET: "#2D6B8A",
        THEME_SWITCH_BACKGROUND: "#1C4050",
        HEADER_BACKGROUND: "#18171B",
        WIDGET_BACKGROUND: "#343337",
        WIDGET_BACKGROUND_ACTIVE: "#444347",
        BACKGROUND: "#262529",
        LABEL: "#FFFFFF",
        TEXT: "#FFFFFF",
        TITLE: "#E0AC5C",
        NUMBER: "#66A1BE",
        //SUC_BAR: '#008000',
        //UNSUC_BAR: '#FF0000',
        //SUC_BAR: "#026347",
        //UNSUC_BAR: "#9c3448",
        SUC_BAR: "#167067",
        UNSUC_BAR: "#9c3448",
        TOTAL_BAR: "#FFFFFF",
        LOGO: "#FFFFFF",
        HELP_BACKGROUND: "#343337",
        TITLE_TEXT: "#FFFFFF",
    },
    LIGHT: {
        THEME_SWTICH_BULLET: "#679DBC",
        THEME_SWITCH_BACKGROUND: "#A9CAD9",
        HEADER_BACKGROUND: "#495a68",
        WIDGET_BACKGROUND: "#FFFFFF",
        WIDGET_BACKGROUND_ACTIVE: "#F0F0F0",
        BACKGROUND: "#E0E0E0",
        LABEL: "#0D576B",
        TEXT: "#FFFFFF",
        TITLE: "#213E46",
        NUMBER: "#66A1BE",
        //SUC_BAR: '#008000',
        //UNSUC_BAR: '#FF0000',
        //SUC_BAR: "#026347",
        //UNSUC_BAR: "#9c3448",
        //SUC_BAR: "#278178",
        //UNSUC_BAR: "#ad4559",
        //SUC_BAR: "#167067",
        //UNSUC_BAR: "#9c3448",
        SUC_BAR: "#008f80",
        UNSUC_BAR: "#b41433",
        TOTAL_BAR: "#66A1BE",
        LOGO: "#FFFFFF",
        HELP_BACKGROUND: "#a0b4bf",
        TITLE_TEXT: "#2d6b8a",
    }
};