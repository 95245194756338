import React from "react";
import CountUp from "react-countup";

type AnimatedNumberProps = {
  style: Object,
  value: number,
  isFirstRender: boolean,
  animationDuration: number
};

const AnimatedNumber = (props: AnimatedNumberProps) => {
  const { isFirstRender, style, animationDuration } = props;
  const value = props.value ? props.value : 0;
  const isTimeValue = value && isNaN(value) && value.includes(":");
  const valueParts = isTimeValue
    ? value.split(":").map(vp => parseInt(vp))
    : [parseInt(value)];

  return (
    <div>
      {/*
        suffix={index + 1 < valueParts.length ? ":" : ""}
      */}
      {isFirstRender ? (
        valueParts.map((valPart, index) => {
          const val = !valPart || isNaN(valPart) ? 0 : valPart;
          const suffix = index + 1 < valueParts.length ? ":" : "";
          return (
            <CountUp
              key={index}
              start={0}
              end={val}
              duration={animationDuration}
              delay={0}
            >
              {({ countUpRef }) => (
                <span>
                  <b style={style} ref={countUpRef} />
                  <b style={style}>{suffix}</b>
                </span>
              )}
            </CountUp>
          );
        })
      ) : (
        <span>
          <b style={style}>{value}</b>
        </span>
      )}
    </div>
  );
};

export default AnimatedNumber;
