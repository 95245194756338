import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const navigationRecord = (state) => state.navigation;

const currentScreenNameSelector = createSelector(
  navigationRecord,
  (record = EMPTY_OBJECT) => record.currentScreenName,
);

export {
  currentScreenNameSelector,
};
