const Kpis = {
  IN_QUEUE: 'IN_QUEUE',
  LOGGED_IN_AGENTS: 'LOGGED_IN_AGENTS',
  AVAILABLE_AGENTS: 'AVAILABLE_AGENTS',
  TOTAL: 'TOTAL',
  ANSWERED: 'ANSWERED',
  MISSED: 'MISSED',
  LATEST: 'LATEST',
  AVERAGE_WAIT: 'AVERAGE_WAIT',
  AVERAGE_DURATION: 'AVERAGE_DURATION',
  FORWARDED: 'FORWARDED',
  AGENT_LIST: 'AGENT_LIST',
};

export default Kpis;
