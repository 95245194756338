let adminAlerts = 'https://notifyworker.pbxaddons.se'
let coreBase = 'https://que.pbxaddons.se'
let loginBase = coreBase //'https://login.soluno.se'
const hostnameDev = 'localhost'
const hostnameStage = 'wbc.pbxaddons.se'
const hostnameStage2 = 'wb.pbxaddons.se'
const hostname = document.location ? document.location.hostname : ''
if (hostname === hostnameDev) {
  coreBase = 'https://localhost:5002'
  
  // loginBase = "https://localhost:5000"
  loginBase = coreBase

// adminAlerts = 'https://localhost:5019'
}
else if (hostname === hostnameStage || hostname === hostnameStage2) {
  // coreBase = 'https://que.soluno.se'
  coreBase = 'https://testque.pbxaddons.se'

  // loginBase = 'https://login.soluno.se'
  loginBase = coreBase

  adminAlerts = 'https://test-notifyworker.pbxaddons.se'
}

const LOGIN_BASE = loginBase
const CORE_BASE = coreBase
const ADM_ALERTS_BASE = adminAlerts
const DEV_ENV = hostname === hostnameDev
const STAGE_ENV = hostname === hostnameStage
export { LOGIN_BASE, CORE_BASE, ADM_ALERTS_BASE, DEV_ENV, STAGE_ENV }
