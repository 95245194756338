const ReduxActions = {
  CHANGE_SCREEN: 'CHANGE_SCREEN',

  UPDATE_LAYOUT: 'UPDATE_LAYOUT',
  LOAD_LAYOUT: 'LOAD_LAYOUT',

  UPDATE_WIDGET: 'UPDATE_WIDGET',
  UPDATE_WIDGET_DATA: 'UPDATE_WIDGET_DATA',
  // UPDATE_WIDGETS: 'UPDATE_WIDGETS',

  UPDATE_ATTENDING_GROUPS: 'UPDATE_ATTENDING_GROUPS',
  UPDATE_WIDGET_GROUP: 'UPDATE_WIDGET_GROUP',
  UPDATE_WIDGET_ALERT_DATA: 'UPDATE_WIDGET_ALERT_DATA',


  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  CHANGE_THEME: 'CHANGE_THEME',

  LOGIN_STARTED: 'LOGIN_STARTED',
  LOGIN_FINISHED: 'LOGIN_FINISHED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',

  RESET_STATE: 'RESET_STATE',

  SHOW_HELP: 'SHOW_HELP',
  HIDE_HELP: 'HIDE_HELP',

  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_MENU_SETTINGS: 'UPDATE_MENU_SETTINGS',

  LOAD_LAYOUT_SETTINGS: 'LOAD_LAYOUT_SETTINGS',
  UPDATE_LAYOUT_SETTINGS: 'UPDATE_LAYOUT_SETTINGS',

  LOAD_WIDGET_GROUP: 'LOAD_WIDGET_GROUP',
  LOAD_WIDGET: 'LOAD_WIDGET',

  UPDATE_INSTANCE: 'UPDATE_INSTANCE',

  INSTANCE_RENAME_STARTED: 'INSTANCE_RENAME_STARTED',
  INSTANCE_RENAME_FINISHED: 'INSTANCE_RENAME_FINISHED',
  INSTANCE_RENAME_ERROR: 'INSTANCE_RENAME_ERROR',
  INSTANCE_RENAME_RESET: 'INSTANCE_RENAME_RESET',
}

export default ReduxActions
