
import axios from '../utils/axios-utility';
import httpStatusCodes from '../constants/http-status-codes';
import { CORE_BASE } from '../configs';

const getGroups = () => new Promise(
  (resolve, reject) => {
    axios.get(`${CORE_BASE}/Que/groups`)
      .then(
        ({ status, data }) => status === httpStatusCodes.OK
          ? resolve(data.groups)
          : reject(data),
      )
      .catch(reject);
  },
);

export {
  getGroups,
};
