// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Button,
  Tab,
  Tabs
} from 'react-bootstrap';
import * as navigationActions from '../store/actions/navigation';
import * as settingsActions from "../store/actions/settings";
import { themeTypeSelector } from '../store/selectors/theme';
import ThemeContext from '../contexts/ThemeContext';
import LocalizationContext from '../contexts/LocalizationContext';
import ScreenNames from '../constants/screen-names';
import "./Settings.css";
import Help from './settings-elements/Help';
import General from './settings-elements/General';
import { availableInstancesSelector } from "../store/selectors/layoutSettings";
import { selectedSettingsTabSelector } from "../store/selectors/settings";
import Layouts from './settings-elements/Layouts';
import AdminAlerts from './settings-elements/AdminAlerts';
import ThemeTypes from '../constants/theme-types';
import { renewAccessToken } from '../utils/axios-utility'

const styles = {
  container: {
    //height: 'calc(100% - 120px)',
    //height: '100%',
    margin: '0 100px',
    minHeight: '400px',
    minWidth: '800px',
    height: '100%',
  },
  tabContentContainer: {
    borderRadius: '10px',
    padding: '10px',
  },
  mainContainer: {
    padding: '15px 0',
    height: 'calc(100% - 120px)',
  },
  heading: {
    textAlign: 'center',
  },
  body: {
    height: 'calc(100% - 70px)',
  },
  buttonSection: {
    float: 'right',
    margin: '-40px 20px 0 0',

  }
};

type SettingsProps = {
  actions: Object,
  onLogout: Function,
  onInstanceChange: Function,
};

class Settings extends Component<SettingsProps> {
  constructor(props, state) {
    super(props, state);

    this.state = {
      activeLayout: undefined,
    };

    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    const { actions: { changeScreen } } = this.props;
    changeScreen(ScreenNames.DASHBOARD);
  }

  renderBody(localization, theme) {
    const { themeType, 
      availableInstances, 
      onLogout, 
      onInstanceChange,
      selectedSettingsTab, 
      actions: {
        updateSettings,
      } 
    } = this.props;

    const supportExtendedInstances = availableInstances && availableInstances.length && availableInstances.length > 2? true: false;
    const handleSelectedSettingsTab = (active, updateSettings) => {
      updateSettings({ selectedSettingsTab: active });
      if(active === "alertsTab") {
        renewAccessToken();
      }
    };
    const themeStyle = themeType === ThemeTypes.DARK ? "tab-headers dark" : "tab-headers light";
    return (
      <Tabs defaultActiveKey={ selectedSettingsTab || "generalTab" }
        onSelect={ active => handleSelectedSettingsTab(active, updateSettings) }
        className={themeStyle}>
        <Tab eventKey="generalTab" title={ localization.SETTINGS_GENERAL } style={{ ...styles.tabContentContainer, background: theme.HEADER_BACKGROUND }}>
          <General themeType={ themeType } onLogout={ onLogout } onInstanceChange={ onInstanceChange } />
        </Tab>
        <Tab eventKey="layoutsTab" title={ localization.SETTINGS_LAYOUTS } style={{ ...styles.tabContentContainer, background: theme.HEADER_BACKGROUND }}>
          <Layouts />
        </Tab>
        {supportExtendedInstances && (<Tab eventKey="alertsTab" title={ localization.SETTINGS_ALERTS } style={{ ...styles.tabContentContainer, background: theme.HEADER_BACKGROUND }}>
          <AdminAlerts />
        </Tab>)}
        <Tab eventKey="helpTab" title={ localization.SETTINGS_HELP } style={{ ...styles.tabContentContainer, background: theme.HEADER_BACKGROUND }}>
          <Help themeType={ themeType }/>
        </Tab>
      </Tabs>    
    );
  }

  render() {
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <ThemeContext.Consumer>
            {(theme) => (
              <div style={{ ...styles.mainContainer, background: theme.WIDGET_BACKGROUND_ACTIVE, color: theme.TEXT }}>
                <div style={{ ...styles.container }}>
                  <Row>
                    <Col>
                      <h2 style={{ ...styles.heading, color: theme.TITLE_TEXT }}>
                        { localization.SETTINGS_HEADER_SINGLE }
                      </h2>
                    </Col>
                  </Row>
                  <Row style={{ ...styles.body }}>
                    <Col>
                      { this.renderBody(localization, theme) }
                    </Col>
                  </Row>
                  <Row style={{ ...styles.buttonSection }}>
                    <Col>
                      <Button
                        style={{
                          background: theme.THEME_SWTICH_BULLET,
                          color: theme.LABEL,
                        }}
                        variant="default"
                        onClick={ this.cancel }>
                        {localization.OK}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  themeType: themeTypeSelector(state),
  availableInstances: availableInstancesSelector(state),
  selectedSettingsTab: selectedSettingsTabSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...navigationActions,
      ...settingsActions,      
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
