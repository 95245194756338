// @flow
import React, { Component } from 'react';
import LinkSelect from "../LinkSelect";
import LocalizationContext from '../../contexts/LocalizationContext';
import ThemeContext from '../../contexts/ThemeContext';
import WidgetTypes from '../../constants/widget-types';

const styles = {
  widget: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
    marginBottom: "10px"
  },
  columnContainer: {
    display:"flex", 
    flexDirection:"row",
  },
  columns: {
    padding: "0 10px",
  },
  icon: {
    margin: "5px 10px 5px 5px"
  },
};

type AgentListWidgetWidgetProps = {
  id: number,
  groupNo: number,
  groupId: number,
  value: number,
  fontFactor: number,
  type: String
};

class AgentListWidgetWidget extends Component<AgentListWidgetWidgetProps> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      trHeight: 0
    };
  }
  componentDidMount() {
    setTimeout(() => {
      if(!this.trElement) {return}
      const trHeight = this.trElement.clientHeight - 85
      this.setState({ trHeight });
    }, 0);
    
  }
  render() {
    const {
      value,
      groups,
      onGroupChange,
      groupId,
      fontFactor,
      type
    } = this.props;

    const { trHeight } = this.state;
    
    const getSize = (base) => {
      return base * fontFactor + "px";
    };
    const options = groups.map(({ id, name }) => ({
      value: `${id}`,
      label: name
    }));
    const selectedValue = options.find(
      option => option.value === `${groupId}`
    ) || options[0];
    const isIncoming = WidgetTypes.TOP_LIST_IN === type;
    const isOutgoing = WidgetTypes.TOP_LIST_OUT === type;
    var sortedValues;
    if(value) {
      if(isOutgoing) {
        sortedValues = value.sort(( a, b ) => a.outbound < b.outbound ? 1 : a.outbound > b.outbound ? -1 : 0);
      } else if (isIncoming) {
        sortedValues = value.sort(( a, b ) => a.inbound < b.inbound ? 1 : a.inbound > b.inbound ? -1 : 0);
      } else {
        sortedValues = value.sort(( a, b ) => a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 
                                  : a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0 );
      }
    }
    return (
      <LocalizationContext.Consumer>
        {localization => (
          <ThemeContext.Consumer>
            {theme => (
              <div style={styles.widget}  ref={(trElement) => this.trElement = trElement}>
                <LinkSelect
                  className="link-select"
                  fontFactor={fontFactor}
                  options={options}
                  value={selectedValue}
                  onChange={event =>
                    onGroupChange(
                      event.value,
                      this.props.groupNo,
                      this.props.id,
                      this.props.kpi,
                      this.props.type
                    )
                  }
                  selectStyle={{
                    color: theme.TITLE
                  }}
                />
                <div title={localization.TIP_CHANGE_MTRC} style={styles.container}>
                  {!!trHeight && (<div style={{...styles.columnContainer,height:trHeight}}>
                    <div style={{...styles.columns}} >
                      {!!sortedValues && sortedValues.length && sortedValues.map(v => (
                        <div style={{...styles.columnContainer, height:getSize(15)}} key={v.id}>
                          <div style={{ margin: getSize(4) }}>
                            <img title={v.activity} src={v.isAvailable ? 'img/unknown-person-green.png' : 'img/unknown-person-red.png'} alt="icon" 
                              style={{ height: getSize(7), width: getSize(7), verticalAlign: "text-top" }}  />
                          </div>
                          <div
                              style={{
                                color: theme.LABEL,
                                fontSize: getSize(10),
                                marginLeft: 5 
                              }}>
                              { v.firstName + ' ' + v.lastName }
                          </div>
                        </div>        
                      ))}
                    </div>
                    <div style={{...styles.columns}}>
                      {!!sortedValues && sortedValues.length && sortedValues.map(v => (
                        <div
                          style={{ 
                            height:getSize(15),
                            color: theme.LABEL,
                            fontSize: getSize(8),
                            paddingTop:'4px'
                          }}
                          key={"act" + v.id}>
                          {v.activity}
                        </div>   
                      ))}
                    </div>
                    { isIncoming && (<div style={{...styles.columns}}>
                      {!!sortedValues && sortedValues.length && sortedValues.map(v => (
                        <div
                          style={{
                            height:getSize(15),
                            color: theme.LABEL,
                            fontSize: getSize(10),
                          }}
                          key={"inbound" + v.id}>
                          {v.inbound}
                        </div>   
                      ))}
                    </div>)}
                    { isOutgoing && (<div style={{...styles.columns}}>
                      {!!sortedValues && sortedValues.length && sortedValues.map(v => (
                        <div
                          style={{
                            height:getSize(15),
                            color: theme.LABEL,
                            fontSize: getSize(10)
                          }}
                          key={"outbound" + v.id}>
                          {v.outbound}
                        </div>   
                      ))}
                    </div>)}
                  </div>
                )}
                </div>
                <div
                    style={{
                      color: theme.LABEL,
                      fontSize: getSize(12)
                    }}>
                    { isIncoming ? localization.TOP_LIST_IN : isOutgoing ? localization.TOP_LIST_OUT : localization.AGENT_LIST }
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </LocalizationContext.Consumer>);
  }
}

AgentListWidgetWidget.defaultProps = {
};

export default AgentListWidgetWidget;
